const convertToTimeFormat = (cronTime = "") => {
  if (typeof cronTime !== "string" || !cronTime.trim()) {
    return "";
  }

  const match = cronTime.match(/^(\d{1,2}) (\d{1,2})/);
  if (!match) {
    return "";
  }

  let [, minute, hour] = match;
  minute = minute.padStart(2, "0");
  hour = hour.padStart(2, "0");

  return `${hour}:${minute}`;
};

module.exports = convertToTimeFormat;
