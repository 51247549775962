/* eslint-disable */
// import { ecosystems } from "../i18n/english";
import i18n from "../i18n/init";

const insightsModuleConfig = {
  createInsightConstants: {
    insightInputTraits: [
      i18n.t("constants.event_category.consumer_action"),
      i18n.t("constants.event_category.email_ctl"),
      i18n.t("constants.event_category.iot"),
      i18n.t("constants.event_category.loyalty"),
      i18n.t("constants.event_category.postal_CLT"),
      i18n.t("constants.event_category.push_CLT"),
      i18n.t("constants.event_category.replenishment"),
      i18n.t("constants.event_category.sms_CLT"),
      i18n.t("constants.event_category.sales_transaction"),
      i18n.t("constants.event_category.website_event"),
    ],
    isRequiredOptions: [
      {
        option: true,
        label: i18n.t("common.yes"),
      },
      {
        option: false,
        label: i18n.t("common.no"),
      },
    ],
    characterLimits: {
      insightsName: 50,
      insightDescription: 500,
      eventType: 2,
    },
    sql_indicator: ["Yes", "No"],
    footerText: {
      1: i18n.t("common.labels.proceed_to_mapping"),
      2: i18n.t("common.labels.proceed_to_confirmation"),
      3: i18n.t("common.labels.confirm_and_submit"),
    },
  },
  sourceProjectSelect: [
    {
      label: i18n.t(
        "insight_container.mp_mapping.source_project_select.sql_expression_big_query"
      ),
      value: "SQLExpressionForBigQuery",
    },
    {
      label: i18n.t(
        "insight_container.mp_mapping.source_project_select.sql_expression_in_spanner"
      ),
      value: "SQLExpressionInSpanner",
    },
  ],
  destinationSelect: [
    {
      label: i18n.t(
        "insight_container.mp_mapping.destination_project_select.crm_activation"
      ),
      value: "CRMActivation",
    },
    {
      label: i18n.t(
        "insight_container.mp_mapping.destination_project_select.website_personalization"
      ),
      value: "WebsitePersonalization",
    },
  ],
  statusOptions: [
    {
      label: i18n.t("insight_container.mp_mapping.statusOptions.active"),
      value: "active",
    },
    {
      label: i18n.t("insight_container.mp_mapping.statusOptions.inactive"),
      value: "inactive",
    },
  ],
  computeFrequencyOptions: [
    {
      label: i18n.t(
        "insight_container.mp_mapping.computeFrequencyOptions.realtime"
      ),
      value: "realtime",
    },
    {
      label: i18n.t(
        "insight_container.mp_mapping.computeFrequencyOptions.batch"
      ),
      value: "batch",
    },
    {
      label: i18n.t(
        "insight_container.mp_mapping.computeFrequencyOptions.both"
      ),
      value: "both",
    },
  ],
  processingTypeOptions: [
    {
      label: i18n.t("insight_container.mp_mapping.processingType.full"),
      value: "full",
    },
    {
      label: i18n.t("insight_container.mp_mapping.processingType.delta"),
      value: "delta",
    },
  ],
};

export default insightsModuleConfig;
