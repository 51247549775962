import insightsModuleConfig from "../../../../config/insightModuleConfig";

import i18n from "../../../../i18n/init";

import convertToTimeFormat from "./convertToTimeFormat";

const { createInsightConstants } = insightsModuleConfig;

export function findSelectedInsightSource(selectedSource) {
  const { sourceProjectSelect } = insightsModuleConfig;
  if (selectedSource) {
    return sourceProjectSelect.find(
      (sourceOption) => sourceOption?.value === selectedSource
    );
  }
  return null;
}

export function findSelectedInsightDestination(selectedDestination) {
  const { destinationSelect } = insightsModuleConfig;
  if (selectedDestination) {
    return destinationSelect.find(
      (destinationOption) => destinationOption?.value === selectedDestination
    );
  }
  return null;
}

export function isTimeFormat(schedule) {
  return /^(?:0?[0-9]|1\d|2[0-4]):00$/.test(schedule);
}

export function getSchedule(schedule) {
  if (!schedule) {
    return "";
  }
  if (isTimeFormat(schedule)) {
    return schedule;
  }
  return convertToTimeFormat(schedule);
}

export function getComputeFrequency(computeFrequency, schedule) {
  let computeFrequencySchedule = computeFrequency;
  if (isTimeFormat(schedule)) {
    computeFrequencySchedule = `${computeFrequencySchedule} - ${schedule}`;
  }
  if (
    !computeFrequencySchedule?.includes("-") &&
    schedule &&
    schedule !== "reatime"
  ) {
    const scheduleTime = convertToTimeFormat(schedule);
    if (scheduleTime) {
      computeFrequencySchedule = `${computeFrequencySchedule} - ${scheduleTime}`;
    }
  }
  return computeFrequencySchedule;
}

export function sourceDestinationText(sourceDetails) {
  return `
    <p>
      ${
        sourceDetails?.ecoSystem
          ? `${i18n.t(
              "insight_container.mp_mapping.source_destination_details_labels.ecosystem"
            )} ${sourceDetails.ecoSystem}<br>`
          : ""
      }
      ${
        sourceDetails?.computationProject
          ? `${i18n.t(
              "insight_container.mp_mapping.source_destination_details_labels.computationProject"
            )} ${sourceDetails.computationProject}<br>`
          : ""
      }
      ${
        sourceDetails?.computationInstance
          ? `${i18n.t(
              "insight_container.mp_mapping.source_destination_details_labels.computationInstance"
            )} ${sourceDetails.computationInstance}<br>`
          : ""
      }
      ${
        sourceDetails?.computationSchema
          ? `${i18n.t(
              "insight_container.mp_mapping.source_destination_details_labels.computationSchema"
            )} ${sourceDetails.computationSchema}`
          : ""
      }
    </p>
  `;
}

export function customIndicatorModify(customIndicator) {
  if (typeof customIndicator === "string") {
    return customIndicator;
  }
  if (customIndicator) {
    return createInsightConstants.sql_indicator[0];
  }
  return createInsightConstants.sql_indicator[1];
}

export const createInputTraits = (inputTraitsVal, includeTitle = false) => {
  return (
    inputTraitsVal?.map((item) => {
      const trait =
        typeof item === "string"
          ? { traitId: item.split("-")[0], traitName: item.split("-")[1] }
          : { traitId: item?.traitId, traitName: item?.traitName };

      if (includeTitle) {
        return { ...trait, title: trait.traitName };
      }

      return trait;
    }) || []
  );
};

const customIndecatorModify = (checkValue) => {
  if (checkValue === "Yes") {
    return true;
  }
  return false;
};

export const modifyOutputTrait = (mOutputTraits, includeTitle = false) => {
  let modOutputTraits = {};
  if (mOutputTraits && typeof mOutputTraits === "string") {
    modOutputTraits = {
      traitId: mOutputTraits?.split("-")[0],
      traitName: mOutputTraits?.split("-")[1],
    };
  } else {
    modOutputTraits = {
      traitId: mOutputTraits?.traitId,
      traitName: mOutputTraits?.traitName,
    };
  }
  if (includeTitle) {
    const outputTrait = `${modOutputTraits?.traitId} - ${modOutputTraits?.traitName}`;
    modOutputTraits.title = outputTrait;
  }
  return modOutputTraits;
};

export function preparePayloadInsights(insightsSelected, mpMappings) {
  const payload = {
    insight: {
      insightsName:
        insightsSelected?.insightsName || insightsSelected?.insightName,
      description: insightsSelected?.description,
      inputTraits: createInputTraits(insightsSelected?.inputTraits),
      outputTrait: modifyOutputTrait(insightsSelected?.outputTrait),
      isSQLTrait: customIndecatorModify(insightsSelected?.customIndicator),
      sqlExpression: insightsSelected?.sqlExpression,
      isNew: true,
      isUpdated: false,
    },
    marketingPrograms: mpMappings.map((marketingProgramInfo) => ({
      isUpdated: marketingProgramInfo.isUpdated,
      isNew: marketingProgramInfo.isNew,
      deleted: marketingProgramInfo?.deleted,
      status: marketingProgramInfo.status,
      marketingProgramNumber: marketingProgramInfo.marketingProgram
        .split("-")[0]
        .trim(),
      description: marketingProgramInfo.marketingProgram.split("-")[1].trim(),
      computeFrequency:
        marketingProgramInfo.computeFrequencyType ||
        marketingProgramInfo.computeFrequency?.split("-")[0]?.trim(),
      schedule: getSchedule(marketingProgramInfo.schedule),
      processingType: marketingProgramInfo.processingType,
      source: marketingProgramInfo.selectedSourceValue,
      destination: marketingProgramInfo.selectedDestinationValue,
    })),
  };
  if (insightsSelected?.insightId) {
    payload.insight.insightsId = insightsSelected.insightId
      ? insightsSelected.insightId
      : "";
    payload.insight.isNew = false;
    payload.insight.isUpdated = true;
  }
  return payload;
}
