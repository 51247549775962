import { useState, useEffect, useRef } from "react";

import {
  Typography,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  Chip,
  Avatar,
  List,
  ListItem,
} from "@material-ui/core";
import { ReportOutlined } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";

import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import getRequestDetails from "../../../api/get-request-details";
import updateBatchRequests from "../../../api/update-batch-requests";
import updateRequestStatus from "../../../api/update-request-status";

import { lightTheme } from "../../../assets/styles/themes";
import AccessDenied from "../../../components/AccessDenied";
import AccordionModal from "../../../components/AccordionModal";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomModal from "../../../components/CustomModal";
import CustomTooltip from "../../../components/CustomTooltip";
import Layout from "../../../components/Layout";
import StatusBadge from "../../../components/StatusBadge";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import TableFooter from "../../../components/TableFooter";
import TableHeader from "../../../components/TableHeader";

import applicationConfig from "../../../config/applicationConfig";
import eventsModuleConfig from "../../../config/eventsModuleConfig";
import marketingProgramsModuleConfig from "../../../config/marketingProgramsModuleConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";
import useRequestsFilters from "../../../hooks/useRequestsFilters";
import useUserProfile from "../../../hooks/useUserProfile";

import i18n from "../../../i18n/init";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import getStatusColorIcon from "../../../utilities/getStatusColorIcon";
import handleError from "../../../utilities/handleError";
import mapValuesFromKeys from "../../../utilities/mapValuesFromKeys";
import reverseObject from "../../../utilities/reverseObject";

import ConsentTexts from "../../Consents/NewConsentContainer/ConsentTexts/TemplateTexts";
import ConsentTemplateRequestOutputModal from "../../Consents/NewConsentTemplateContainer/components/ConsentTemplateRequestOutputModal";
import Versions from "../../Consents/NewConsentTemplateContainer/components/Versions";
import EventRequestOutputModal from "../../Events/NewEventsContainer/EventRequestOutputModal";
import EventsRequestOutputModal from "../../Events/UseEventContainer/components/EventsRequestOutputModal";
import InsightRequestOutputModal from "../../Insights/CreateInsightContainer/InsightRequestOutputModal";
import InsightDetailsModal from "../../Insights/InsightsContainer/components/InsightDetailsModal";
import { getComputeFrequency } from "../../Insights/InsightsContainer/helpers/insightsUtils";
import NewMarketingProgram from "../../MarketingPrograms/components/NewMarketingProgram";
import EcosystemMappingRequestOutputModal from "../../MarketingPrograms/NewMarketingProgramEcosystemMappingContainer/components/EcosystemMappingRequestOutputModal";
import MarketingProgramRequestOutputModal from "../../MarketingPrograms/NewMarketingProgramsContainer/components/MarketingProgramRequestOutputModal";
import NewOptsMapping from "../../Opts/components/NewOptsMapping";
import NewServiceName from "../../Opts/components/NewServiceName";
// import SelectedService from "../../Opts/components/SelectedService";
import OptIdRequestOutputModal from "../../Opts/NewOptIdContainer/components/OptIdRequestOutputModal";
import OptsRequestOutputModal from "../../Opts/NewOptsMappingContainer/components/OptsRequestOutputModal";
// import OptsMappingModal from "../../Opts/NewOptsStatusTrait/components/OptsMappingModal";
import OptsTraitRequestOutputModal from "../../Opts/NewOptsStatusTrait/components/OptsTraitRequestOutputModal";
import NewDataSource from "../../Sources/components/NewDataSource";
import NewSegmentSource from "../../Sources/components/NewSegmentSource";
import SourcesRequestOutputModal from "../../Sources/CreateSourcesContainer/components/SourcesRequestOutputModal";
import DisconnectSourcesRequestOutputModal from "../../Sources/DisconnectSourcesContainer/components/DisconnectSourcesRequestOutputModal";
import NewTrait from "../../Traits/components/NewTrait";
import TraitMpMappingModal from "../../Traits/components/TraitsMarketingProgramMappingModal";
import TraitsRequestOutputModal from "../../Traits/components/TraitsRequestOutputModal";
import buildMappings from "../../Traits/CreateTraitsContainer/helpers/buildMappings";
import AliasesModal from "../../TraitsResponses/NewTraitsResponseContainer/components/AliasesModal";
import TraitsResponseRequestOutputModal from "../../TraitsResponses/NewTraitsResponseContainer/components/TraitsResponseRequestOutputModal";
import UseCaseRequestOutputModal from "../../UseCaseManagement/NewUseCaseContainer/components/UseCaseRequestOutputModal";

import ReviewRequestModal from "./components/ReviewRequestModal";
import useStyles from "./styles";

const RequestsContainer = ({ config, type }) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    myRequests,
    loadMyRequests,
    searchFilter,
    setSearchFilter,
    myRequestsCount,
    setMyRequests,
    setMyRequestsCount,
  } = useRequestsFilters(config);
  const {
    requestStatus,
    myApprovalsTooltips,
    regionFilters,
    requestsSearchFilter,
    approvalsSearchFilter,
  } = applicationConfig;

  const {
    toolTipTextPending,
    toolTipTextApproved,
    toolTipTextReturned,
    toolTipTextProcessing,
  } = myApprovalsTooltips;
  const { t } = useTranslation();
  const { user } = useUserProfile();
  const { addNotification, notification } = useNotifier();
  const { setRequest, removeRequest, request } = useRequest();
  const commentRef = useRef("");
  const marketingProgramDescriptionRef = useRef("");
  const location = useLocation();
  const query = new URLSearchParams(window.location.search);
  const requestIdParams = query.get("requestId");
  const { mpEcosystemKeys } =
    marketingProgramsModuleConfig.mpEcosystemMappingConstants;

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();

  const toolTipHeadings = {
    [requestStatus.PENDING]: toolTipTextPending,
    [requestStatus.APPROVED]: toolTipTextApproved,
    [requestStatus.RETURNED]: toolTipTextReturned,
    [requestStatus.PROCESSING]: toolTipTextProcessing,
  };

  const { paginationConfig } = applicationConfig;
  const { eventsResponsesKeys } = eventsModuleConfig;

  const isUserAuthorized = checkUserAuthorization(user.access, config.access);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(paginationConfig.defaultPerPage);

  const [reviewRequestDetailsLoading, setReviewRequestDetailsLoading] =
    useState(false);

  const [, setOptMappingRows] = useState([]);
  const [, setMarketingProgramOpts] = useState([]);

  const [consentTexts, setConsentTexts] = useState([]);
  const [openConsentTexts, setOpenConsentTexts] = useState(false);

  const [consentTemplateVersions, setConsentTemplateVersions] = useState([]);
  const [openConsentTemplateVersions, setOpenConsentTemplateVersions] =
    useState(false);

  const [reviewRequestDetailsOpen, setReviewRequestDetailsOpen] =
    useState(false);
  const [reviewRequestDetailsOutput, setReviewRequestDetailsOutput] = useState(
    {}
  );
  const [editRequestOutput, setEditRequestOutput] = useState({});
  const [openEventsUseCaseModal, setOpenEventsUseCaseModal] = useState(false);
  const [useCaseModalData, setUseCaseModalData] = useState({});
  const [openTraitsUseCaseModal, setOpenTraitsUseCaseModal] = useState(false);
  const [selectedMappings, setSelectedMappings] = useState("");
  const [openOriginalMappings, setOpenOriginalMappings] = useState(false);

  const [newTraitModalOpen, setNewTraitModalOpen] = useState(false);
  const [traitModalData, setTraitModalData] = useState(null);
  const [requestOutput, setRequestOutput] = useState({});

  const [openDataSourceModal, setOpenDataSourceModal] = useState(false);
  const [newDataSource, setNewDataSource] = useState({});

  const [openSegmentSourceModal, setOpenSegmentSourceModal] = useState(false);
  const [segmentSource, setSegmentSource] = useState({});

  const [openEventMappingModal, setOpenEventMappingModal] = useState(false);

  const [openDisconnectSourcesModal, setOpenDisconnectSourcesModal] =
    useState(false);
  const [openEventPropertyModal, setOpenEventPropertyModal] = useState(false);
  const [eventMappings, setEventMappings] = useState({});
  const [marketingPrograms, setMarketingPrograms] = useState({});

  const [openOptsModal, setOpenOptsModal] = useState(false);
  const [optsData, setOptsData] = useState({});

  const [openEcosystemMappingModal, setOpenEcosystemMappingModal] =
    useState(false);
  const [ecosystemMappingModalDetails, setEcosystemMappingModalDetails] =
    useState({});

  const [marketingProgramModalData, setMarketingProgramModalData] = useState(
    {}
  );

  const [openServiceNameModal, setOpenServiceNameModal] = useState(false);
  const [serviceName, setServiceName] = useState({});
  const [openInsightsModal, setOpenInsightsModal] = useState(false);
  const [, setOpenOptMappingsModal] = useState(false);

  const [openMarketingProgramOptsModal, setOpenMarketingProgramOptsModal] =
    useState(false);
  const [openMarketingProgramModal, setOpenMarketingProgramModal] =
    useState(false);

  const [isRequestOutputLoading, setIsRequestOutputLoading] = useState(false);

  const [traitResponses, setTraitResponses] = useState({});
  const [openTraitResponsesModal, setOpenTraitResponsesModal] = useState(false);

  const [eventResponses, setEventResponses] = useState({});
  const [openEventResponsesModal, setOpenEventResponsesModal] = useState(false);

  const [confirmationStatus, setConfirmationStatus] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

  const [isRevisedRequest, setIsRevisedRequest] = useState(false);
  const [requestModule, setRequestModule] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [currentModal, setCurrentModal] = useState("");

  const [isBatchRequest, setIsBatchRequest] = useState(false);
  const [batchRequests, setBatchRequests] = useState([]);

  const isApprovalPage = type === "approvals";

  const moduleMapping = {
    traits: "traits",
    "trait-responses": "traits",
    insights: "insights",
    "event-responses": "events",
    "Use Case": "use-cases",
    Source: "data-sources",
    "Segment Source": "data-sources",
    "New Opt": "opts",
    "Opt Mapping": "opts",
    "Opt Traits": "opts",
    "Marketing Program": "marketing-programs",
    "Marketing Program Ecosystem": "marketing-programs",
    "Event Mapping": "events",
    "Event Properties": "events",
    consents: "consents",
    "Consent Template": "consents",
    [applicationConfig.modules.traits_use_case]: "use-cases",
    [applicationConfig.modules.events_use_case]: "use-cases",
  };

  const displayModuleMapping = {
    traits: "Traits",
    "data-sources": "Data Sources",
    opts: "Opts",
    "marketing-programs": "Marketing Programs",
    consents: "Consents",
    events: "Events",
    insights: "Insights",
  };

  const convertToUiSchema = (trait) => {
    const ruleMapping = reverseObject(
      traitsModuleConfig.survivorshipRuleMapping
    );
    return {
      ...trait,
      personalData: trait.personalData,
      personalDataNonPii: trait.personalDataNonPii,
      ecoSystemTraits: trait.ecosystemTraits.map((ecosystem) => {
        return {
          ...ecosystem,
          dataType:
            ecosystem.dataType.charAt(0).toUpperCase() +
            ecosystem.dataType.slice(1),
        };
      }),
      traitDescription: trait?.description,
      dataType:
        trait.dataType.charAt(0).toUpperCase() + trait.dataType.slice(1),
      traitInputType: trait.formInput,
      frequencyChange: trait.uDataChangeFrequency,
      traitResponseType: trait.multiAnswerResponseIndicator
        ? "Multiple"
        : "Single",
      survivorshipRule: ruleMapping[trait.survivorshipRule],
    };
  };

  const renderName = (details) => {
    if (details.trait || (details.traits && details.traits.length === 1)) {
      let trait;
      if (details.traits) {
        [trait] = details.traits;
      } else {
        trait = details.trait;
      }
      if (!trait.traitId) {
        return (
          <div
            role="button"
            aria-hidden="true"
            className={classes.traitName}
            onClick={() => {
              setNewTraitModalOpen(true);
              setTraitModalData(convertToUiSchema(trait));
            }}
          >
            <span>{trait.traitName}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        );
      }
      return `${trait.traitId} - ${trait.traitName}`;
    }
    if (details.segmentSource) {
      // Segment Source
      if (!details.segmentSource.ecosystemSourceId) {
        return (
          <div
            role="button"
            aria-hidden="true"
            className={classes.traitName}
            onClick={() => {
              setOpenSegmentSourceModal(true);
              setSegmentSource({
                ...details.segmentSource,
                sourceIntegrationType:
                  details.segmentSource.ecosystemSourceType,
              });
            }}
          >
            <span>{details.segmentSource.ecosystemSourceName}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        );
      }
      return details.segmentSource.ecosystemSourceName;
    }
    if (!details.segmentSource && details.dataSource) {
      // Data Source
      return (
        <div
          role="button"
          aria-hidden="true"
          className={classes.traitName}
          onClick={() => {
            setOpenDataSourceModal(true);
            setNewDataSource({
              ...details.dataSource,
            });
          }}
        >
          <span>{details.dataSource.sourceName}</span>
          <Button
            disableElevation
            variant="contained"
            className={classes.badge}
          >
            <span className={classes.badgeText}>{t("common.new")}</span>
          </Button>
        </div>
      );
    }
    if (details.dataSources) {
      return (
        <div className={classes.flexVertical}>
          {details.dataSources.map((source) => {
            if (source.sourceId) {
              return (
                <div key={source.sourceName}>
                  {source.sourceId}-{source.sourceName}
                </div>
              );
            }
            return (
              <div
                role="button"
                aria-hidden="true"
                className={classes.traitName}
                onClick={() => {
                  setOpenDataSourceModal(true);
                  setNewDataSource({
                    ...source,
                  });
                }}
              >
                <span>{source.sourceName}</span>
                <Button
                  disableElevation
                  variant="contained"
                  className={classes.badge}
                >
                  <span className={classes.badgeText}>{t("common.new")}</span>
                </Button>
              </div>
            );
          })}
        </div>
      );
    }
    if (details.opt) {
      if (details.service && details.service.isNew) {
        return (
          <div
            role="button"
            aria-hidden="true"
            className={classes.traitName}
            onClick={() => {
              setOpenServiceNameModal(true);
              setServiceName({
                ...details.service,
                marketingProgramName: `${details.marketingProgram?.marketingProgramNumber} - ${details.marketingProgram?.description}`,
                serviceNameDescription: details.service?.description,
              });
            }}
          >
            <span>{details.service.serviceName}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        );
      }
      if (details.service) {
        return details.service.serviceName;
      }
    }

    return "";
  };

  const renderOptId = (details) => {
    return (
      <div
        role="button"
        aria-hidden="true"
        className={classes.traitName}
        onClick={() => {
          setOpenOptsModal(true);
          setOptsData({ ...details });
        }}
      >
        <span>{details.opt.optId}</span>
      </div>
    );
  };

  const renderMarketingProgramName = (details) => {
    if (!details.marketingProgram) {
      return "";
    }
    return (
      <div
        role="button"
        aria-hidden="true"
        className={classes.traitName}
        onClick={() => {
          setOpenMarketingProgramModal(true);
          setMarketingProgramModalData({ ...details });
        }}
      >
        <span>{details.marketingProgram?.description}</span>
        <Button disableElevation variant="contained" className={classes.badge}>
          <span className={classes.badgeText}>{t("common.new")}</span>
        </Button>
      </div>
    );
  };

  const renderMpEcosystems = (ecosystems) => {
    return (
      <div
        role="button"
        aria-hidden="true"
        className={classes.traitName}
        onClick={() => {
          setOpenEcosystemMappingModal(true);
          setEcosystemMappingModalDetails(ecosystems);
        }}
      >
        <span>View MP Ecosystems</span>
      </div>
    );
  };

  const getModuleData = (details) => {
    let module = applicationConfig.modules.traits; //  traits;
    if (
      (!details.requestDetails.trait && !details.requestDetails.traits) ||
      details.requestDetails.dataUseCase
    ) {
      if (details.requestDetails.insight) {
        // insight
        return {
          ...details,
          module: applicationConfig.modules.insights,
        };
      }
      if (
        details.requestDetails.opt &&
        details.requestDetails.marketingProgramOpts
      ) {
        module = applicationConfig.modules.new_opt;
      } else if (details.requestDetails.opt && details.requestDetails.service) {
        module = applicationConfig.modules.opt_mapping;
      } else if (details.requestDetails.consentText) {
        // Thid need to merge with next condition, for time being go ahead with this
        module = applicationConfig.modules.consents;
      } else if (
        details.requestDetails.consent &&
        details.module === applicationConfig.modules.consents
      ) {
        module = applicationConfig.modules.consents;
      } else if (
        details.requestDetails.segmentSource &&
        details.requestDetails.mapToSegmentSource
      ) {
        module = applicationConfig.modules.segment_source;
      } else if (
        !details.requestDetails.marketingPrograms &&
        (details.requestDetails.dataSource ||
          details.requestDetails.dataSources)
      ) {
        module = applicationConfig.modules.source;
      } else if (
        (details.requestDetails.marketingProgram &&
          details.requestDetails?.legalEntity &&
          details.requestDetails.contactPointTypes) ||
        (details.requestDetails.marketingProgram &&
          details.requestDetails.ecosystems)
      ) {
        if (details.requestDetails.ecosystems) {
          module = applicationConfig.modules.marketing_program_ecosystem;
        } else {
          module = applicationConfig.modules.marketing_program;
        }
      } else if (details.requestDetails.dataUseCase) {
        if (
          details.requestDetails.eventProperties &&
          details.requestDetails.scope
        ) {
          module = applicationConfig.modules.events_use_case;
        } else if (
          details.requestDetails.traits &&
          details.requestDetails.scope
        ) {
          module = applicationConfig.modules.traits_use_case;
        }
      } else if (
        details.requestDetails.eventProperties &&
        details.requestDetails.marketingPrograms
      ) {
        module = applicationConfig.modules.event_mapping;
      } else if (details.requestDetails.eventProperties) {
        module = applicationConfig.modules.event_properties;
      } else if (details.requestDetails.eventPropertyResponses) {
        module = applicationConfig.modules.events_responses;
      } else if (
        details.requestDetails?.marketingPrograms?.length > 0 &&
        details.requestDetails?.dataSources?.length > 0
      ) {
        module = applicationConfig.modules.data_source_disconnect;
      }
    } else if (details.requestDetails.traitResponses) {
      module = applicationConfig.modules.traits_responses;
    } else if (
      details.requestDetails.trait &&
      details.requestDetails.optMappings
    ) {
      module = applicationConfig.modules.opt_traits;
      return {
        ...details,
        module,
      };
    }
    return {
      ...details,
      module,
    };
  };

  const getScopeLabel = (details, modifiedDetails) => {
    let label = "";

    if (details.scope.type === "global") {
      label = "Global";
    }

    if (details.scope.type === "regional") {
      label = `${modifiedDetails.scope.regionCode} - ${modifiedDetails.scope.regionName} (Regional)`;
    }

    if (details.scope.type === "legalEntity") {
      label = `${modifiedDetails.scope.legalEntityId} - ${modifiedDetails.scope.legalEntityName} (Legal Entity)`;
    }

    if (details.scope.type === "marketingProgram") {
      label = `${modifiedDetails.scope?.marketingProgramNumber} - ${modifiedDetails.scope?.description} (Marketing Program)`;
    }
    return label;
  };

  const renderScopeResponses = (details, module) => {
    const modifiedDetails = {
      ...details,
    };

    const label = getScopeLabel(details, modifiedDetails);

    modifiedDetails.title = label;

    return (
      <div
        role="button"
        aria-hidden="true"
        className={classes.traitName}
        onClick={() => {
          if (module === applicationConfig.modules.events_responses) {
            setOpenEventResponsesModal(true);
            setEventResponses({
              eventName: modifiedDetails.eventName,
              propertyName: modifiedDetails.propertyName,
              eventPropertyResponses: modifiedDetails.eventPropertyResponses,
            });
          } else if (module === applicationConfig.modules.traits_responses) {
            setOpenTraitResponsesModal(true);
            setTraitResponses({
              traitName: modifiedDetails.trait.traitName,
              traitResponses: modifiedDetails.traitResponses,
            });
          }
        }}
      >
        <span>{label}</span>
      </div>
    );
  };

  const renderScopeUseCase = (details, module) => {
    let label = "";

    let modifiedDetails = {
      ...details,
    };

    if (module === applicationConfig.modules.events_use_case) {
      modifiedDetails = {
        ...modifiedDetails,
        eventProperties: details.eventProperties.properties.map((x) =>
          "deleted" in x ? x : { ...x, new: true }
        ),
      };
    } else if (module === applicationConfig.modules.traits_use_case) {
      modifiedDetails = {
        ...modifiedDetails,
        traits: details.traits.map((x) =>
          "deleted" in x ? x : { ...x, new: true }
        ),
      };
    }

    if (details.scope.type === "global") {
      label = "Global";
    }

    if (details.scope.type === "regional") {
      label = `${modifiedDetails.scope.regionCode} - ${modifiedDetails.scope.regionName} (Regional)`;
    }

    if (details.scope.type === "legalEntity") {
      label = `${modifiedDetails.scope.legalEntityId} - ${modifiedDetails.scope.legalEntityName} (Legal Entity)`;
    }

    if (details.scope.type === "marketingProgram") {
      label = `${modifiedDetails.scope?.marketingProgramNumber} - ${modifiedDetails.scope?.description} (Marketing Program)`;
    }

    modifiedDetails.title = label;

    return (
      <div
        role="button"
        aria-hidden="true"
        className={classes.traitName}
        onClick={() => {
          if (module === applicationConfig.modules.events_use_case) {
            setOpenEventsUseCaseModal(true);
            setUseCaseModalData(modifiedDetails);
          } else if (module === applicationConfig.modules.traits_use_case) {
            setOpenTraitsUseCaseModal(true);
            setUseCaseModalData(modifiedDetails);
          }
        }}
      >
        <span>{label}</span>
      </div>
    );
  };

  const handleReviewRequestModal = async (
    requestId,
    isPending,
    requestDetails
  ) => {
    setReviewRequestDetailsLoading(true);
    setReviewRequestDetailsOpen(true);
    setReviewRequestDetailsOutput({});
    try {
      const detailsResponse = await getRequestDetails(requestId);
      const response = getModuleData(detailsResponse);
      setRequestOutput(response);
      setEditRequestOutput(response);
      setRequestModule(response.module);
      if (
        response &&
        response.originalRequestDetails &&
        Object.keys(response.originalRequestDetails).length > 0
      ) {
        setIsRevisedRequest(true);
      } else {
        setIsRevisedRequest(false);
      }
      let moduleSpecificRequestDetails = {};
      if (response.module === applicationConfig.modules.traits) {
        moduleSpecificRequestDetails = {
          survivorshipRules: (
            <div
              role="button"
              aria-hidden="true"
              className={classes.traitName}
              onClick={() => {
                setSelectedMappings("survivorshipRules");
              }}
            >
              <span>View Survivorship Rules</span>
            </div>
          ),
          traitResponseTypes: (
            <div
              role="button"
              aria-hidden="true"
              className={classes.traitName}
              onClick={() => {
                setSelectedMappings("traitResponseTypes");
              }}
            >
              <span>View Trait Response Types</span>
            </div>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalSurvivorshipRules: (
              <div
                role="button"
                aria-hidden="true"
                className={classes.traitName}
                onClick={() => {
                  setOpenOriginalMappings(true);
                  setSelectedMappings("survivorshipRules");
                }}
              >
                <span>View Survivorship Rules</span>
              </div>
            ),
            originalTraitResponseTypes: (
              <div
                role="button"
                aria-hidden="true"
                className={classes.traitName}
                onClick={() => {
                  setOpenOriginalMappings(true);
                  setSelectedMappings("traitResponseTypes");
                }}
              >
                <span>View Trait Response Types</span>
              </div>
            ),
          };
        }
      } else if (response.module === applicationConfig.modules.insights) {
        moduleSpecificRequestDetails = {
          ...moduleSpecificRequestDetails,
          insightId: (
            <div
              role="button"
              aria-hidden="true"
              className={classes.traitName}
              onClick={() => {
                setOpenInsightsModal(true);
              }}
            >
              <span>View Insight Info</span>
            </div>
          ),
        };

        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalInsightName:
              response.originalRequestDetails.insight.insightName ||
              response.insightName,
          };
        }
      } else if (
        response.module === applicationConfig.modules.source ||
        response.module === applicationConfig.modules.segment_source
      ) {
        moduleSpecificRequestDetails = {
          dataSources: (
            <div className={classes.flexVertical}>
              {response.requestDetails.dataSources.map((source) => {
                if (source.sourceId) {
                  return (
                    <div key={source.sourceName}>
                      {source.sourceId}-{source.sourceName}
                    </div>
                  );
                }
                return (
                  <div
                    role="button"
                    aria-hidden="true"
                    className={classes.traitName}
                    onClick={() => {
                      setOpenDataSourceModal(true);
                      setNewDataSource({
                        ...source,
                      });
                    }}
                  >
                    <span>{source.sourceName}</span>
                    <Button
                      disableElevation
                      variant="contained"
                      className={classes.badge}
                    >
                      <span className={classes.badgeText}>
                        {t("common.new")}
                      </span>
                    </Button>
                  </div>
                );
              })}
            </div>
          ),
          dataSourceMarketingPrograms:
            response.requestDetails.marketingProgram &&
            response.requestDetails.marketingProgram?.description
              ? `${response.requestDetails.marketingProgram?.marketingProgramNumber} - ${response.requestDetails.marketingProgram?.description}`
              : null,
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalDataSources: (
              <div className={classes.flexVertical}>
                {response.originalRequestDetails.dataSources?.map((source) => {
                  if (source.sourceId) {
                    return (
                      <div key={source.sourceName}>
                        {source.sourceId}-{source.sourceName}
                      </div>
                    );
                  }
                  return (
                    <div
                      role="button"
                      aria-hidden="true"
                      className={classes.traitName}
                      onClick={() => {
                        setOpenDataSourceModal(true);
                        setNewDataSource({
                          ...source,
                        });
                      }}
                    >
                      <span>{source.sourceName}</span>
                      <Button
                        disableElevation
                        variant="contained"
                        className={classes.badge}
                      >
                        <span className={classes.badgeText}>
                          {t("common.new")}
                        </span>
                      </Button>
                    </div>
                  );
                })}
              </div>
            ),
            originalDataSourceMarketingPrograms: response.originalRequestDetails
              .marketingProgram?.description
              ? `${response.originalRequestDetails.marketingProgram?.marketingProgramNumber} - ${response.originalRequestDetails.marketingProgram?.description}`
              : null,
          };
        }
      } else if (response.module === applicationConfig.modules.new_opt) {
        moduleSpecificRequestDetails = {
          optMarketingProgram: `${response.requestDetails.marketingProgram?.marketingProgramNumber} - ${response.requestDetails.marketingProgram?.description}`,
          optTextEnglish: (
            <div
              style={{ fontSize: "0.875rem" }}
              dangerouslySetInnerHTML={{
                __html: response.requestDetails.opt.optTextEnglish,
              }}
            />
          ),
          optTextLocalLanguage: (
            <div
              style={{ fontSize: "0.875rem" }}
              dangerouslySetInnerHTML={{
                __html: response.requestDetails.opt.optTextLocalLanguage,
              }}
            />
          ),
          optId: response.requestDetails.opt.optId,
          optMappings: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setMarketingProgramOpts(
                  response.requestDetails.marketingProgramOpts
                );
                setOpenMarketingProgramOptsModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("opts_requests.click_here")}
            </span>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalOptMarketingProgram: `${response.originalRequestDetails.marketingProgram?.marketingProgramNumber} - ${response.originalRequestDetails.marketingProgram?.description}`,
            originalOptTextEnglish:
              response.originalRequestDetails.opt.optTextEnglish,
            originalOptTextLocalLanguage:
              response.originalRequestDetails.opt.optTextLocalLanguage,
            originalOptMappings: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setMarketingProgramOpts(
                    response.originalRequestDetails.marketingProgramOpts
                  );
                  setOpenMarketingProgramOptsModal(true);
                }}
                className={classes.badgeText2}
              >
                {t("opts_requests.click_here")}
              </span>
            ),
          };
        }
      } else if (response.module === applicationConfig.modules.opt_mapping) {
        if (response.requestDetails.marketingProgram) {
          marketingProgramDescriptionRef.current =
            response.requestDetails.marketingProgram?.description;
        } else {
          marketingProgramDescriptionRef.current = "";
        }
        moduleSpecificRequestDetails = {
          optId: response.requestDetails.opt
            ? renderOptId(response.requestDetails)
            : /* istanbul ignore next */ "",
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalOptId:
              response.originalRequestDetails &&
              response.originalRequestDetails.opt
                ? renderOptId(response.originalRequestDetails)
                : /* istanbul ignore next */ "",
          };
        }
      } else if (response.module === applicationConfig.modules.opt_traits) {
        moduleSpecificRequestDetails = {
          optTraitMarketingProgram: `${response.requestDetails.marketingProgram?.marketingProgramNumber} - ${response.requestDetails.marketingProgram?.description}`,
          channel:
            response.requestDetails.contactPointType.contactPointTypeName,
          optTraitNames: (
            <div
              className={clsx(classes.flexContainerVertical, classes.wordBreak)}
            >
              <span>
                {response.requestDetails.trait.optChoiceDate.traitName}
              </span>
              <span>
                {response.requestDetails.trait.optIndicator.traitName}
              </span>
            </div>
          ),
          optMappings: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setOptMappingRows(response.requestDetails.optMappings);
                setOpenOptMappingsModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("opts_requests.click_here")}
            </span>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalOptTraitMarketingProgram: `${response.originalRequestDetails.marketingProgram?.marketingProgramNumber} - ${response.originalRequestDetails.marketingProgram?.description}`,
            originalChannel:
              response.originalRequestDetails.contactPointType
                .contactPointTypeName,
            originalOptTraitNames: (
              <div className={classes.flexContainerVertical}>
                <span>
                  {
                    response.originalRequestDetails.trait.optChoiceDate
                      .traitName
                  }
                </span>
                <span>
                  {response.originalRequestDetails.trait.optIndicator.traitName}
                </span>
              </div>
            ),
            originalOptMappings: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setOptMappingRows(
                    response.originalRequestDetails.optMappings
                  );
                  setOpenOptMappingsModal(true);
                }}
                className={classes.badgeText2}
              >
                {t("opts_requests.click_here")}
              </span>
            ),
          };
        }
      } else if (
        response?.requestDetails?.consentText &&
        response.module === applicationConfig.modules.consents
      ) {
        // Consent template request
        moduleSpecificRequestDetails = {
          ...moduleSpecificRequestDetails,
          consentTemplateType: `${response?.requestDetails?.consentTemplateType?.consentTemplateTypeId} - ${response?.requestDetails?.consentTemplateType?.description}`,
          country: `${response?.requestDetails?.country?.countryCode} - ${response?.requestDetails?.country?.countryName}`,
          language: `${response?.requestDetails?.language?.languageCode} - ${response?.requestDetails?.language?.languageName}`,
          versions: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setConsentTemplateVersions([
                  {
                    ...response.requestDetails,
                  },
                ]);
                setOpenConsentTemplateVersions(true);
              }}
              className={classes.badgeText2}
            >
              View Versions
            </span>
          ),
          originalConsentTemplateType: `${response?.originalRequestDetails?.consentTemplateType?.consentTemplateTypeId} - ${response?.originalRequestDetails?.consentTemplateType?.description}`,
          originalCountry: `${response?.originalRequestDetails?.country?.countryCode} - ${response?.originalRequestDetails?.country?.countryName}`,
          originalLanguage: `${response?.originalRequestDetails?.language?.languageCode} - ${response?.originalRequestDetails?.language?.languageName}`,
          originalVersions: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setConsentTemplateVersions([
                  {
                    ...response.originalRequestDetails,
                  },
                ]);
                setOpenConsentTemplateVersions(true);
              }}
              className={classes.badgeText2}
            >
              View Versions
            </span>
          ),
        };
      } else if (response.module === applicationConfig.modules.consents) {
        // Consent ID request
        moduleSpecificRequestDetails = {
          ...moduleSpecificRequestDetails,
          consentId: response.requestDetails.consent?.consentId,
          marketingProgram: `${response.requestDetails.marketingProgram?.marketingProgramNumber} - ${response.requestDetails.marketingProgram?.description}`,
          consentTemplateType: `${response.requestDetails.consent.consentIds?.[0]?.consentId} - ${response.requestDetails.consent.consentIds?.[0]?.consentTemplateType}`,
          consentTexts: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setConsentTexts(
                  response.requestDetails?.consent?.consentTexts?.reduce(
                    (acc, curr) => {
                      const key = `${curr.consentTemplateId}-${curr.country.countryCode}-${curr.language.languageCode}`;
                      const newAcc = {
                        ...acc,
                        [key]: {
                          ...curr,
                          countryCode: curr.country.countryCode,
                          languageCode: curr.language.languageCode,
                          language: curr.language.language,
                          consentTemplateType:
                            response.requestDetails.consent.consentIds?.[0]
                              ?.consentTemplateType,
                        },
                      };
                      return newAcc;
                    },
                    {}
                  ) || {}
                );
                setOpenConsentTexts(true);
              }}
              className={classes.badgeText2}
            >
              View Consent Texts
            </span>
          ),
          optMappings: (
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setMarketingProgramOpts(
                  response.requestDetails.marketingProgramOpts
                );
                setOpenMarketingProgramOptsModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("opts_requests.click_here")}
            </span>
          ),
        };

        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalConsentId:
              response.originalRequestDetails?.consent?.consentId,
            originalMarketingProgram: `${response.originalRequestDetails.marketingProgram?.marketingProgramNumber} - ${response.originalRequestDetails.marketingProgram?.description}`,
            originalConsentTemplateType: `${response.originalRequestDetails.consentTemplateType?.consentTemplateTypeId} - ${response.originalRequestDetails.consentTemplateType?.description}`,
          };
        }
      } else if (
        response.module === applicationConfig.modules.marketing_program ||
        response.module ===
          applicationConfig.modules.marketing_program_ecosystem
      ) {
        if (!response.requestDetails.marketingProgram?.marketingProgramNumber) {
          moduleSpecificRequestDetails = {
            marketingProgramDescription: renderMarketingProgramName(
              response.requestDetails
            ),
          };
          if (response.requestDetails.ecosystems) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              mpEcosystems: renderMpEcosystems(
                response.requestDetails.ecosystems
              ),
            };
          }
          if (response.originalRequestDetails) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              originalMarketingProgramDescription: renderMarketingProgramName(
                response.originalRequestDetails
              ),
            };
            if (response.originalRequestDetails.ecosystems) {
              moduleSpecificRequestDetails = {
                ...moduleSpecificRequestDetails,
                originalMpEcosystems: renderMpEcosystems(
                  response.originalRequestDetails.ecosystems
                ),
              };
            }
          }
        } else {
          marketingProgramDescriptionRef.current =
            response.requestDetails.marketingProgram?.description;
          moduleSpecificRequestDetails = {
            marketingProgramDescription: `${response.requestDetails.marketingProgram?.marketingProgramNumber} - ${response.requestDetails.marketingProgram?.description}`,
            mpEcosystems: renderMpEcosystems(
              response.requestDetails.ecosystems
            ),
          };
          if (response.originalRequestDetails) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              originalMarketingProgramDescription: renderMarketingProgramName(
                response.originalRequestDetails || {}
              ),
            };
            if (response.originalRequestDetails.ecosystems) {
              moduleSpecificRequestDetails = {
                ...moduleSpecificRequestDetails,
                originalMpEcosystems: renderMpEcosystems(
                  response.originalRequestDetails.ecosystems
                ),
              };
            }
          }
        }
      } else if (
        response.module === applicationConfig.modules.traits_use_case ||
        response.module === applicationConfig.modules.events_use_case
      ) {
        moduleSpecificRequestDetails = {
          useCaseId: response.requestDetails.dataUseCase.useCaseId,
          useCaseDescription:
            response.requestDetails.dataUseCase.useCaseDescription,
          useCaseName: !response.requestDetails.dataUseCase.useCaseId ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>{response.requestDetails.dataUseCase.useCaseName}</p>
              <Button
                variant="contained"
                color="primary"
                style={{
                  marginLeft: "8px",
                  borderRadius: "20px",
                  padding: "2px",
                }}
              >
                New
              </Button>
            </div>
          ) : (
            response.requestDetails.dataUseCase.useCaseName
          ),
        };
        if (response.module === applicationConfig.modules.traits_use_case) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            scope: renderScopeUseCase(
              response.requestDetails,
              applicationConfig.modules.traits_use_case
            ),
          };
          if (response.requestDetails.traits.some((trait) => trait.deleted)) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              warning: (
                <Alert severity="error">
                  {t("create_use_case.trait_deletion_approval_warning")}
                </Alert>
              ),
            };
          }
        } else if (
          response.module === applicationConfig.modules.events_use_case
        ) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            eventName: response.requestDetails.eventProperties.eventName,
            scope: renderScopeUseCase(
              response.requestDetails,
              applicationConfig.modules.events_use_case
            ),
          };
          if (
            response.requestDetails.eventProperties?.properties?.some(
              (property) => property.deleted
            )
          ) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              warning: (
                <Alert severity="error">
                  {t("create_use_case.property_deletion_approval_warning")}
                </Alert>
              ),
            };
          }
        }
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalUseCaseDescription:
              response.originalRequestDetails.dataUseCase.useCaseDescription,
            originalUseCaseName:
              response.originalRequestDetails.dataUseCase.useCaseName,
          };
          if (response.module === applicationConfig.modules.traits_use_case) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              originalScope: renderScopeUseCase(
                response.originalRequestDetails,
                applicationConfig.modules.traits_use_case
              ),
            };
          } else if (
            response.module === applicationConfig.modules.events_use_case
          ) {
            moduleSpecificRequestDetails = {
              ...moduleSpecificRequestDetails,
              originalEventName:
                response.originalRequestDetails.eventProperties.eventName,
              originalScope: renderScopeUseCase(
                response.originalRequestDetails,
                applicationConfig.modules.events_use_case
              ),
            };
          }
        }
      } else if (response.module === applicationConfig.modules.event_mapping) {
        moduleSpecificRequestDetails = {
          eventName: response.requestDetails.eventName || response.name,
          eventProperties: (
            <span
              data-testid="view-event-properties"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setEventMappings({
                  eventName: response.requestDetails.eventName || response.name,
                  eventProperties: response.requestDetails.eventProperties,
                });
                setOpenEventMappingModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("events_container.view_event_properties")}
            </span>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalEventName:
              response.originalRequestDetails.eventName || response.name,
            originalEventProperties: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setEventMappings({
                    eventName:
                      response.originalRequestDetails.eventName ||
                      response.name,
                    eventProperties:
                      response.originalRequestDetails.eventProperties,
                  });
                  setOpenEventMappingModal(true);
                }}
                className={classes.badgeText2}
              >
                {t("events_container.view_event_properties")}
              </span>
            ),
          };
        }
      } else if (
        response.module === applicationConfig.modules.data_source_disconnect
      ) {
        moduleSpecificRequestDetails = {
          dataSource:
            response.requestDetails.dataSources[0].sourceName || response.name,
          name: response.name,
          marketingPrograms: (
            <span
              data-testid="view-source-marketing-programs"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setMarketingPrograms({
                  marketingPrograms: response.requestDetails.marketingPrograms,
                });
                setOpenDisconnectSourcesModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("disconnect_sources.view_marketing_program")}
            </span>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalDataSource:
              response.originalRequestDetails.dataSources[0].sourceName ||
              response.name,
            name: response.name,
            originalMarketingPrograms: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setMarketingPrograms({
                    marketingPrograms:
                      response.originalRequestDetails.marketingPrograms,
                  });
                  setOpenDisconnectSourcesModal(true);
                }}
                className={classes.badgeText2}
              >
                {t("disconnect_sources.view_marketing_program")}
              </span>
            ),
          };
        }
      } else if (
        response.module === applicationConfig.modules.event_properties
      ) {
        moduleSpecificRequestDetails = {
          eventName: response.requestDetails.eventName || response.name,
          eventProperties: (
            <span
              data-testid="view-event-properties"
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              onClick={() => {
                setEventMappings({
                  eventName: response.requestDetails.eventName || response.name,
                  eventProperties: response.requestDetails.eventProperties,
                });
                setOpenEventPropertyModal(true);
              }}
              className={classes.badgeText2}
            >
              {t("events_container.view_event_properties")}
            </span>
          ),
        };
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalEventName:
              response.originalRequestDetails.eventName || response.name,
            originalEventProperties: (
              <span
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
                onClick={() => {
                  setEventMappings({
                    eventName:
                      response.originalRequestDetails.eventName ||
                      response.name,
                    eventProperties:
                      response.originalRequestDetails.eventProperties,
                  });
                  setOpenEventPropertyModal(true);
                }}
                className={classes.badgeText2}
              >
                {t("events_container.view_event_properties")}
              </span>
            ),
          };
        }
      } else if (
        response.module === applicationConfig.modules.traits_responses
      ) {
        let { traitName } = response.requestDetails.trait;
        if (response.hasWarning) {
          traitName = (
            <div className={classes.flexContainer}>
              <span>{response.requestDetails.trait.traitName}</span>
              <ReportOutlined className={classes.warningIcon} />
            </div>
          );
        }
        moduleSpecificRequestDetails = {
          traitName,
          traitResponses: renderScopeResponses(
            response.requestDetails,
            applicationConfig.modules.traits_responses
          ),
        };
        if (
          response.requestDetails.traitResponses.some(
            (property) => property.deleted && !property.isUpdated
          )
        ) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            warning: (
              <Alert severity="error">
                {t("create_use_case.trait_responses_deletion_approval_warning")}
              </Alert>
            ),
          };
        }
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalTraitResponses: renderScopeResponses(
              response.originalRequestDetails,
              applicationConfig.modules.traits_responses
            ),
          };
        }
      } else if (
        response.module === applicationConfig.modules.events_responses
      ) {
        moduleSpecificRequestDetails = {
          eventName: response.requestDetails.eventName,
          propertyName: response.requestDetails.propertyName,
          eventPropertyResponses: renderScopeResponses(
            response.requestDetails,
            applicationConfig.modules.events_responses
          ),
        };
        if (
          response.requestDetails.eventPropertyResponses.some(
            (property) => property.deleted && !property.isUpdated
          )
        ) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            warning: (
              <Alert severity="error">
                {t(
                  "create_use_case.property_responses_deletion_approval_warning"
                )}
              </Alert>
            ),
          };
        }
        if (response.originalRequestDetails) {
          moduleSpecificRequestDetails = {
            ...moduleSpecificRequestDetails,
            originalEventPropertyResponses: renderScopeResponses(
              response.originalRequestDetails,
              applicationConfig.modules.events_responses
            ),
          };
        }
      }

      // Set Common Request Details
      setReviewRequestDetailsOutput({
        isPending,
        requestId: response.requestId.toString(),
        requestDate: moment(response.requestDate).format(
          applicationConfig.common.yyyy_mm_dd
        ),
        requester: response.requestedBy,
        region: (
          <CustomTooltip
            items={mapValuesFromKeys(
              regionFilters,
              applicationConfig.common.name,
              applicationConfig.common.label,
              response.geoRegionCodes
            )}
          />
        ),
        name: renderName(response.requestDetails),
        module: response.module,
        message: requestDetails.message,
        errorMessage: requestDetails.message ? (
          <Alert severity="error" variant="filled">
            {requestDetails.message}
          </Alert>
        ) : null,
        comment:
          response.comments && response.comments.length > 0
            ? response.comments
            : t("common.NA"),
        reviewer:
          response.reviewedBy && response.reviewedBy.length > 0
            ? response.reviewedBy
            : t("common.NA"),
        status: <StatusBadge status={response.status.toLowerCase()} />,
        statusText: response.status.toLowerCase(),
        ecosystems: (
          <CustomTooltip
            items={(response.requestDetails.ecosystems || []).map(
              (eco) => eco.ecosystemName
            )}
          />
        ),
        marketingPrograms: (
          <CustomTooltip
            items={(response.requestDetails.marketingPrograms || []).map((mp) =>
              mp?.description
                ? `${mp?.marketingProgramNumber} - ${mp?.description}`
                : `${mp?.marketingProgramNumber} - ${mp.marketingProgramName}`
            )}
          />
        ),
        ...moduleSpecificRequestDetails,
        originalName: response.originalRequestDetails
          ? renderName(response.originalRequestDetails)
          : null,
        originalEcosystems: (
          <CustomTooltip
            items={
              response.originalRequestDetails
                ? (response.originalRequestDetails.ecosystems || []).map(
                    (eco) => eco.ecosystemName
                  )
                : []
            }
          />
        ),
        originalMarketingPrograms: (
          <CustomTooltip
            items={
              response.originalRequestDetails
                ? (response.originalRequestDetails.marketingPrograms || []).map(
                    (mp) =>
                      `${mp?.marketingProgramNumber} - ${
                        mp?.description || mp.marketingProgramName
                      }`
                  )
                : []
            }
          />
        ),
      });
      setReviewRequestDetailsLoading(false);
    } catch (error) {
      setReviewRequestDetailsOpen(false);
      setReviewRequestDetailsLoading(false);
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const getRevisionFlowUrl = (data) => {
    let url = "";

    if (data.module === applicationConfig.modules.traits) {
      url = applicationConfig.pathnames.traits_requests_revision;
    } else if (
      [
        applicationConfig.modules.use_case,
        applicationConfig.modules.traits_use_case,
        applicationConfig.modules.events_use_case,
      ].includes(data.module)
    ) {
      url = applicationConfig.pathnames.use_cases_revision;
    } else if (data.module === applicationConfig.modules.segment_source) {
      url = applicationConfig.pathnames.sources_revision;
    } else if (data.module === applicationConfig.modules.source) {
      url = applicationConfig.pathnames.sources_revision;
    } else if (data.module === applicationConfig.modules.new_opt) {
      url = applicationConfig.pathnames.opts_revision;
    } else if (data.module === applicationConfig.modules.opt_mapping) {
      url = applicationConfig.pathnames.opts_mapping_revision;
    } else if (data.module === applicationConfig.modules.opt_traits) {
      url = applicationConfig.pathnames.opts_status_manage;
    } else if (
      data.module === applicationConfig.modules.marketing_program ||
      data.module === applicationConfig.modules.marketing_program_ecosystem
    ) {
      url = applicationConfig.pathnames.marketing_program_map_revision;
    } else if (data.module === applicationConfig.modules.event_mapping) {
      url = applicationConfig.pathnames.events_map_revision;
    } else if (data.module === applicationConfig.modules.traits_responses) {
      url = applicationConfig.pathnames.traits_responses_revision;
    } else if (data.module === applicationConfig.modules.events_responses) {
      url = applicationConfig.pathnames.traits_responses_revision;
    } else if (data.module === applicationConfig.modules.consent_template) {
      url = applicationConfig.pathnames.consent_templates_manage;
    } else if (data.module === applicationConfig.modules.consents) {
      url = applicationConfig.pathnames.consent_manage;
    } else if (data.module === applicationConfig.modules.event_properties) {
      url = applicationConfig.pathnames.events_manage;
    } else if (data.module === applicationConfig.modules.insights) {
      url = applicationConfig.pathnames.insight_requests_revision;
    } else if (
      data.module === applicationConfig.modules.data_source_disconnect
    ) {
      url = applicationConfig.pathnames.sources_manage;
    }

    return url;
  };

  useEffect(() => {
    if (requestIdParams && requestIdParams.length > 0) {
      handleReviewRequestModal(requestIdParams, "", {});
    }
  }, [requestIdParams]);

  const handleApprovalsEditFlow = (data) => {
    setRequest(data);
    history.push(getRevisionFlowUrl(data));
  };

  const handleEditFlow = async (requestId) => {
    let url = "";
    increaseRequestsCount();
    const detailsResponse = await getRequestDetails(requestId);
    const details = getModuleData(detailsResponse);

    setRequest(details);
    decreaseRequestsCount();
    url = getRevisionFlowUrl(details);

    history.push({
      pathname: url,
      state: {
        requestId,
      },
    });
  };

  const openModal = (module) => {
    setCurrentModal(module);
  };

  const handleOutputModal = async (requestId, apiRequest) => {
    setRequestOutput({
      output: {
        warnings: [],
        items: [],
      },
    });
    // Open a random modal
    setCurrentModal(applicationConfig.modules.traits);
    setIsRequestOutputLoading(true);
    setShowModal(true);
    try {
      const details = await apiRequest(requestId, moduleMapping[requestModule]);
      setIsRequestOutputLoading(false);
      // For status based output
      if (details.requestDetails) {
        const moduleData = getModuleData(details);
        if (details.requestDetails.marketingProgram) {
          marketingProgramDescriptionRef.current =
            details.requestDetails.marketingProgram?.description;
        }
        openModal(moduleData.module);
        setRequestOutput(details);
      }
    } catch (error) {
      setCurrentModal(null);
      handleError({
        error,
        handle404: () => {
          setMyRequestsCount(0);
        },
        addNotification,
      });
      setMyRequests([]);
    }
  };

  const getDefaultStatusFilters = () => {
    if (isApprovalPage) {
      return [requestStatus.PENDING];
    }
    return [
      applicationConfig.filters.all,
      requestStatus.APPROVED,
      requestStatus.REJECTED,
      requestStatus.PENDING,
      requestStatus.RETURNED,
      requestStatus.PROCESSING,
    ];
  };

  const renderHeader = () => {
    const visibleFilters = [
      applicationConfig.filters.status,
      applicationConfig.filters.date_range,
    ];
    let searchFilters = requestsSearchFilter;
    visibleFilters.push(applicationConfig.filters.modules);
    if (config.type === applicationConfig.common.approval) {
      visibleFilters.push(applicationConfig.filters.regions);
      searchFilters = approvalsSearchFilter;
    }
    return (
      <TableHeader
        handleFilterChange={setSearchFilter}
        searchFilter={searchFilter}
        defaultSearchFilterName=""
        searchFilters={searchFilters}
        visibleFilters={visibleFilters}
        isApprovalPage={isApprovalPage}
        defaultStatus={getDefaultStatusFilters()}
      />
    );
  };

  const requestsTableColumns = config.tableColumns.map((column) => {
    if (column.field === applicationConfig.common.status) {
      return {
        ...column,
        renderCell: (params) => {
          const { value, row } = params;

          const statusColorIcon = getStatusColorIcon(value, requestStatus);

          const { statusColor } = statusColorIcon;
          const { statusIcon } = statusColorIcon;

          // if (isApprovalPage) {
          //   if (value !== requestStatus.APPROVED) {
          //     statusIcon = <div />;
          //   }
          // }

          return (
            <>
              <div
                className={classes.statusWrapper}
                style={{
                  backgroundColor: statusColor,
                  color: lightTheme.palette.static.pureGrey,
                }}
              >
                {statusIcon[0]}
                <span className={classes.statusText}>{value}</span>
              </div>
              <StyledTooltip
                placement="top"
                title={<span>{toolTipHeadings[value]}</span>}
              >
                <div
                  onClick={() => {
                    if (value === requestStatus.APPROVED) {
                      handleOutputModal(row.requestId, getRequestDetails);
                    }
                    if (value === requestStatus.RETURNED) {
                      handleEditFlow(row.requestId);
                    }
                  }}
                  role="button"
                  aria-hidden="true"
                  data-testid="clickable-cell"
                  className={classes.toolTipBtn}
                >
                  {statusIcon[1]}
                </div>
              </StyledTooltip>
            </>
          );
        },
      };
    }
    if (column.field === applicationConfig.common.region) {
      return {
        ...column,
        renderCell: (params) => {
          const { value } = params;
          return (
            <List disablePadding className={classes.regionColumn}>
              {value.split(",").map((i) => (
                <ListItem disableGutters>{i?.trim()}</ListItem>
              ))}
            </List>
          );
        },
      };
    }
    if (
      column.field === applicationConfig.common.name ||
      column.field === applicationConfig.common.trait_name ||
      column.field === applicationConfig.common.source_name ||
      column.field === applicationConfig.common.service_name ||
      column.field === applicationConfig.common.opt_text_english ||
      column.field === applicationConfig.common.marketing_program_description
    ) {
      return {
        ...column,
        renderCell: (params) => {
          const { row } = params;
          if (row.isNew) {
            return (
              <div
                role="button"
                aria-hidden="true"
                className={classes.traitName}
                onClick={() => {
                  handleReviewRequestModal(
                    row.requestId,
                    row.status === requestStatus.PENDING,
                    row
                  );
                }}
              >
                <span>{row.name}</span>
                <Button
                  disableElevation
                  variant="contained"
                  className={classes.badge}
                >
                  <span className={classes.badgeText}>{t("common.new")}</span>
                </Button>
              </div>
            );
          }
          if (row.hasWarning) {
            return (
              <div className={classes.flexContainer}>
                <span>{row.name}</span>
                <ReportOutlined className={classes.warningIcon} />
              </div>
            );
          }
          return row.name;
        },
      };
    }
    if (column.field === "") {
      return {
        ...column,
        field: "",
        sortable: false,
        renderHeader: () => {
          if (config.type !== "approval") {
            return "";
          }
          const handleChange = () => {
            setIsBatchRequest(!isBatchRequest);
            setBatchRequests([]);
          };
          return (
            <div className={classes.switchHeader}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isBatchRequest}
                      onChange={handleChange}
                      color="primary"
                      name="checkedB"
                      inputProps={{ "aria-label": "primary checkbox" }}
                    />
                  }
                  label={t("requests.table_columns.batch_review")}
                />
              </FormGroup>
            </div>
          );
        },
        renderCell: (params) => {
          const { row } = params;
          const isRequestSelected = batchRequests.some(
            (_request) => _request.requestId === row.requestId
          );
          if (isBatchRequest) {
            if (
              row.status === requestStatus.PENDING ||
              row.status === requestStatus.RETURNED
            ) {
              return (
                <div
                  className={classes.checkboxContainer}
                  data-testid="checkbox-container"
                >
                  <Checkbox
                    checked={isRequestSelected}
                    onClick={() => {
                      if (isRequestSelected) {
                        const requestIndex = batchRequests.findIndex(
                          (_request) => {
                            if (row.requestId === _request.requestId) {
                              return true;
                            }
                            return false;
                          }
                        );
                        batchRequests.splice(requestIndex, 1);
                      } else {
                        batchRequests.push(row);
                      }
                      setBatchRequests(() => {
                        return [...batchRequests];
                      });
                    }}
                  />
                </div>
              );
            }
            return <></>;
          }
          return (
            <div className={classes.btnContainer}>
              {config.type === applicationConfig.common.approval ? (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleReviewRequestModal(
                      row.requestId,
                      row.status === requestStatus.PENDING,
                      row
                    );
                  }}
                >
                  {row.status === requestStatus.PENDING
                    ? t("common.labels.review_request1")
                    : t("common.labels.view_request")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    handleReviewRequestModal(
                      row.requestId,
                      row.status === requestStatus.PENDING,
                      row
                    );
                  }}
                >
                  {t("common.labels.view_request")}
                </Button>
              )}
            </div>
          );
        },
      };
    }
    return column;
  });

  const getConfirmationModalDetails = () => {
    if (confirmationStatus === requestStatus.APPROVED) {
      return {
        title: t("dialogs.confirm_approval"),
        message: isBatchRequest
          ? t("dialogs.confirm_batch_approval_message")
          : t("dialogs.confirm_approval_message"),
        btn1Text: t("common.cancel"),
        btn2Text: t("common.approve"),
        btn2Action: async () => {
          try {
            increaseRequestsCount(2);
            if (isBatchRequest) {
              const batchRequestsApi = batchRequests.map((_request) => ({
                requestId: _request.requestId,
                comments: commentRef.current?.trim(),
                module: _request.module,
              }));
              await updateBatchRequests({
                requests: batchRequestsApi.map((_batchRequest) => ({
                  ..._batchRequest,
                  status: requestStatus.APPROVED.toLowerCase(),
                })),
              });
              addNotification(t("notifications.bulk_approval_success"), "info");
            } else {
              await updateRequestStatus(
                reviewRequestDetailsOutput.requestId,
                requestStatus.APPROVED.toLowerCase(),
                commentRef.current?.trim(),
                moduleMapping[requestModule]
              );
              addNotification(t("notifications.approved_success"), "info");
            }
            setReviewRequestDetailsOpen(false);
            setConfirmationModalOpen(false);
            commentRef.current = "";
            setBatchRequests(() => {
              return [];
            });
            setIsBatchRequest(false);
            await loadMyRequests(1, 20, searchFilter);
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification,
            });
            setReviewRequestDetailsOpen(true);
          } finally {
            decreaseRequestsCount(2);
          }
        },
        type: applicationConfig.status.success,
      };
    }
    if (confirmationStatus === requestStatus.REJECTED) {
      return {
        title: t("dialogs.confirm_reject"),
        message: isBatchRequest
          ? t("dialogs.confirm_batch_rejected_message")
          : t("dialogs.confirm_rejected_message"),
        btn1Text: t("common.cancel"),
        btn2Text: t("common.reject"),
        btn2Action: async () => {
          try {
            increaseRequestsCount(2);
            if (isBatchRequest) {
              const batchRequestsApi = batchRequests.map((_request) => ({
                requestId: _request.requestId,
                comments: commentRef.current?.trim(),
                module: _request.module,
              }));
              await updateBatchRequests({
                requests: batchRequestsApi.map((_batchRequest) => ({
                  ..._batchRequest,
                  status: requestStatus.REJECTED.toLowerCase(),
                })),
              });
              addNotification(t("notifications.bulk_approval_success"), "info");
            } else {
              await updateRequestStatus(
                reviewRequestDetailsOutput.requestId,
                requestStatus.REJECTED.toLowerCase(),
                commentRef.current?.trim(),
                moduleMapping[requestModule]
              );
              addNotification(t("notifications.rejected_success"), "info");
            }
            setReviewRequestDetailsOpen(false);
            setConfirmationModalOpen(false);
            commentRef.current = "";
            setBatchRequests(() => {
              return [];
            });
            setIsBatchRequest(false);
            await loadMyRequests(1, 20, searchFilter);
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification,
            });
            setReviewRequestDetailsOpen(true);
          } finally {
            decreaseRequestsCount(2);
          }
        },
        type: "error",
      };
    }
    if (confirmationStatus === requestStatus.RETURNED) {
      return {
        title: t("dialogs.confirm_return"),
        message: isBatchRequest
          ? t("dialogs.confirm_batch_returned_message")
          : t("dialogs.confirm_returned_message"),
        btn1Text: t("common.cancel"),
        btn2Text: t("common.return"),
        btn2Action: async () => {
          try {
            increaseRequestsCount(2);
            if (isBatchRequest) {
              const batchRequestsApi = batchRequests.map((_request) => ({
                requestId: _request.requestId,
                comments: commentRef.current?.trim(),
                module: _request.module,
              }));
              await updateBatchRequests({
                requests: batchRequestsApi.map((_batchRequest) => ({
                  ..._batchRequest,
                  status: requestStatus.RETURNED.toLowerCase(),
                })),
              });
              addNotification(t("notifications.bulk_approval_success"), "info");
            } else {
              await updateRequestStatus(
                reviewRequestDetailsOutput.requestId,
                requestStatus.RETURNED.toLowerCase(),
                commentRef.current?.trim(),
                moduleMapping[requestModule]
              );
              addNotification(t("notifications.returned_success"), "info");
            }
            setReviewRequestDetailsOpen(false);
            setConfirmationModalOpen(false);
            commentRef.current = "";
            setIsBatchRequest(false);
            setBatchRequests(() => {
              return [];
            });
            await loadMyRequests(1, 20, searchFilter);
          } catch (error) {
            handleError({
              error,
              handle404: false,
              addNotification,
            });
            setReviewRequestDetailsOpen(true);
          } finally {
            decreaseRequestsCount(2);
          }
        },
        type: "info",
      };
    }
    return {};
  };

  const confirmationDetails = getConfirmationModalDetails();

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      const endDate = searchFilter.find((filter) => filter.name === "endDate");
      if (
        (!endDate?.value ||
          (endDate?.value.length > 0 &&
            moment(endDate.value, "YYYY-MM-DD").isValid())) &&
        searchFilter.length > 0
      ) {
        loadMyRequests(page, perPage, searchFilter);
      }
    }
  }, [user, searchFilter, page, perPage]);

  useEffect(async () => {
    if (isApprovalPage) {
      const { requestId } = request;
      if (requestId && String(requestId).length > 0) {
        const requestDetails = await getRequestDetails(requestId);
        handleReviewRequestModal(
          requestId,
          requestDetails?.status === requestStatus.PENDING,
          requestDetails
        );
        setRequest({});
      }
    }
  }, [location.pathname]);

  const { requestId } = requestOutput;
  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied showButton goToText="Go To Home" goToLink="/" />
  ) : (
    <div className={classes.themeWrapper}>
      <Layout
        header={
          <div className={classes.a}>
            <Typography variant="h4" gutterBottom>
              {config.name}
            </Typography>
          </div>
        }
        childrenClassName={classes.layoutChildren}
      >
        <div className={classes.tableHeader}>{renderHeader()}</div>
        <Table
          rows={myRequests.map((row) => ({
            requestId: row.requestId,
            requestDate: moment(row.requestDate).format(
              applicationConfig.common.yyyy_mm_dd
            ),
            name: row.name,
            isNew: row.isNew,
            sourceName: row.name,
            traitName: row.name,
            serviceName: row.name,
            marketingProgramDescription: row.name,
            module: displayModuleMapping[row.module],
            status: row.status.charAt(0).toUpperCase() + row.status.slice(1),
            message: row.message,
            requestedBy: row.requestedBy,
            reviewer: row.reviewedBy,
            comments: row.comments,
            isRevised: row.isRevised,
            id: row.id,
            hasWarning: row.hasWarning,
            region: mapValuesFromKeys(
              regionFilters,
              applicationConfig.common.name,
              applicationConfig.common.label,
              row.geoRegionCodes
            ).join(", "),
          }))}
          columns={requestsTableColumns.map((data) => ({
            ...data,
            sortable: false,
            editable: false,
            disableClickEventBubbling: true,
          }))}
          // initApiCall={(_page, _perPage, _searchFilter) =>
          //   _searchFilter.length > 0 &&
          //   loadMyRequests(_page, _perPage, _searchFilter)
          // }
          // filters={searchFilter}
          onPageChange={setPage}
          onPerPageChange={setPerPage}
          tableStyle={classes.requestsTableStyle}
          externalLoading={loading}
          totalItems={myRequestsCount}
          error={notification.message}
        />

        {currentModal === applicationConfig.modules.traits && showModal && (
          <TraitsRequestOutputModal
            requestId={requestId}
            traitOutput={requestOutput}
            isLoading={isRequestOutputLoading}
            open={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
          />
        )}

        {currentModal === applicationConfig.modules.traits_use_case &&
          showModal && (
            <UseCaseRequestOutputModal
              requestId={requestId}
              data={{
                ...requestOutput.output,
                useCaseName: requestOutput.name,
              }}
              isLoading={isRequestOutputLoading}
              isOpen={showModal}
              traits={requestOutput.requestDetails?.traits}
              marketingProgram={requestOutput.requestDetails?.marketingProgram}
              onClose={() => {
                setShowModal(false);
              }}
              module={applicationConfig.modules.traits_use_case}
            />
          )}

        {currentModal === applicationConfig.modules.events_use_case &&
          showModal && (
            <UseCaseRequestOutputModal
              requestId={requestId}
              data={{
                ...requestOutput.output,
                useCaseName: requestOutput.name,
              }}
              isLoading={isRequestOutputLoading}
              isOpen={showModal}
              eventProperties={
                requestOutput.requestDetails?.eventProperties?.properties
              }
              onClose={() => {
                setShowModal(false);
              }}
              module={applicationConfig.modules.events_use_case}
            />
          )}

        {currentModal === applicationConfig.modules.traits_responses &&
          showModal && (
            <TraitsResponseRequestOutputModal
              requestId={requestId}
              data={{
                ...requestOutput.output,
                traitName: requestOutput.requestDetails?.trait.traitName,
                isGlobal:
                  requestOutput.requestDetails?.scope?.type === "global",
              }}
              isOpen={showModal}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}
        {currentModal === applicationConfig.modules.events_responses &&
          showModal && (
            <TraitsResponseRequestOutputModal
              requestId={requestId}
              data={{
                ...requestOutput.output,
                eventName: requestOutput.requestDetails?.eventName,
                propertyName: requestOutput.requestDetails?.propertyName,
                isGlobal:
                  requestOutput.requestDetails?.scope?.type === "global",
              }}
              isOpen={showModal}
              onClose={() => {
                setShowModal(false);
              }}
            />
          )}

        {currentModal === applicationConfig.modules.event_mapping && showModal && (
          <EventsRequestOutputModal
            requestId={requestId}
            data={requestOutput.output}
            isLoading={isRequestOutputLoading}
            isOpen={showModal}
            onClose={() => {
              setShowModal(false);
            }}
          />
        )}

        {(currentModal === applicationConfig.modules.source ||
          currentModal === applicationConfig.modules.segment_source) &&
          showModal && (
            <SourcesRequestOutputModal
              isOpen={showModal}
              requestId={requestId}
              data={requestOutput.output}
              inputData={requestOutput}
              setShowSourceOutput={() => {
                setShowModal(false);
              }}
              isLoading={isRequestOutputLoading}
            />
          )}

        {currentModal === applicationConfig.modules.data_source_disconnect &&
          showModal && (
            <DisconnectSourcesRequestOutputModal
              isOpen={showModal}
              requestId={requestId}
              data={{
                ...requestOutput.output,
                status: requestOutput.status,
                message: requestOutput.message,
              }}
              inputData={requestOutput}
              setShowOutput={() => {
                setShowModal(false);
              }}
              isLoading={isRequestOutputLoading}
            />
          )}

        {currentModal === applicationConfig.modules.insights && showModal && (
          <InsightRequestOutputModal
            isOpen={showModal}
            requestId={requestId}
            data={{
              ...requestOutput.output,
              status: requestOutput.status,
              message: requestOutput.message,
              description: requestOutput.requestDetails?.insight?.description,
              insightsName: requestOutput.requestDetails?.insight?.insightsName,
            }}
            inputData={requestOutput}
            setShowOutput={() => {
              setShowModal(false);
            }}
            isLoading={isRequestOutputLoading}
          />
        )}

        {currentModal === applicationConfig.modules.new_opt && showModal && (
          <OptIdRequestOutputModal
            isOpen={showModal}
            isLoading={isRequestOutputLoading}
            requestId={requestId}
            data={requestOutput.output}
            setShowOptOutput={() => setShowModal(false)}
            marketingProgramDescription={marketingProgramDescriptionRef.current}
          />
        )}

        {currentModal === applicationConfig.modules.event_properties &&
          showModal && (
            <EventRequestOutputModal
              isOpen={showModal}
              isLoading={isRequestOutputLoading}
              requestId={requestId}
              data={requestOutput.output}
              setShowOutput={() => setShowModal(false)}
            />
          )}

        {currentModal === applicationConfig.modules.consent_template &&
          showModal && (
            <ConsentTemplateRequestOutputModal
              isOpen={showModal}
              isLoading={isRequestOutputLoading}
              requestId={requestId}
              data={requestOutput.output}
              setShowOutput={() => setShowModal(false)}
            />
          )}

        {currentModal === applicationConfig.modules.opt_mapping &&
          showModal && (
            <OptsRequestOutputModal
              isOpen={showModal}
              isLoading={isRequestOutputLoading}
              requestId={requestId}
              data={requestOutput.output}
              setShowOptOutput={() => setShowModal(false)}
              marketingProgramDescription={
                marketingProgramDescriptionRef.current
              }
            />
          )}

        {currentModal === applicationConfig.modules.opt_traits && showModal && (
          <OptsTraitRequestOutputModal
            isOpen={showModal}
            isLoading={isRequestOutputLoading}
            requestId={requestId}
            data={requestOutput.output}
            setShowOptsTraitOutput={() => setShowModal(false)}
          />
        )}

        {currentModal === applicationConfig.modules.marketing_program &&
          showModal && (
            <MarketingProgramRequestOutputModal
              isOpen={showModal}
              isLoading={isRequestOutputLoading}
              requestId={requestId}
              data={requestOutput.output}
              setShowOutput={() => setShowModal(false)}
            />
          )}

        {currentModal ===
          applicationConfig.modules.marketing_program_ecosystem &&
          showModal && (
            <EcosystemMappingRequestOutputModal
              isOpen={showModal}
              isLoading={isRequestOutputLoading}
              requestId={requestId}
              data={requestOutput.output}
              marketingProgramDescription={
                marketingProgramDescriptionRef.current
              }
              setShowOutput={() => setShowModal(false)}
            />
          )}

        {config.type === applicationConfig.common.request && (
          <ReviewRequestModal
            open={reviewRequestDetailsOpen}
            handleClose={() => {
              setReviewRequestDetailsOpen(false);
            }}
            handleEditRequest={() => {
              handleApprovalsEditFlow(editRequestOutput);
            }}
            handleOutput={() => {
              setCurrentModal(requestModule);
              setShowModal(true);
            }}
            data={reviewRequestDetailsOutput}
            module={requestModule}
            isLoading={reviewRequestDetailsLoading}
            status={reviewRequestDetailsOutput.statusText}
            isRevised={isRevisedRequest}
          />
        )}

        {config.type === applicationConfig.common.approval && (
          <ReviewRequestModal
            open={reviewRequestDetailsOpen}
            handleClose={() => {
              setReviewRequestDetailsOpen(false);
              removeRequest();
              commentRef.current = "";
            }}
            handleAction={(status) => {
              setConfirmationModalOpen(true);
              setConfirmationStatus(status);
            }}
            handleEditRequest={() => {
              handleApprovalsEditFlow(editRequestOutput);
            }}
            handleOutput={(data) => {
              handleOutputModal(data.requestId, getRequestDetails);
            }}
            data={reviewRequestDetailsOutput}
            module={requestModule}
            isApprovalPage
            isLoading={reviewRequestDetailsLoading}
            status={reviewRequestDetailsOutput.statusText}
            isRevised={isRevisedRequest}
            commentRef={commentRef}
            isBatchRequest={
              isBatchRequest &&
              Array.isArray(reviewRequestDetailsOutput?.batchRequests)
            }
          />
        )}

        {openDataSourceModal && (
          <NewDataSource
            isOpen={openDataSourceModal}
            newDataSource={newDataSource}
            setNewDataSourceModal={setOpenDataSourceModal}
            title={`${t("sources_requests.new_data_source")} - ${
              newDataSource.sourceName
            }`}
          />
        )}

        {openSegmentSourceModal && (
          <NewSegmentSource
            isOpen={openSegmentSourceModal}
            newSegmentSource={segmentSource}
            setNewSegmentSourceModal={setOpenSegmentSourceModal}
            isDisabled
          />
        )}

        {openOptsModal && (
          <NewOptsMapping
            isOpen={openOptsModal}
            optsMappingInfo={optsData}
            setNewOptsMappingModal={() => setOpenOptsModal(false)}
            title={t("opts_mapping.modal_title")}
          />
        )}

        {openServiceNameModal && (
          <NewServiceName
            isOpen={openServiceNameModal}
            newServiceName={serviceName}
            isDisabled
            setNewServiceNameModal={() => setOpenServiceNameModal(false)}
          />
        )}

        {openMarketingProgramModal && (
          <NewMarketingProgram
            isOpen={openMarketingProgramModal}
            info={marketingProgramModalData}
            setNewMarketingProgramModal={() =>
              setOpenMarketingProgramModal(false)
            }
            title={t("marketing_programs_container.modal_title")}
          />
        )}

        {openTraitsUseCaseModal && (
          <AccordionModal
            open={openTraitsUseCaseModal}
            onClose={() => setOpenTraitsUseCaseModal(false)}
            title={`${useCaseModalData.title}`}
            subtitle={t("create_use_case.traits_use_case_mapping")}
            rowKeys={{
              title: "traitName",
              subTitle: "description",
            }}
            keys={[
              {
                name: t("common.labels.trait_name"),
                id: "traitName",
              },
              {
                name: t("common.labels.trait_id"),
                id: "traitId",
              },
              {
                name: t("common.labels.description"),
                id: "description",
              },
              {
                name: t("common.labels.personal_data"),
                id: "personalData",
              },
              {
                name: "Labels",
                id: "labels",
              },
            ]}
            rows={useCaseModalData.traits.map((x) => ({
              ...x,
              labels: (x.labels || []).map((y, index) => (
                <div key={index} className={classes.accordionChips}>
                  <Chip
                    label={y.labelName}
                    color="primary"
                    avatar={y.labelId ? <></> : <Avatar>NEW</Avatar>}
                  />
                </div>
              )),
            }))}
          />
        )}

        {openEventsUseCaseModal && (
          <AccordionModal
            open={openEventsUseCaseModal}
            onClose={() => setOpenEventsUseCaseModal(false)}
            title={`${useCaseModalData.title}`}
            subtitle={t("create_use_case.events_use_case_mapping")}
            rowKeys={{
              title: "eventProperty",
              subTitle: "eventPropertyDescription",
            }}
            keys={[
              {
                name: t("use_events_container.property_name"),
                id: "eventProperty",
              },
              {
                name: t("description"),
                id: "eventPropertyDescription",
              },
              {
                name: "Labels",
                id: "labels",
              },
            ]}
            rows={useCaseModalData.eventProperties.map((x) => ({
              ...x,
              labels: (x.labels || []).map((y, index) => (
                <div key={index} className={classes.accordionChips}>
                  <Chip
                    label={y.labelName}
                    color="primary"
                    avatar={<Avatar>NEW</Avatar>}
                  />
                </div>
              )),
            }))}
          />
        )}

        {openInsightsModal && (
          <InsightDetailsModal
            title="Insight Details"
            subtitle="Insight Info"
            data={{
              insightDetail: {
                ...requestOutput?.requestDetails?.insight,
                customIndicator:
                  requestOutput?.requestDetails?.insight?.isSQLTrait,
                sqlExpression:
                  requestOutput?.requestDetails?.insight?.sqlExpression,
                inputTraits:
                  requestOutput?.requestDetails?.insight?.inputTraits?.map(
                    (trait) => `${trait.traitId} - ${trait.traitName}`
                  ),
                outputTrait: `${requestOutput?.requestDetails?.insight?.outputTrait?.traitId} - ${requestOutput?.requestDetails?.insight?.outputTrait?.traitName}`,
                insightsId: requestOutput?.requestDetails?.insight?.insightsId,
              },
              insightMappings:
                requestOutput?.requestDetails?.marketingPrograms?.map(
                  (marketingProgram) => ({
                    marketingProgram: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
                    status: marketingProgram.status,
                    computeFrequency: getComputeFrequency(
                      marketingProgram?.computeFrequency,
                      marketingProgram?.schedule
                    ),
                    processingType: marketingProgram.processingType,
                    sourceDetailsText: marketingProgram?.source,
                    destinationDetailsText: marketingProgram?.destination,
                    new: marketingProgram?.isNew,
                    isUpdated: marketingProgram?.isUpdated,
                    deleted: marketingProgram?.deleted,
                  })
                ),
            }}
            loading={false}
            open={openInsightsModal}
            onClose={() => setOpenInsightsModal(false)}
            request
          />
        )}

        {openEventMappingModal && (
          <AccordionModal
            open={openEventMappingModal}
            onClose={() => setOpenEventMappingModal(false)}
            title={`${eventMappings.eventName}`}
            subtitle={t("events_container.mapped_properties")}
            rowKeys={{
              title: "valueType",
              subTitle: "propertyName",
            }}
            keys={[
              {
                id: "propertyName",
                name: t("events_container.event_accordion_keys.property_name"),
              },
              {
                id: "description",
                name: t("common.labels.description"),
              },
              {
                id: "dataType",
                name: t("common.labels.data_type"),
              },
              {
                id: "valueType",
                name: t("events_container.event_accordion_keys.value_types"),
              },
            ]}
            rows={eventMappings.eventProperties?.map(
              ({ valueType, ...rest }) => ({
                ...rest,
                valueType: valueType.join(", "),
              })
            )}
          />
        )}

        {openDisconnectSourcesModal && (
          <AccordionModal
            open={openDisconnectSourcesModal}
            onClose={() => setOpenDisconnectSourcesModal(false)}
            title={t("disconnect_sources.disconnected_mpns")}
            subtitle={t("disconnect_sources.mapped_marketing_programs")}
            rowKeys={{
              title: "marketingProgramNumber",
              subTitle: "description",
            }}
            keys={[
              {
                id: "marketingProgramNumber",
                name: t("common.labels.marketing_program_number"),
              },
              {
                id: "description",
                name: t("common.labels.description"),
              },
            ]}
            rows={marketingPrograms.marketingPrograms}
          />
        )}

        {openEventPropertyModal && (
          <AccordionModal
            open={openEventPropertyModal}
            onClose={() => setOpenEventPropertyModal(false)}
            title={`${eventMappings.eventName}`}
            subtitle={t("events_container.new_event_properties")}
            rowKeys={{
              title: "propertyName",
              subTitle: "description",
            }}
            keys={[
              {
                id: "propertyName",
                name: t("events_container.event_accordion_keys.property_name"),
              },
              {
                id: "description",
                name: t("common.labels.description"),
              },
              {
                id: "dataType",
                name: t("common.labels.data_type"),
              },
              {
                id: "sampleValues",
                name: t("events_container.event_properties_keys.sample_values"),
              },
              {
                id: "required",
                name: "Required for Event",
              },
              {
                id: "personalData",
                name: "Personal (PII) Data",
              },
              {
                id: "personalDataNonPii",
                name: "Sensitive (NON-PII) Data",
              },
              {
                id: "healthData",
                name: "Health Data",
              },
            ]}
            rows={eventMappings.eventProperties}
          />
        )}

        {openTraitResponsesModal && (
          <AccordionModal
            open={openTraitResponsesModal}
            onClose={() => setOpenTraitResponsesModal(false)}
            title={`${traitResponses.traitName} - Trait Responses`}
            subtitle={t("manage_traits_responses.request_page_modal_subtitle")}
            rowKeys={{
              title: "title",
              subTitle: "subTitle",
            }}
            keys={[
              {
                id: "traitResponseLocalLanguage",
                name: i18n.t(
                  "traitsResponses.accordion_keys.trait_response_local_language"
                ),
              },
              {
                id: "language",
                name: i18n.t("traitsResponses.accordion_keys.language"),
              },
              {
                id: "traitResponseEnglish",
                name: i18n.t(
                  "traitsResponses.accordion_keys.trait_response_english"
                ),
              },
              {
                id: "predefinedTraitResponse",
                name: i18n.t(
                  "traitsResponses.accordion_keys.predefined_trait_response"
                ),
              },
              {
                id: "predefinedTraitResponseAliases",
                name: i18n.t(
                  "traitsResponses.accordion_keys.predefined_trait_response_aliases"
                ),
              },
            ]}
            rows={traitResponses.traitResponses.map((response) => {
              let status = { new: true };
              if (response.deleted && response.isUpdated) {
                status = { existing: true };
              } else if (response.new && response.isUpdated) {
                status = { updated: true };
              } else if (response.deleted) {
                status = { deleted: true };
              }

              return {
                ...status,
                ...response,
                traitResponseLocalLanguage:
                  response.predefinedTraitResponseLocalLanguage ||
                  response.traitResponseLocalLanguage,
                language: `${
                  response.languageName || response.language || "English"
                } (${response.languageCode?.toUpperCase() || "EN"})`,
                title: response.languageCode?.toUpperCase() || "EN",
                subTitle:
                  response.predefinedTraitResponseLocalLanguage ||
                  response.traitResponseLocalLanguage
                    ? response.predefinedTraitResponseLocalLanguage ||
                      response.traitResponseLocalLanguage
                    : response.globalStandardResponse ||
                      response.predefinedTraitResponse,
                traitResponseEnglish:
                  response.predefinedTraitResponse || response.traitResponse,
                predefinedTraitResponseAliases:
                  response.predefinedTraitResponseAliases?.length > 0 ? (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        setCurrentModal({
                          modal: "traitResponseAliases",
                          data: {
                            predefinedTraitResponseAliases:
                              response.predefinedTraitResponseAliases.map(
                                (trtResponse, index) => ({
                                  ...trtResponse,
                                  id: index,
                                })
                              ) || [],
                          },
                        });
                        setShowModal(true);
                      }}
                    >
                      View Aliases
                    </Button>
                  ) : (
                    <Typography style={{ fontSize: "0.875rem" }}>
                      None
                    </Typography>
                  ),
                predefinedTraitResponse:
                  response.predefinedTraitResponseLocalLanguage ||
                  response.traitResponseLocalLanguage
                    ? response.globalStandardResponse
                    : response.globalStandardResponse ||
                      response.predefinedTraitResponse,
              };
            })}
          />
        )}

        {openEcosystemMappingModal && (
          <AccordionModal
            open={openEcosystemMappingModal}
            onClose={() => setOpenEcosystemMappingModal(false)}
            title="MP Ecosystem Mappings"
            subtitle={t(
              "mp_ecosystem_mapping_container.mp_eco_system_modal_title"
            )}
            rowKeys={{
              title: "ecosystemName",
              subTitle: "ecosystemMarketingProgramName",
            }}
            keys={mpEcosystemKeys}
            rows={ecosystemMappingModalDetails?.map((response) => {
              let status = { new: true };
              if (response.new && response.isUpdated) {
                status = applicationConfig.status.updated;
              } else if (response.deleted && response.isUpdated) {
                status = applicationConfig.status.existing;
              } else if (response.deleted) {
                status = applicationConfig.status.deleted;
              } else if (response.new) {
                status = applicationConfig.status.new;
              }
              if (response.isUpdated) {
                status = { existing: true };
              } else if (
                ecosystemMappingModalDetails.filter(
                  (x) => x.ecosystemId === response.ecosystemId
                ).length === 2
              ) {
                status = { updated: true };
              }

              return {
                ...response,
                ...status,
              };
            })}
          />
        )}

        {currentModal.modal === "traitResponseAliases" && showModal && (
          <AliasesModal
            openManageAliasesModal={showModal}
            viewOnly
            isGlobal={requestOutput.requestDetails.scope.type === "global"}
            setOpenManageAliasesModal={() => {
              setShowModal(false);
              setCurrentModal({});
            }}
            traitResponses={currentModal.data.predefinedTraitResponseAliases}
          />
        )}

        {openEventResponsesModal && (
          <AccordionModal
            open={openEventResponsesModal}
            onClose={() => setOpenEventResponsesModal(false)}
            title={`${eventResponses.eventName} - ${eventResponses.propertyName} Event Property Responses`}
            subtitle={t("manage_events_responses.request_page_modal_subtitle")}
            rowKeys={{
              title: "title",
              subTitle: "subTitle",
            }}
            keys={eventsResponsesKeys}
            rows={eventResponses.eventPropertyResponses.map((response) => {
              let status = { new: true };
              if (response.deleted && response.isUpdated) {
                status = { existing: true };
              } else if (response.new && response.isUpdated) {
                status = { updated: true };
              } else if (response.deleted) {
                status = { deleted: true };
              }

              return {
                ...status,
                predefinedEventPropertyResponseLocalLanguage:
                  response.predefinedEventPropertyResponseLocalLanguage,
                language: `${response.language || "English"} (${
                  response.languageCode?.toUpperCase() || "EN"
                })`,
                title: response.languageCode?.toUpperCase() || "EN",
                subTitle: response.predefinedEventPropertyResponseLocalLanguage
                  ? response.predefinedEventPropertyResponseLocalLanguage
                  : response.globalStandardResponse ||
                    response.predefinedEventPropertyResponse,
                globalStandardResponse: response.globalStandardResponse,
                predefinedEventPropertyResponse:
                  response.predefinedEventPropertyResponse ||
                  response.predefinedEventPropertyResponseEnglish ||
                  response.globalStandardResponse,
              };
            })}
          />
        )}

        {openMarketingProgramOptsModal && (
          <AccordionModal
            open={openMarketingProgramOptsModal}
            onClose={() => setOpenMarketingProgramOptsModal(false)}
            title={`${reviewRequestDetailsOutput.requestId} - ${t(
              "common.labels.opt_mappings"
            )}`}
            rowKeys={{
              title: "channel",
              subTitle: "cdpMarketingProgram",
            }}
            keys={[
              {
                id: "cdpMarketingProgram",
                name: t("common.labels.marketing_program"),
              },
              {
                id: "serviceName",
                name: t("common.labels.service_name"),
              },
              {
                id: "channel",
                name: t("common.labels.channel"),
              },
              {
                id: "contactPointCategoryCode",
                name: t("opts_mapping.contact_point_category_code"),
              },
              {
                id: "primaryIndicator",
                name: t("opts_mapping.primary_indicator"),
              },
              {
                id: "subscriptionOptNumber",
                name: t("opts_mapping.subscription_opt_number"),
              },
            ]}
          />
        )}

        {openConsentTexts && (
          <CustomModal
            open={openConsentTexts}
            onClose={() => {
              setOpenConsentTexts(false);
            }}
            title={`${reviewRequestDetailsOutput.requestId} - Consent Texts`}
            subtitle=""
            showCloseIcon
            loading={loading}
          >
            <ConsentTexts
              isDisabled
              templateVariablesValues={{}}
              updatedConsentTexts={consentTexts}
            />
          </CustomModal>
        )}

        {openConsentTemplateVersions && (
          <CustomModal
            open={openConsentTemplateVersions}
            onClose={() => {
              setOpenConsentTemplateVersions(false);
            }}
            title={`${reviewRequestDetailsOutput.requestId} - Consent Template Versions`}
            showCloseIcon
            loading={loading}
          >
            <Versions
              consentTemplateVersions={consentTemplateVersions}
              viewOnly
            />
          </CustomModal>
        )}

        <NewTrait
          openModal={newTraitModalOpen}
          handleClose={() => setNewTraitModalOpen(false)}
          handleSubmit={() => {}}
          setTraitsInfo={() => {}}
          traitsInfo={traitModalData || {}}
          ecosystems={traitModalData ? traitModalData.ecoSystemTraits : []}
          isLoading={false}
          traitsSelected={[
            {
              title: "",
              isSelected: false,
              isNewTrait: false,
            },
          ]}
          isDisabled
          title={`${t("common.new_trait")} - ${
            traitModalData ? traitModalData.traitName : ""
          }`}
        />

        {Object.keys(confirmationDetails).length > 0 && (
          <ConfirmationModal
            status={reviewRequestDetailsOutput.statusText}
            commentRef={commentRef}
            isBatchRequest={isBatchRequest}
            open={confirmationModalOpen}
            loading={loading}
            onClose={() => {
              setConfirmationModalOpen(false);
            }}
            {...confirmationDetails}
          />
        )}

        {batchRequests.length > 0 && (
          <TableFooter
            items={batchRequests.map((_request) => _request.requestId)}
            itemsLabel={t("requests.request")}
            onCancelClick={() => {
              setBatchRequests(() => {
                return [];
              });
            }}
            proceedBtnText={t("requests.proceed_to_review")}
            onProceedClick={() => {
              setReviewRequestDetailsOpen(true);
              setReviewRequestDetailsOutput({ batchRequests });
            }}
          />
        )}

        {selectedMappings !== "" && !openOriginalMappings && (
          <TraitMpMappingModal
            isOpen
            onClose={() => setSelectedMappings("")}
            traits={requestOutput.requestDetails.traits}
            marketingPrograms={requestOutput.requestDetails.marketingPrograms}
            disabled
            ecosystems={requestOutput.requestDetails.ecosystems}
            view={selectedMappings}
            mappingsStore={buildMappings(
              requestOutput.requestDetails.survivorshipRules,
              requestOutput.requestDetails.traitResponseTypes,
              requestOutput.requestDetails.ecosystems
            )}
          />
        )}

        {selectedMappings !== "" && openOriginalMappings && (
          <TraitMpMappingModal
            isOpen
            onClose={() => {
              setSelectedMappings("");
              setOpenOriginalMappings(false);
            }}
            traits={requestOutput.originalRequestDetails.traits}
            marketingPrograms={
              requestOutput.originalRequestDetails.marketingPrograms
            }
            disabled
            ecosystems={requestOutput.originalRequestDetails.ecosystems}
            view={selectedMappings}
            mappingsStore={buildMappings(
              requestOutput.originalRequestDetails.survivorshipRules,
              requestOutput.originalRequestDetails.traitResponseTypes,
              requestOutput.originalRequestDetails.ecosystems
            )}
          />
        )}
      </Layout>
    </div>
  );
};

RequestsContainer.defaultProps = {
  config: PropTypes.shape({
    access: [],
  }),
};

RequestsContainer.propTypes = {
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    tableColumns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    module: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    access: PropTypes.arrayOf(PropTypes.string),
  }),
  type: PropTypes.string.isRequired,
};

export default RequestsContainer;
