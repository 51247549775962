import applicationConfig from "../config/applicationConfig";

const { paginationConfig } = applicationConfig;

const parseSearchFilter = (
  filters,
  filterFieldMap,
  page = paginationConfig.defaultPage,
  perPage = paginationConfig.defaultPerPage,
  sortBy,
  sortField
) => {
  let parsedUrl = "";
  parsedUrl += `?page=${page}&itemsPerPage=${perPage}`;

  if (sortField && sortBy) {
    parsedUrl += `&${sortField}=${sortBy}`;
  } else if (!sortField && sortBy) {
    parsedUrl += `&sortBy=${sortBy}`;
  }

  if (!filters.length) {
    return parsedUrl;
  }

  filters.forEach((filter) => {
    if (filter && filterFieldMap[filter.name] && filter.value) {
      parsedUrl += `&${filterFieldMap[filter.name]}=${filter.value}`;
    }
  });

  return parsedUrl;
};

export default parseSearchFilter;
