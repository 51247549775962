import { useState, useRef } from "react";

import {
  Paper,
  Button,
  Typography,
  ButtonGroup,
  MenuItem,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClearIcon from "@material-ui/icons/Clear";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";

import getSources from "../../../api/get-sources";

import AccessDenied from "../../../components/AccessDenied";
import Layout from "../../../components/Layout";
import SearchFilter from "../../../components/SearchFilter";
import StyledTooltip from "../../../components/StyledTooltip";
import Table from "../../../components/Table";
import Tabs from "../../../components/Tabs";

import applicationConfig from "../../../config/applicationConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import sourcesModuleConfig from "../../../config/sourcesModuleConfig";

import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import SourceIdModal from "../components/SourceIdModal";

import useStyles from "./styles";

const SourcesContainer = () => {
  const history = useHistory();
  const classes = useStyles();
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const childRef = useRef();

  const {
    sourcesTableColumns,
    sourceSearchFilters,
    sourcesSearchDefaultFilterState,
  } = sourcesModuleConfig;
  const [openDropdown, setOpenDropdown] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loadingCellData, setLoadingCellData] = useState(false);
  const [filters, setFilters] = useState({});

  const anchorRef = useRef(null);
  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const [cellData, setCellData] = useState({
    data: [],
    field: "sourceId",
    row: {},
  });

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.searchSources
  );

  const removeSourceIds = (string) => {
    if (string.indexOf("("))
      return `${string.slice(0, string.indexOf("("))}...`;
    return string;
  };

  const resetDrawer = () => {
    setDrawerOpen((x) => !x);
    setCellData({
      data: {},
      field: "sourceId",
      row: {},
    });
  };

  const renderClickableCell = (params) => {
    let content = params.value;
    if (!content) {
      return (
        <div data-testid="clickable-cell" className={classes.clickableCell} />
      );
    }
    if (typeof content === "string" && !Array.isArray(content)) {
      content = content.split(",");
    }

    if (params.field === applicationConfig.common.ecosystem_source_names) {
      if (content.length > 0 && !content[0].includes("...")) {
        content[0] = removeSourceIds(content[0]);
      }
    }

    if (content.length >= 2) {
      const noOfItems = content.length - 1;
      content = content.slice(0, 1);

      if (noOfItems > 0) {
        if (
          params.field !== applicationConfig.common.ecosystem_source_names &&
          !content[0].includes("...")
        ) {
          content[0] += "...";
        }
        content[0] += `+${noOfItems}`;
      }
    }

    return (
      <div data-testid="clickable-cell" className={classes.clickableCell}>
        {Array.isArray(content) ? content.join("\n\n") : content}
      </div>
    );
  };

  const renderDescriptionCell = (params) => {
    const { description } = params.row;
    return (
      <StyledTooltip placement="top" title={<div>{description}</div>}>
        <div>{params.value}</div>
      </StyledTooltip>
    );
  };

  const resetFilter = () => {
    childRef.current.clearFilter();
    setFilters({});
  };

  const filterFieldMap = {
    sourceId: "sourceId",
    sourceName: "sourceName",
    url: "url",
    marketingProgram: "marketingProgram",
    "": "searchText",
  };

  const getAllowedColumns = () => {
    const columns = [...sourcesTableColumns];
    return columns.map((data) => {
      const columnConfig = {
        ...data,
        sortable: false,
        editable: false,
        disableClickEventBubbling: true,
      };
      if (
        data.field === applicationConfig.common.ecosystem_source_names ||
        data.field === applicationConfig.common.marketing_programs ||
        data.field === applicationConfig.common.sourceId
      ) {
        columnConfig.renderCell = renderClickableCell;
      }

      if (data.field === applicationConfig.common.description) {
        columnConfig.renderCell = renderDescriptionCell;
      }

      return columnConfig;
    });
  };

  const handleClickAway = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenDropdown(false);
  };

  return !isUserAuthorized && !user.loading ? (
    <AccessDenied />
  ) : (
    <Layout
      header={
        <div className={classes.flexContainer}>
          <Typography variant="h4" gutterBottom>
            {t("sources_container.sources_management")}
          </Typography>
        </div>
      }
    >
      <div className={classes.flexContainer}>
        <Tabs
          tabList={[
            {
              label: "Sources",
              onClick: () => history.push("/sources"),
            },
          ]}
        />
        <div className={clsx(classes.marginLeftAuto, classes.flexContainer)}>
          {checkUserAuthorization(
            user.access,
            pageAccessConfig.createSources
          ) && (
            <div className={classes.flexContainer}>
              {checkUserAuthorization(
                user.access,
                pageAccessConfig.createTraits
              ) && (
                <div className={classes.btnContainer}>
                  <ButtonGroup
                    variant="contained"
                    color="primary"
                    ref={anchorRef}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      component={Link}
                      onClick={() => {
                        history.push(applicationConfig.pathnames.sources_new);
                      }}
                    >
                      {t("sources_container.create_source")}
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleToggle}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  </ButtonGroup>
                  <Popper
                    open={openDropdown}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClickAway}>
                            <MenuList id="split-button-menu">
                              <MenuItem
                                onClick={() => {
                                  history.push(
                                    applicationConfig.pathnames.sources_new
                                  );
                                }}
                              >
                                {t("sources_container.create_source_small")}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  history.push(
                                    applicationConfig.pathnames.sources_manage
                                  );
                                }}
                              >
                                {t("disconnect_sources.disconnect_sources")}
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              )}
            </div>
          )}
          <SearchFilter
            ref={childRef}
            onSearch={(data) => {
              setFilters(data);
            }}
            searchFilters={sourceSearchFilters}
            defaultFilterState={sourcesSearchDefaultFilterState}
          />
          <StyledTooltip
            placement="top"
            title={t("common.labels.clear_search")}
          >
            <div
              role="button"
              aria-hidden="true"
              data-testid="reset-btn"
              onClick={resetFilter}
            >
              <ClearIcon data-testid="filter-icon" />
            </div>
          </StyledTooltip>
        </div>
      </div>
      <Paper data-testid="sources-container" elevation={0}>
        <div className={classes.sourcesTableContainer}>
          <Table
            initApiCall={(page, perPage) =>
              getSources(
                parseSearchFilter(
                  [filters],
                  filterFieldMap,
                  page,
                  perPage,
                  t("common.source_id_sort_order")
                )
              )
            }
            moduleName="sources"
            filters={filters}
            columns={getAllowedColumns()}
            openModal={() => setDrawerOpen(true)}
            setLoadingCellData={setLoadingCellData}
            renderCellData={(data, field, row) => {
              if (field === "ecosystemSourceNames") {
                setCellData({
                  data: data.map((item) => ({
                    ...item,
                    ecosystemSourceTruncatedName: removeSourceIds(
                      item.ecosystemSourceName
                    ),
                  })),
                  field,
                  row,
                });
              } else {
                setCellData({
                  data,
                  field,
                  row,
                });
              }
            }}
          />
        </div>
      </Paper>
      {cellData.field === "sourceId" && drawerOpen && (
        <SourceIdModal
          title={`${t("common.data_source_id")} - ${cellData.row.sourceId}`}
          subtitle={t("sources_container.source_id_title")}
          data={cellData.data}
          loading={loadingCellData}
          open={drawerOpen}
          onClose={() => resetDrawer()}
          type="sources"
        />
      )}
    </Layout>
  );
};

export default SourcesContainer;
