import { useState, useEffect, useCallback, useRef, useMemo } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { Skeleton } from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import clsx from "clsx";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createInsightRequest from "../../../api/create-insight-request";
import getInsight from "../../../api/get-insight";
import getInsights from "../../../api/get-insights";

import getRequestDetails from "../../../api/get-request-details";

import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import { AccordionModalDetails } from "../../../components/AccordionModal";
import ConfirmationModal from "../../../components/ConfirmationModal";

import CustomAutoComplete from "../../../components/CustomAutoComplete";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import TextBox from "../../../components/TextBox";

import applicationConfig from "../../../config/applicationConfig";
import insightModuleConfig from "../../../config/insightModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";
import traitsModuleConfig from "../../../config/traitsModuleConfig";
import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import { isEmptyString } from "../../../utilities/formValidation";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";
import NewInsight from "../Components/NewInsight";
import NewMpMapping from "../Components/NewMpMapping";

import {
  findSelectedInsightSource,
  findSelectedInsightDestination,
  getComputeFrequency,
  getSchedule,
  preparePayloadInsights,
  customIndicatorModify,
} from "../InsightsContainer/helpers/insightsUtils";

import InsightRequestOutputModal from "./InsightRequestOutputModal";
import useStyles from "./styles";

const CreateInsightContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();

  const { user } = useUserProfile();
  const { requestStatus } = applicationConfig;

  const { createInsightConstants } = insightModuleConfig;
  const { addNotification } = useNotifier();
  const { request, setRequest } = useRequest();
  const history = useHistory();
  const location = useLocation();
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);
  const [openNewMpMapping, setOpenNewMpMapping] = useState(false);
  const initialMpMapping = useRef([]);
  const [mpMappings, setMpMappings] = useState([]);
  const [rowIndex, setRowIndex] = useState(null);

  const initialValues = {
    marketingProgram: "",
    marketingProgramNumber: 1,
    description: "",
    schedule: "",
    status: "",
    computeFrequencyType: "",
    processingType: "",
    isNew: true,
    isUpdated: false,
    selectedSourceValue: "SQLExpressionForBigQuery",
    selectedDestinationValue: "CRMActivation",
  };

  const [mpMappingInfo, setMpMappingInfo] = useImmer({ ...initialValues });

  const {
    location: { state },
  } = history;

  let requestId = null;
  if (location.state) {
    requestId = location.state?.requestId;
  }
  const [currentStep, setCurrentStep] = useState(1);
  const [insightsSelected, setInsightsSelected] = useState({
    title: "",
    isSelected: false,
    isNewEvent: false,
    insightId: "",
  });
  const { insightMappingsKeys } = traitsModuleConfig;
  const [storeDataLoading, setStoreDataLoading] = useState(false);

  useEffect(() => {
    const fetchInsightMappings = async () => {
      if (
        currentStep === 2 &&
        insightsSelected?.isSelected &&
        !insightsSelected?.isNewEvent
      ) {
        setStoreDataLoading(true);
        try {
          const response = await getInsight(insightsSelected.insightId); // Assuming insightId is available
          initialMpMapping.current = response.insightMappings;
          let updateInsightMappings = [];
          if (response?.insightMappings) {
            updateInsightMappings = response.insightMappings.map(
              (mpMapping) => {
                const updatedMpItem = mpMapping;
                if (mpMapping?.selectedSource) {
                  updatedMpItem.selectedSourceValue = mpMapping.selectedSource;
                }
                if (mpMapping?.selectedDestination) {
                  updatedMpItem.selectedDestinationValue =
                    mpMapping.selectedDestination;
                }
                if (updatedMpItem.selectedSourceValue) {
                  updatedMpItem.sourceDetailsText = findSelectedInsightSource(
                    updatedMpItem.selectedSourceValue
                  )?.label;
                }
                if (updatedMpItem.selectedDestinationValue) {
                  updatedMpItem.destinationDetailsText =
                    findSelectedInsightDestination(
                      updatedMpItem.selectedDestinationValue
                    )?.label;
                }
                if (updatedMpItem?.computeFrequency) {
                  updatedMpItem.computeFrequency = getComputeFrequency(
                    updatedMpItem?.computeFrequency,
                    updatedMpItem?.schedule
                  );
                }
                return updatedMpItem;
              }
            );
          }
          setMpMappings(updateInsightMappings); // Set mpMappings from the API response
        } catch (error) {
          handleError({ error, addNotification }); // Handle error appropriately
        } finally {
          setStoreDataLoading(false);
        }
      }
    };
    if (mpMappings?.length === 0) {
      fetchInsightMappings();
    }
  }, [currentStep]);

  useEffect(() => {
    if (
      state?.insight !== undefined &&
      Object.entries(state.insight.insightDetail).length > 0
    ) {
      const makeSelectedObject = {
        insightId:
          state.insight.insightDetail.insightsId ||
          state.insight.insightDetail.insightId,
        title:
          state.insight.insightDetail.insightsName ||
          state.insight.insightDetail.insightName,
        isSelected: true,
        insightName:
          state.insight.insightDetail.insightsName ||
          state.insight.insightDetail.insightName,
        description: state.insight.insightDetail.description,
        customIndicator: customIndicatorModify(
          state.insight.insightDetail?.customIndicator
        ),
        sqlExpression: state.insight.insightDetail?.sqlExpression,
        inputTraits: state.insight?.insightDetail?.inputTraits,
        outputTrait: state.insight?.insightDetail?.outputTrait,
      };
      setCurrentStep(currentStep + 1);
      setInsightsSelected(makeSelectedObject);
    }
  }, [state]);

  const isRequestRevisionFlow =
    Object.keys(request).length !== 0 || Boolean(requestId);
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openInsightMappingDeleteModal, setOpenInsightMappingDeleteModal] =
    useState(false);

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.useEvents
  );

  const totalSteps = 3;

  const { t } = useTranslation();

  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [formErrors, setFormErrors] = useState({});

  const [insightSearchValue, setInsightSearchValue] = useState("");
  const [insightOptions, setInsightOptions] = useState([]);
  const [initialInsightOptions, setInitialInsightOptions] = useState([]);
  const [insightsInfo, setInsightsInfo] = useImmer({
    insightsName: "",
    description: "",
    inputTraits: [],
    outputTrait: "",
    customIndicator: "No",
    sqlExpression: "",
    isNew: true,
    isUpdated: false,
  });
  const [isNewInsightDisabled, setIsNewInsightDisabled] = useState(false);

  const [insightRequestResponse, setInsightRequestResponse] = useState([]);

  const [insightsLoading, setInsightsLoading] = useState(false);

  const trackChanges = (existing, changes) => {
    const transformData = (arr) =>
      _.sortBy(
        arr.map((x) => ({
          marketingProgram: x?.marketingProgram?.replace(/\s+/g, ""),
          status: x?.status,
          computeFrequency: x?.computeFrequency,
          processingType: x?.processingType,
          selectedSourceValue: x?.selectedSourceValue,
          selectedDestinationValue: x?.selectedDestinationValue,
        })),
        [
          "marketingProgram",
          "status",
          "computeFrequency",
          "processingType",
          "selectedSourceValue",
          "selectedDestinationValue",
        ]
      );

    const existingData = transformData(_.cloneDeep(existing));
    const changedData = transformData(_.cloneDeep(changes));

    const existingNormalized = _.cloneDeep(existing).map((item) => ({
      ...item,
      marketingProgram: item?.marketingProgram?.replace(/\s+/g, ""),
    }));

    const changesNormalized = _.cloneDeep(changes).map((item) => ({
      ...item,
      marketingProgram: item?.marketingProgram?.replace(/\s+/g, ""),
    }));

    const diffData = _.differenceWith(existingData, changedData, _.isEqual);

    // Updated items logic: Create two versions
    const updatedItems = _.filter(changesNormalized, (item) =>
      _.some(diffData, { marketingProgram: item.marketingProgram })
    );

    const updatedExistingItems = updatedItems.map((item) => {
      const original = existingNormalized.find(
        (e) => e.marketingProgram === item.marketingProgram
      );
      return { ...original, isNew: false, isUpdated: true, deleted: true };
    });

    const updatedNewItems = updatedItems.map((item) => ({
      ...item,
      isNew: false,
      isUpdated: true,
      deleted: false,
    }));

    // Deleted items logic
    const deletedItems = existingNormalized
      .filter(
        (item) =>
          !_.some(changesNormalized, {
            marketingProgram: item.marketingProgram,
          })
      )
      .map((item) => ({
        ...item,
        isNew: false,
        isUpdated: false,
        deleted: true,
      }));

    const addedItems = changesNormalized
      .filter(
        (item) =>
          !_.some(existingNormalized, {
            marketingProgram: item.marketingProgram,
          })
      )
      .map((item) => ({
        ...item,
        new: true,
        isNew: true,
        isUpdated: false,
        deleted: false,
      }));

    // Order: Existing → Updated → Deleted → New
    let result = [];

    // Maintain the order for updated items
    updatedExistingItems.forEach((existingItem) => {
      result.push(existingItem);
      const correspondingUpdatedItem = updatedNewItems.find(
        (item) => item.marketingProgram === existingItem.marketingProgram
      );
      if (correspondingUpdatedItem) {
        result.push(correspondingUpdatedItem);
      }
    });

    // Append Deleted Items
    result = [...result, ...deletedItems];

    // Append New Items
    result = [...result, ...addedItems];

    return result;
  };

  const diffList = useMemo(() => {
    return trackChanges(initialMpMapping.current, mpMappings);
  }, [mpMappings, trackChanges]);

  useEffect(() => {
    const existingNormalized = _.cloneDeep(initialMpMapping.current).map(
      (item) => ({
        ...item,
        marketingProgram: item?.marketingProgram?.replace(/\s+/g, ""),
      })
    );
    const changesNormalized = _.cloneDeep(mpMappings).map((item) => ({
      ...item,
      marketingProgram: item?.marketingProgram?.replace(/\s+/g, ""),
    }));
    const addedItems = changesNormalized.filter(
      (item) =>
        !_.some(existingNormalized, {
          marketingProgram: item.marketingProgram,
        })
    );
    setMpMappings((prevMappings) => {
      const updatedMappings = prevMappings?.map((item) => {
        const sanitizedMarketingProgram = item?.marketingProgram?.replace(
          /\s+/g,
          ""
        );

        const existsInPrevMappings = existingNormalized?.some(
          (existingItem) =>
            existingItem?.marketingProgram === sanitizedMarketingProgram
        );

        const isUpdated =
          diffList.some(
            (diff) => diff?.marketingProgram === sanitizedMarketingProgram
          ) && existsInPrevMappings;

        const isNew = addedItems?.some(
          (newItem) => newItem?.marketingProgram === sanitizedMarketingProgram
        );

        return {
          ...item,
          marketingProgram: sanitizedMarketingProgram,
          isUpdated,
          new: !isUpdated && isNew, // Set new to true only if it's not updated and doesn't exist
        };
      });
      // Prevent unnecessary state update
      if (!_.isEqual(prevMappings, updatedMappings)) {
        return updatedMappings;
      }

      return prevMappings;
    });
  }, [diffList]);

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return;
    }
    setOpenModal(false);
    setInsightsInfo({
      insightsName: "",
      description: "",
      inputTraits: [],
      outputTrait: "",
      customIndicator: "No",
      sqlExpression: "",
    });
    setInsightSearchValue("");
    setIsNewInsightDisabled(false);
  };

  const onCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const validateForm = (step) => {
    const errors = {};
    if (step === 1) {
      // 1
      if (!insightsSelected?.title) {
        errors.insights = {
          error: t("insight_container.errors.select_insight"),
        };
      }
    }
    if (step === 2) {
      const grouped = _.groupBy(
        mpMappings,
        (item) => item.marketingProgram.split("-")?.[0]
      );
      const duplicateKey =
        grouped && _.findKey(grouped, (items) => items?.length > 1);
      const duplicateInsight = duplicateKey && grouped?.[duplicateKey]?.[0];
      if (duplicateInsight?.marketingProgram) {
        errors.insightAlreadyExists = t(
          "insight_container.marketing_program_already_mapped"
        )?.replace("{marketingProgram}", duplicateInsight?.marketingProgram);
      } else if (isEmpty(diffList)) {
        addNotification(t("No changes Done."));
        errors.noChangesError = t("insight_container.no_insights_changes");
      }
    }
    setFormErrors(errors);
    return errors;
  };

  const isInfoStep = currentStep > totalSteps;

  const newInsight = (
    <div className="newInsight">
      <Typography variant="h6">
        {t("insight_container.no_suggestion_match")}
      </Typography>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setOpenModal(true);
        }}
      >
        {t("insight_container.new_insight")}
      </Button>
    </div>
  );

  const getInsightsFromApi = useCallback(async (searchText) => {
    let filter = {};
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const filterFieldMap = {
        searchText: "searchText",
      };

      const rsp1 = await getInsights(
        parseSearchFilter(
          Object.keys(filter).map((fil) => ({
            name: fil,
            value: filter[fil],
          })),
          filterFieldMap, // new URLSearchParams(filter).toString(),
          1,
          6
        )
      );
      setInsightOptions([...rsp1.items, newInsight]);
      if (searchText.length === 0) {
        if (initialInsightOptions.length > 0) {
          const newInitialInsightOptions = [...initialInsightOptions];
          newInitialInsightOptions.splice(
            newInitialInsightOptions.length - 1,
            0,
            ...rsp1.items
          );
          setInitialInsightOptions(newInitialInsightOptions);
        } else {
          setInitialInsightOptions([...rsp1.items, newInsight]);
        }
      }
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setInsightOptions([newInsight]);
        },
        addNotification,
      });
    } finally {
      setInsightsLoading(false);
    }
  }, []);

  const handleSubmit = (insightInfo) => {
    let currentInsights = { ...insightsSelected };
    currentInsights = {
      ...insightInfo,
      title: insightInfo?.insightsName,
      insightId: insightInfo?.insightsId || "",
      isSelected: true,
      isNewEvent: !insightInfo?.insightsId,
    };

    setInsightsSelected(currentInsights);
    handleClose();
  };

  // Debounce & Memoize Api Calls
  const debouncedInsightsFromApi = debounce(
    getInsightsFromApi,
    applicationConfig.waitTime
  );

  const memoizedInsightsFromApi = useCallback(async (val) => {
    debouncedInsightsFromApi(val);
  }, []);

  useEffect(() => {
    if (insightSearchValue?.length > 0) {
      memoizedInsightsFromApi(insightSearchValue);
    }
  }, [insightSearchValue]);

  const setInsightsData = (data) => {
    const { insight, marketingPrograms } = data.requestDetails;
    setInsightsSelected({
      ...insight,
      title: insight.insightsName,
      inputTraits: insight?.inputTraits,
      outputTrait: insight?.outputTrait,
      customIndicator: customIndicatorModify(insight?.isSQLTrait),
      isSelected: true,
    });

    const uniqMpList = _.filter(
      marketingPrograms,
      (item) => !(item.deleted === true && item.isUpdated === true)
    );
    uniqMpList.map((mpMapping) => {
      const updatedMpItem = mpMapping;
      updatedMpItem.marketingProgram = `${mpMapping.marketingProgramNumber} - ${mpMapping.description}`;
      updatedMpItem.selectedSourceValue = mpMapping.source;
      updatedMpItem.selectedDestinationValue = mpMapping.destination;
      updatedMpItem.sourceDetailsText = findSelectedInsightSource(
        updatedMpItem.selectedSourceValue
      )?.label;
      updatedMpItem.destinationDetailsText = findSelectedInsightDestination(
        updatedMpItem.selectedDestinationValue
      )?.label;
      updatedMpItem.computeFrequency = getComputeFrequency(
        updatedMpItem?.computeFrequency,
        updatedMpItem?.schedule
      );
      updatedMpItem.new = false;
      updatedMpItem.isNew = false;
      updatedMpItem.deleted = false;
      updatedMpItem.isUpdated = false;
      return updatedMpItem;
    });
    initialMpMapping.current = uniqMpList;
    setMpMappings(uniqMpList);
    setCurrentStep(2);
  };

  useEffect(async () => {
    if (isRequestRevisionFlow && !requestId) {
      setInsightsData(request);
    } else if (requestId) {
      const details = await getRequestDetails(requestId);
      setInsightsData(details);
    }
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getInsightsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  useEffect(() => {
    if (!openInsightMappingDeleteModal) {
      validateForm();
    }
  }, [openInsightMappingDeleteModal]);

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.insight_name"),
        value: insightsSelected?.isNewEvent ? (
          <>
            <div className={clsx(classes.flexContainer, classes.marginTop20)}>
              <div
                className={classes.newBtnContainer}
                onClick={() => {
                  setIsNewInsightDisabled(true);
                  setInsightsInfo(insightsSelected);
                  setOpenModal(true);
                }}
                role="button"
                tabIndex={0}
                onKeyDown={() => {}}
              >
                <span className="eventName">{insightsSelected.title}</span>
                <Button variant="contained">{t("common.new")}</Button>
              </div>
            </div>
          </>
        ) : (
          <div>{insightsSelected.title}</div>
        ),
      },
      {
        label: t("common.labels.insight_description"),
        value: <div>{insightsSelected.description}</div>,
      },
    ];
    return info;
  };

  const handleInsightOutputModal = async () => {
    try {
      setShowOutput(true);
    } catch (error) {
      handleError({
        error,
        handle404: false,
        addNotification,
      });
    }
  };

  const createRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "insightName",
      headerName: t("common.labels.insight_name"),
      flex: 1,
      renderCell: (params) => {
        const { value, row } = params;
        return row.isNewEvent ? (
          <div role="button" aria-hidden="true" className={classes.eventName}>
            <span>{value}</span>
            <Button
              disableElevation
              variant="contained"
              className={classes.badge}
            >
              <span className={classes.badgeText}>{t("common.new")}</span>
            </Button>
          </div>
        ) : (
          <span>{value}</span>
        );
      },
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  handleInsightOutputModal(params.row.requestId);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const onCloseInsightMappingDeleteModal = () => {
    setOpenInsightMappingDeleteModal(false);
  };

  const insightsDropdownOptions = () => {
    let options = initialInsightOptions;
    if (insightSearchValue?.length > 0) {
      options = insightOptions;
    }
    const updatedOptions = options?.map((option) => {
      if (option?.type !== "div") {
        return {
          title: `${option?.insightsId} - ${option?.insightsName}`,
          ...option,
        };
      }
      return option;
    });
    if (updatedOptions.length > 4) {
      return [...updatedOptions.slice(0, 3), ...updatedOptions.slice(-1)];
    }
    return updatedOptions;
  };

  const renderStep = (step) => {
    if (step === 1) {
      // 1
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <div className={classes.center} data-testid="insights-container">
          {!insightsSelected?.isSelected ? (
            <div
              className={clsx(classes.flexContainer, classes.autoCompleteBox)}
            >
              <CustomAutoComplete
                isMultiple={false}
                classes={{
                  listbox: classes.listbox,
                }}
                options={insightsDropdownOptions()}
                id="insight-field"
                placeholder={
                  loading
                    ? t("common.loading")
                    : t(
                        "insight_container.insight_select_or_insight_placeholder"
                      )
                }
                loading={insightsLoading}
                value={
                  insightsSelected.title && [
                    {
                      title: insightsSelected.title,
                    },
                  ]
                }
                closeIcon={<></>}
                isExternalInputValue
                inputValue={insightSearchValue}
                setValue={(val) => {
                  if (val && val.type !== "div") {
                    if (val) {
                      setInsightsSelected({
                        ...val,
                        title: val.title,
                        insightId: val.insightsId,
                        isSelected: true,
                      });
                    }
                  }
                }}
                onInputChange={(val) => {
                  setInsightSearchValue(val);
                  setInsightOptions([]);
                  setInsightsLoading(true);
                }}
                error={Boolean(formErrors?.insights?.error)}
                errorText={formErrors?.insights?.error}
              />
            </div>
          ) : (
            <div className={classes.fullWidth}>
              <TextBox
                isNew={insightsSelected}
                name={
                  insightsSelected?.title ||
                  insightsSelected?.insightsName ||
                  insightsSelected?.insightName
                }
                onClick={() => {
                  // EDIT NEW INSIGHT
                  if (insightsSelected) {
                    setInsightsInfo({
                      ...insightsSelected,
                    });
                    setOpenModal(true);
                  } else {
                    // DELETE INSIGHT
                    setOpenDeleteModal(true);
                  }
                }}
                onDelete={() => {
                  // DELETE INSIGHT
                  setOpenDeleteModal(true);
                }}
              />
            </div>
          )}
        </div>
      );
    }
    if (step === 2) {
      if (storeDataLoading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton width={100} height={24} />
          </>
        );
      }
      return (
        <>
          <div className={classes.marketingProgramContainer}>
            <AccordionModalDetails
              keys={insightMappingsKeys}
              noScroll
              rows={mpMappings}
              showEdit={() => true}
              showDelete={() =>
                checkUserAuthorization(
                  user.access,
                  pageAccessConfig.deleteInsights
                )
              }
              onEdit={(row, index) => {
                setMpMappingInfo({
                  ...row,
                  marketingProgram: {
                    marketingProgramNumber: row.marketingProgram
                      .split("-")[0]
                      .trim(),
                    description: row.marketingProgram.split("-")[1].trim(),
                  },
                  selectedSourceValue: row.selectedSourceValue,
                  selectedDestinationValue: row.selectedDestinationValue,
                  sourceDetailsText: findSelectedInsightSource(
                    row.selectedSourceValue
                  )?.label,
                  destinationDetailsText: findSelectedInsightDestination(
                    row.selectedDestinationValue
                  )?.label,
                  computeFrequencyType: row.computeFrequency
                    ?.split("-")?.[0]
                    .trim(),
                  schedule: getSchedule(row?.schedule),
                });
                setRowIndex(index);
                setOpenNewMpMapping(true);
              }}
              onDelete={(row, index) => {
                setRowIndex(index);
                setOpenInsightMappingDeleteModal(true);
              }}
              rowKeys={{
                title: "marketingProgram",
                subTitle: "computeFrequency",
              }}
            />
            {formErrors.insightAlreadyExists && (
              <Alert severity="error" className={classes.mappingAlertMessage}>
                {formErrors.insightAlreadyExists}
              </Alert>
            )}
            <div className={globalStyles.addTraitBtn}>
              <AddCircleOutlineIcon />
              <Button onClick={() => setOpenNewMpMapping(true)}>
                {t("insight_container.new_mp_mapping")}
              </Button>
            </div>
            {openNewMpMapping && (
              <NewMpMapping
                openModal={openNewMpMapping}
                handleClose={() => {
                  setOpenNewMpMapping(false);
                  setRowIndex(null);
                  setMpMappingInfo(initialValues);
                }}
                title={
                  rowIndex === null
                    ? t("insight_container.new_mp_mapping")
                    : t("insight_container.edit_mp_mapping")
                }
                handleSubmit={() => {
                  if (!isEmptyString(rowIndex)) {
                    setMpMappings((prevMappings) => {
                      const updatedMappings = [...prevMappings];
                      updatedMappings[rowIndex] = {
                        ...mpMappingInfo,
                        computeFrequency: getComputeFrequency(
                          mpMappingInfo.computeFrequencyType,
                          mpMappingInfo?.schedule
                        ),
                        marketingProgram: `${mpMappingInfo.marketingProgram.marketingProgramNumber} - ${mpMappingInfo.marketingProgram.description}`,
                        selectedSourceValue: mpMappingInfo?.selectedSourceValue,
                        selectedDestinationValue:
                          mpMappingInfo?.selectedDestinationValue,
                        sourceDetailsText: findSelectedInsightSource(
                          mpMappingInfo?.selectedSourceValue
                        )?.label,
                        destinationDetailsText: findSelectedInsightDestination(
                          mpMappingInfo?.selectedDestinationValue
                        )?.label,
                      };
                      return updatedMappings;
                    });
                  } else {
                    setMpMappings([
                      ...mpMappings,
                      {
                        ...mpMappingInfo,
                        computeFrequency: getComputeFrequency(
                          mpMappingInfo.computeFrequencyType,
                          mpMappingInfo?.schedule
                        ),
                        marketingProgram: `${mpMappingInfo.marketingProgram.marketingProgramNumber} - ${mpMappingInfo.marketingProgram.description}`,
                        selectedSourceValue: mpMappingInfo?.selectedSourceValue,
                        selectedDestinationValue:
                          mpMappingInfo?.selectedDestinationValue,
                        sourceDetailsText: findSelectedInsightSource(
                          mpMappingInfo?.selectedSourceValue
                        )?.label,
                        destinationDetailsText: findSelectedInsightDestination(
                          mpMappingInfo?.selectedDestinationValue
                        )?.label,
                      },
                    ]);
                  }
                  setMpMappingInfo(initialValues);
                  setOpenNewMpMapping(false);
                  setRowIndex(null);
                }}
                mpMappingInfo={mpMappingInfo}
                setMpMappingInfo={setMpMappingInfo}
                selectedInsight={insightsSelected}
              />
            )}
          </div>
        </>
      );
    }
    if (step === 3) {
      // 3
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            style={{ alignItems: "flex-start" }}
            key={`${info.label}${info.index}`}
          >
            <Typography className="label" variant="h6">
              {info.label} :
            </Typography>
            <Typography variant="h6">{info.value}</Typography>
          </div>
        );
      });

      return (
        <>
          {detailsDOM}
          {diffList.length > 0 ? (
            <AccordionModalDetails
              keys={insightMappingsKeys}
              noScroll
              rows={diffList}
              rowKeys={{
                title: "marketingProgram",
                subTitle: "computeFrequency",
              }}
            />
          ) : (
            <Typography>No mappings available.</Typography> // Fallback message
          )}
        </>
      );
    }
    return (
      <div
        style={{
          height: 70 * insightRequestResponse.length + 40,
          maxHeight: "calc(100vh - 300px)",
          overflow: "hidden",
        }}
      >
        <Table
          columns={createRequestTableColumns}
          rows={insightRequestResponse}
        />
      </div>
    );
  };
  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/traits" goToText="GO TO TRAITS" />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        steps={[
          t("insight_container.headings.step_1"),
          t("insight_container.headings.step_2"),
          t("insight_container.headings.step_3"),
        ]}
        currentStep={currentStep}
        loading={loading && currentStep === 1}
        headerText={t("insight_container.insight_management")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      classes={{
                        root: globalStyles.btn,
                      }}
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    classes={{
                      root: globalStyles.btn,
                    }}
                    onClick={() => {
                      setCurrentStep(currentStep - 1);
                    }}
                  >
                    BACK
                  </Button>
                )}
              </>
            )}
            {currentStep <= totalSteps && ( // totalSteps
              <Button
                disabled={loading}
                className="nextStep"
                classes={{
                  root: globalStyles.btn,
                }}
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    // totalSteps
                    try {
                      increaseRequestsCount();
                      const payload = preparePayloadInsights(
                        insightsSelected,
                        diffList
                      );
                      if (!isRequestRevisionFlow) {
                        const data = await createInsightRequest(payload);
                        const { items } = data;
                        setInsightRequestResponse(
                          items.map((item, index) => ({
                            id: index,
                            requestId: item.requestId,
                            insightName: item.insight.insightsName,
                            status:
                              item.status.charAt(0).toUpperCase() +
                              item.status.slice(1),
                          }))
                        );
                        setCurrentStep(currentStep + 1);
                        setOutput({
                          ...items?.[0]?.output,
                          status: items[0].status,
                          message: items[0]?.message,
                          description: items[0]?.insight?.description,
                        });
                      } else {
                        const data = await updateRequest(
                          request.requestId || requestId,
                          payload,
                          applicationConfig.modules.insights
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                      }
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const errors = validateForm(currentStep);
                    setFormErrors(errors);
                    if (Object.keys(errors).length > 0) {
                      return null;
                    }
                    setCurrentStep(currentStep + 1);
                  }
                  return null;
                }}
              >
                {currentStep === totalSteps && loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                )}

                {createInsightConstants.footerText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/insights"
                >
                  {t("common.labels.back_to_insight")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${insightRequestResponse?.[0]?.requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.eventContainer,
            isInfoStep && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {openModal && (
        <NewInsight
          openModal={openModal}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          insightsInfo={insightsInfo}
          setInsightsInfo={setInsightsInfo}
          isDisabled={isNewInsightDisabled}
          insightsSelected={insightsSelected}
          isRequestRevisionFlow={isRequestRevisionFlow}
          title={
            insightsSelected?.title
              ? t("insight_container.edit_insight")
              : t("insight_container.create_new_insight")
          }
        />
      )}
      <ConfirmationModal
        open={openDeleteModal}
        onClose={onCloseDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("insight_container.insight_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setInsightsSelected({
            title: "",
            isSelected: false,
            isNewEvent: false,
            insightId: "",
          });
          onCloseDeleteModal();
        }}
        type="error"
      />
      <ConfirmationModal
        open={openInsightMappingDeleteModal}
        onClose={onCloseInsightMappingDeleteModal}
        title={t("dialogs.confirm_remove")}
        message={t("insight_container.insight_removal_warning")}
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setMpMappings((prevMappings) => {
            const updatedMappings = [...prevMappings];
            updatedMappings.splice(rowIndex, 1);
            return updatedMappings;
          });
          setRowIndex(null);
          onCloseInsightMappingDeleteModal();
        }}
        type="error"
      />
      {currentStep <= 3 && (
        <Prompt
          message={() => {
            if (isRequestRevisionFlow) {
              return true;
            }
            return t("prompt.progress_lost");
          }}
        />
      )}
      {showOutput && (
        <InsightRequestOutputModal
          isOpen={showOutput}
          isLoading={loading}
          requestId={insightRequestResponse[0].requestId}
          data={output}
          setShowOutput={setShowOutput}
        />
      )}
    </>
  );
};

export default CreateInsightContainer;
