import api from "../utilities/api";
import isEmpty from "../utilities/isEmpty";

const getOptsDetails = async (optId, includeAllVersions) => {
  const apiUrl = `/opts/${optId}?refreshCache=true&includeAllVersions=${
    isEmpty(includeAllVersions) ? true : includeAllVersions
  }`;
  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getOptsDetails;
