import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  systems: {
    maxHeight: 320,
    overflowY: "auto",
    width: "100%",
    paddingBottom: 16,
    paddingRight: 15,
    maxWidth: "60vw",
    "& > div": {
      maxWidth: "800px",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "unset",
    },
  },
  accordion: {
    borderBottom: "none",
    borderRadius: 4,
    marginBottom: 16,
    "& .MuiAccordionSummary-content": {
      alignItems: "center",
    },
  },
  systemType: {
    backgroundColor: theme.palette.blue.dark,
    color: theme.palette.static.white,
    minWidth: 88,
    padding: "10px 16px",
    borderRadius: 4,
    fontSize: "12px",
    textAlign: "center",
  },
  systemName: {
    marginLeft: 16,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "400px",
  },
  accordionContent: {
    padding: 16,
    color: theme.palette.text.primary,
    margin: "0 !important",
  },
  programName: {
    marginBottom: 10,
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: 18,
  },
  footer1: {
    justifyContent: "space-between",
    "& .MuiButton-containedPrimary:first-child": {
      backgroundColor: theme.colorTheme.white,
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  footer2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 18,
    "& .MuiButtonBase-root.Mui-disabled": {
      backgroundColor: theme.palette.static.mildGrey,
      color: theme.palette.static.almostBlack,
    },
  },
  statusLabel: {
    marginLeft: "auto",
  },
  deleted: {
    backgroundColor: theme.palette.error.main,
    padding: "5px 8px",
    borderRadius: 20,
    fontSize: "12px",
    textAlign: "center",
    color: theme.colorTheme.white,
    fontWeight: "bold",
  },
  new: {
    backgroundColor: theme.palette.blue.dark,
    padding: "5px 8px",
    borderRadius: 4,
    minWidth: 88,
    fontSize: "12px",
    textAlign: "center",
    color: theme.colorTheme.white,
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
  },
  fullWidth: {
    width: "100%",
  },
  ml12: {
    marginLeft: "12px",
  },

  noScrollSystems: {
    overflowY: "auto",
    width: "100%",
    paddingBottom: 16,
    paddingRight: 15,
    maxWidth: "60vw",
    "& > div": {
      maxWidth: "800px",
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: "unset",
    },
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
