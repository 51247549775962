import { Container, Typography } from "@material-ui/core";
import clsx from "clsx";
import PropTypes from "prop-types";

import Footer from "../Footer";

import useStyles from "./styles";

const Layout = ({
  header,
  headerClassName,
  children,
  childrenClassName,
  showFooter,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.themeWrapper}>
      <Container
        maxWidth="xl"
        className={clsx(classes.container, showFooter && classes.autoHeight)}
      >
        {Boolean(header) && (
          <div className={clsx(classes.header, headerClassName)}>
            {typeof header === "string" ? (
              <Typography variant="h4" gutterBottom>
                {header}
              </Typography>
            ) : (
              header
            )}
          </div>
        )}
        <div className={`${childrenClassName}`}>{children}</div>
      </Container>
      {showFooter && <Footer />}
    </div>
  );
};

Layout.defaultProps = {
  header: <></>,
  headerClassName: null,
  childrenClassName: "",
  children: <></>,
  showFooter: false,
};

Layout.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  headerClassName: PropTypes.string,
  childrenClassName: PropTypes.string,
  children: PropTypes.node,
  showFooter: PropTypes.bool,
};

export default Layout;
