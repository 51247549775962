import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  timerBtn: {
    position: "relative",
    padding: "2px",
    "& .rc-progress-circle": {
      width: "40px",
      height: "40px",
    },
    "& .timer-text": {
      position: "absolute",
      fontSize: "10px",
    },
  },
  dialogActionFooter: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoutTimerBtn: {
    backgroundColor: theme.palette.error.dark,
    color: "#fff",
    "& .MuiButton-endIcon span": {
      fontSize: "unset",
    },
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
      color: "#fff",
    },
  },
  loginTimerBtn: {
    backgroundColor: theme.palette.green.main2,
    color: "#fff",
    "& .MuiButton-endIcon span": {
      fontSize: "unset",
    },
    "&:hover": {
      backgroundColor: theme.palette.green.main2,
      color: "#fff",
    },
    "&:disabled": {
      color: "#fff",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerTitle: {
    marginLeft: "85px",
    "& p": {
      fontSize: "1.25rem",
      fontWeight: 400,
    },
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiToolbar-gutters": {
      padding: "0px !important",
    },
    "& a": {
      color: theme.palette.text.primary,
      textDecoration: "none",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl": {
      height: "35px !important",
    },
  },

  header: {
    boxShadow: "unset",
  },
  headerWrapper: {
    backgroundColor: theme.colorTheme.headerBackgroundColor,
    borderBottom: "1px solid rgba(255, 255, 255, 0.12)",
    color: theme.palette.text.primary,
    "& h6": {
      color: theme.palette.text.primary,
    },
  },
  padding: {
    padding: "12px 24px",
  },
  usernameContainer: {
    "& > *:not(:last-child)": {
      marginRight: "10px",
    },
    "& .MuiTypography-h6": {
      fontSize: "14px",
    },
    "& svg": {
      cursor: "pointer",
    },
  },
  verticalBar: {
    height: "32px",
    border: `1px solid ${theme.colorTheme.white}`,
    opacity: 0.2,
    marginRight: "16px",
  },
  hover: {
    cursor: "pointer",
  },
  iconContainer: {
    "& .a": {
      fill: "none !important",
    },
    "& .b": {
      fill: `${theme.colorTheme.white} !important`,
      clipPath: "none",
    },
  },
  profilePicture: {
    backgroundColor: theme.palette.blue.darker,
    color: theme.palette.static.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "44px",
    height: "44px",
    borderRadius: "50%",
  },
  menuItem: {
    "& svg": {
      marginRight: "10px",
    },
  },
  pgLogo: {
    marginRight: 12,
    transform: "scale(1.5)",
  },
  logoContainer: {
    "& p": {
      fontSize: "18px",
      fontWeight: 700,
    },
    alignItems: "flex-end",
    "& >:first-child, & >:nth-child(2)": {
      transform: "scale(1.5)",
    },
    "& >:first-child": {
      marginRight: "20px",
    },
    "& >:not(:first-child):not(:nth-child(2))": {
      fontWeight: 700,
    },
  },
  margin: {
    margin: "0px 10px",
  },
  dcf: {
    color: `${theme.palette.green.light2} !important`,
  },
}));

export default useStyles;
