import { useState, useEffect, useCallback } from "react";

import { Button, Typography, CircularProgress, Box } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { Skeleton } from "@material-ui/lab";
import Autocomplete from "@material-ui/lab/Autocomplete";
import clsx from "clsx";
import produce from "immer";
import { useTranslation } from "react-i18next";
import { Link, Prompt, useHistory, useLocation } from "react-router-dom";

import createOptTraits from "../../../api/create-opt-traits";
import getMarketingProgramsApi from "../../../api/get-marketing-programs";
import getOptMappings from "../../../api/get-opt-mappings";
import getOptTraits from "../../../api/get-opt-traits";
import getOpts from "../../../api/get-opts";
import getReferenceData from "../../../api/get-reference-data";
import getTraits from "../../../api/get-traits";
import updateRequest from "../../../api/update-request";

import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import CustomAutoComplete from "../../../components/CustomAutoComplete";
import Form from "../../../components/Form";
import InlineMessage from "../../../components/InlineMessage";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";
import applicationConfig from "../../../config/applicationConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import usePrevious from "../../../hooks/usePrevious";
import useRequest from "../../../hooks/useRequest";
import useUserProfile from "../../../hooks/useUserProfile";

import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import debounce from "../../../utilities/debounce";
import handleError from "../../../utilities/handleError";
import isEmpty from "../../../utilities/isEmpty";
import parseSearchFilter from "../../../utilities/parseSearchFilter";
import useImmer from "../../../utilities/useImmer";

import NewOptIdMapping from "./components/NewOptIdMapping";
import OptsMappingModal from "./components/OptsMappingModal";
import OptsTraitRequestOutputModal from "./components/OptsTraitRequestOutputModal";
import PartiallyEditableText from "./components/PartiallyEditableText";

import getFields from "./getFields";
import createBackendPayload from "./helpers/createBackendPayload";
import optsMappingTableColumns from "./optsMappingTableColumns";
import useStyles from "./styles";

const NewOptsStatusTraitContainer = () => {
  const classes = useStyles();
  const globalStyles = useGlobalStyles();
  const { user } = useUserProfile();
  const { addNotification } = useNotifier();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const { createOptTraitConstants, contactPointTypeOptions, requestStatus } =
    optsModuleConfig;
  const [openOptMappingModal, setOpenOptMappingModal] = useState(false);
  const [optMappingIndex, setOptMappingIndex] = useState(-1);
  const [traitId, setTraitId] = useState(-1);
  const [cdpMarketingProgramOptions, setCdpMarketingProgramOptions] = useState(
    []
  );
  const [cdpMarketingProgramsLoading, setCdpMarketingProgramsLoading] =
    useState(false);
  const [subscriptionOptNumbers, setSubscriptionOptNumbers] = useState([]);
  const [optTrait, setOptTrait] = useState({});
  const [serviceName, setServiceName] = useState({});
  const [ecosystemInputValue, setEcosystemInputValue] = useState("");

  const [
    optInfo,
    // setOptInfo
  ] = useImmer({
    ciamMarketingProgram: "",
    optTextEnglish: "",
    optTextLocal: "",
  });

  const [optMappingInfo, setOptMappingInfo] = useImmer({
    optId: "",
    channel: "",
    contactPointCategoryCode: "",
    serviceName: "",
    primaryIndicator: "",
    subscriptionOptNumber: "",
    cdpMarketingProgram: "",
  });

  const [errors, setErrors] = useState({
    step1: {
      marketingProgram: {
        error: "",
      },
      ecosystems: {
        error: "",
      },
      channel: {
        error: "",
      },
      statusTraitNameOptIndicator: {
        error: "",
      },
      statusTraitNameOptChoiceDate: {
        error: "",
      },
    },
    step2: {
      optMappingsSelected: {
        error: "",
      },
    },
  });

  const [
    optIdValue,
    // setOptIdValue
  ] = useState({
    new: false,
    title: "",
  });

  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.createOpts
  );

  const totalSteps = 3;

  const [currentStep, setCurrentStep] = useState(1);
  const previousStep = usePrevious(currentStep);
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const [optTraitResponse, setOptTraitResponse] = useState([]);

  // Marketing Program
  const [marketingProgramOptions, setMarketingProgramOptions] = useState([]);
  const [marketingProgramsLoading, setMarketingProgramsLoading] =
    useState(true);
  const [optIds, setOptsIds] = useState([]);
  const [optIdsLoading, setOptIdsLoading] = useState(false);
  const [marketingProgram, setMarketingProgram] = useState({});
  const [ecosystems, setEcosystems] = useState([]);

  const [optsTraitInfo, setOptsTraitInfo] = useImmer({
    marketingProgram: "",
    channel: "",
    statusTraitNameOptIndicator: "",
    statusTraitNameOptChoiceDate: "",
    statusTraitNameOptIndicatorPrefix: "",
    statusTraitNameOptChoiceDatePrefix: "",
    optIndicator: t("opts_status_trait.opt_indicator"),
    optChoiceDate: t("opts_status_trait.opt_choice_date"),
  });

  const { request, setRequest } = useRequest();
  // const [newServiceNameModal, setNewServiceNameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const isRequestRevisionFlow = Object.keys(request).length !== 0;
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);

  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  // Ecosystems
  const [ecoSystemsSelected, setEcosystemsSelected] = useState([]);
  const [ecoSystemsLoading, setEcosystemsLoading] = useState(true);

  // Opt mappings
  const [optMappings, setOptMappings] = useState([]);
  const [openOptMappingsModal, setOpenOptMappingsModal] = useState(false);

  // Opts Status Trait Output
  const [showOptsTraitRequestOutput, setShowOptsTraitRequestOutput] =
    useState(false);

  const [existingTraitNameError, setExistingTraitNameError] = useState("");

  // Checked Rows
  const [optMappingsSelected, setOptMappingsSelected] = useState(() => {
    if (request && request.requestDetails) {
      return request.requestDetails.optMappings.map((mapping) => mapping.id);
    }
    return [];
  });

  const [intersecting, setIntersecting] = useState([]);

  useEffect(() => {
    if (
      (previousStep === 1 || previousStep === 2) &&
      currentStep === 2 &&
      (optTrait?.opts?.length || 0) > 0 &&
      optMappings.length > 0 &&
      !optMappings.find((x) => x.ciamOptId.type === "div")
    ) {
      const inter = optMappings
        .filter((om) =>
          optTrait.opts.find(
            (o) =>
              o.ciamOptId === om.ciamOptId &&
              o.subscriptionOptNumber === om.subscriptionOptNumber &&
              o.serviceName === om.serviceName
          )
        )
        .map((x) => x.id);
      setIntersecting(inter);
      setOptMappingsSelected(inter);
    }
  }, [optMappings, traitId, optTrait, currentStep]);

  const isInfoStep = currentStep > totalSteps;
  const isFormDisabled = currentStep === 2;

  const getOptTraitsFromApi = useCallback(async () => {
    try {
      const response = await getOptTraits(`?traitId=${traitId}`);
      setOptTrait(response.items[0]);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setOptsIds([]);
        },
        addNotification,
      });
    }
  }, [traitId]);

  const getOptsFromApi = useCallback(async () => {
    setOptIdsLoading(true);
    try {
      const rsp1 = await getOpts(
        `?legalEntityId=${marketingProgram?.legalEntityNumber}`
      );
      setOptsIds(rsp1.items);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setOptsIds([]);
        },
        addNotification,
      });
    } finally {
      setOptIdsLoading(false);
    }
  }, [marketingProgram?.legalEntityNumber, traitId]);

  const getMarketingProgramsFromApi = useCallback(async (searchText) => {
    let filter = { itemsPerPage: 3, page: 1, normalize: true };
    if (searchText && searchText.length > 0) {
      filter = {
        ...filter,
        searchText,
      };
    }
    try {
      const rsp1 = await getMarketingProgramsApi(filter);
      setMarketingProgramOptions(rsp1.items);
      setCdpMarketingProgramOptions(rsp1.items);
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setMarketingProgramOptions([]);
        },
        addNotification,
      });
    } finally {
      setMarketingProgramsLoading(false);
    }
  }, []);

  const getEcosystemsFromApi = useCallback(async (name) => {
    const filter = { name };
    if (!name && name.length === 0) {
      delete filter.name;
    }
    try {
      const rsp1 = await getReferenceData(
        applicationConfig.referenceDataQueries.ecoSystems
      );
      setEcosystems(rsp1.ecoSystems);
      return rsp1.ecoSystems;
    } catch (error) {
      handleError({
        error,
        handle404: true,
        addNotification,
      });
    } finally {
      setEcosystemsLoading(false);
    }
    return [];
  }, []);

  const getState = () => {
    if (
      existingTraitNameError ===
      t("opts_status_trait.errors.validating_trait_name")
    ) {
      return applicationConfig.status.info;
    }
    if (
      existingTraitNameError ===
      t("opts_status_trait.errors.trait_name_already_present")
    ) {
      return applicationConfig.status.info;
    }
    return applicationConfig.status.success;
  };

  const searchForTraits = useCallback(async (searchText) => {
    increaseRequestsCount(1);
    try {
      if (searchText.length > 0) {
        let filter = {};
        filter = {
          name: "traitName",
          value: searchText,
        };
        setExistingTraitNameError(
          t("opts_status_trait.errors.validating_trait_name")
        );
        const response = await getTraits(
          parseSearchFilter(
            [filter],
            {
              traitName: "traitName",
            },
            1,
            3
          )
        );

        setTraitId(
          response.items.find((trait) =>
            trait.traitName.includes(`${searchText}OptIndicator`)
          )?.traitId || -1
        );

        setExistingTraitNameError(
          t("opts_status_trait.errors.trait_name_already_present")
        );
      }
    } catch (error) {
      setExistingTraitNameError(t("opts_status_trait.errors.trait_name_valid"));
    } finally {
      decreaseRequestsCount(1);
    }
  }, []);

  const getOptMappingsFromApi = async () => {
    if (
      !marketingProgram ||
      !marketingProgram.marketingProgramNumber ||
      !optsTraitInfo.channel.contact_point_type_code
    ) {
      return null;
    }
    const filter = {
      marketingProgramNumber: marketingProgram.marketingProgramNumber,
      contactPointTypeCode: optsTraitInfo.channel.contact_point_type_code,
    };
    try {
      increaseRequestsCount();
      const rsp1 = await getOptMappings(filter);
      let mappings = rsp1.items.map((item, index) => ({
        id: `${item.ciamOptId}-${index}`,
        ...item,
      }));
      let selected = rsp1.items.map((item, index) => ({
        id: `${item.ciamOptId}-${index}`,
        ...item,
      }));

      if (isRequestRevisionFlow) {
        const requestOptMappings = request.requestDetails.optMappings;
        mappings = [
          ...mappings,
          ...requestOptMappings
            .filter((y) => y.new)
            .map((x) => ({
              ...x,
              ciamOptId: (
                <div style={{ whiteSpace: "nowrap" }}>
                  {x.ciamOptId}
                  <Button
                    style={{
                      backgroundColor: "#0E61DD",
                      padding: "5px 8px",
                      borderRadius: 20,
                      fontSize: "12px",
                      textAlign: "center",
                      color: "#fff",
                      fontWeight: "bold",
                      marginLeft: "8px",
                    }}
                    className={classes.new}
                  >
                    NEW
                  </Button>
                </div>
              ),
            })),
        ];
        selected = requestOptMappings.map((x) => x.id);
        setOptMappingsSelected(selected);
      }

      setOptMappings(mappings);
      return rsp1.items;
    } catch (error) {
      handleError({
        error,
        handle404: () => {
          setOptMappings([]);
        },
        addNotification,
      });
    } finally {
      decreaseRequestsCount();
    }
    return [];
  };

  const setOptsTraitInfoFromApi = async (data) => {
    const { contactPointTypeCode, contactPointTypeName } =
      data.requestDetails.contactPointType;
    const {
      marketingProgram: marketingProgramApi,
      trait,
      ecosystems: ecosystemsApi,
    } = data.requestDetails;
    let channel = contactPointTypeName.split(" ")[0];
    channel = channel.toLowerCase();
    const { optIndicator } = trait;
    let legalEntityName = optIndicator.traitName.replace(channel, "");
    const mpDescription = marketingProgramApi.description
      .split(" ")
      .slice(1)
      .join("");
    legalEntityName = legalEntityName.replace(
      t("opts_status_trait.opt_indicator"),
      ""
    );
    legalEntityName = legalEntityName.replace(mpDescription, "");
    legalEntityName = legalEntityName.replace(/[\d]+/, "");
    setEcosystemsSelected(
      ecosystemsApi.map((ecosystem) => ({
        ...ecosystem,
        title: ecosystem.ecosystemName,
      }))
    );
    setMarketingProgram({
      title: `${marketingProgramApi.marketingProgramNumber} - ${marketingProgramApi.description}`,
      ...marketingProgramApi,
      legalEntityName,
    });
    setOptsTraitInfo((draft) => {
      draft.channel = {
        contact_point_type_code: contactPointTypeCode,
        contact_point_type_name: contactPointTypeName,
      };
      draft.marketingProgram = mpDescription;
    });
  };

  // Debounce & Memoize Api Calls
  const debouncedMarketingProgramsFromApi = debounce(
    getMarketingProgramsFromApi,
    applicationConfig.waitTime
  );
  const memoizedMarketingProgramsFromApi = useCallback((val) => {
    debouncedMarketingProgramsFromApi(val);
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedEcosystemsFromApi = debounce(
    getEcosystemsFromApi,
    applicationConfig.waitTime
  );
  const memoizedEcosystemsFromApi = useCallback((val) => {
    debouncedEcosystemsFromApi(val);
  }, []);

  useEffect(async () => {
    if (user.userId && isUserAuthorized) {
      increaseRequestsCount(2);
      await getMarketingProgramsFromApi("");
      await getEcosystemsFromApi("");
      decreaseRequestsCount(2);
    }
  }, [user]);

  const updateStatusTraitInfo = async () => {
    if (
      !isEmpty(marketingProgram) &&
      marketingProgram &&
      optsTraitInfo.channel
    ) {
      const channel =
        optsTraitInfo.channel.contact_point_type_name.split(" ")[0];
      const marketingProgramDescription = marketingProgram.description
        .split(" ")
        .slice(1)
        .join("");
      const legalEntityName = marketingProgram.legalEntityName
        ? marketingProgram.legalEntityName.split(" ").join("")
        : "";
      const statusTraitName =
        channel.charAt(0).toLowerCase() +
        channel.slice(1) +
        legalEntityName +
        marketingProgramDescription;
      setOptsTraitInfo((draft) => {
        draft.statusTraitNameOptIndicator = statusTraitName;
        draft.statusTraitNameOptIndicatorPrefix = statusTraitName;
        draft.statusTraitNameOptChoiceDate = statusTraitName;
        draft.statusTraitNameOptChoiceDatePrefix = statusTraitName;
      });
      await searchForTraits(statusTraitName);
    }
  };

  useEffect(async () => {
    if (currentStep === 2 && previousStep === 1) {
      increaseRequestsCount(3);
      setOptMappings([]);
      await getOptMappingsFromApi();
      if (marketingProgram?.legalEntityNumber) {
        await getOptsFromApi();
      }
      if (traitId > -1) {
        await getOptTraitsFromApi();
      }
      decreaseRequestsCount(3);
    }
  }, [currentStep, marketingProgram, traitId]);

  useEffect(async () => {
    await updateStatusTraitInfo();
  }, [marketingProgram, optsTraitInfo.channel]);

  useEffect(() => {
    if (location.pathname === "/opts/status/revision") {
      if (Object.keys(request).length === 0) {
        history.goBack();
      }
    }
  }, []);

  useEffect(async () => {
    if (isRequestRevisionFlow) {
      setOptsTraitInfoFromApi(request);
    }
  }, []);

  const validateFields = (step) => {
    if (step === 1) {
      const traitInfo = JSON.parse(JSON.stringify(optsTraitInfo));

      delete traitInfo.statusTraitNameOptIndicatorPrefix;
      delete traitInfo.statusTraitNameOptChoiceDatePrefix;

      const step1Errors = {
        marketingProgram: {
          error: "",
        },
        ecosystems: {
          error: "",
        },
        channel: {
          error: "",
        },
        statusTraitNameOptIndicator: {
          error: "",
        },
        statusTraitNameOptChoiceDate: {
          error: "",
        },
      };

      if (loading) {
        return {
          valid: false,
        };
      }

      if (ecoSystemsSelected.length === 0) {
        step1Errors.ecosystems.error = "Please select at least one ecosystem";
      } else {
        step1Errors.ecosystems.error = null;
      }

      if (isEmpty(optsTraitInfo.marketingProgram)) {
        step1Errors.marketingProgram.error =
          "Please select a marketing program";
      } else {
        step1Errors.marketingProgram.error = null;
      }

      if (isEmpty(optsTraitInfo.channel)) {
        step1Errors.channel.error = "Please select a channel";
      } else {
        step1Errors.channel.error = null;
      }

      if (isEmpty(optsTraitInfo.statusTraitNameOptIndicator)) {
        step1Errors.statusTraitNameOptIndicator.error =
          "There should be a prefix for opt indicator trait";
      } else {
        step1Errors.statusTraitNameOptIndicator.error = null;
      }

      if (isEmpty(optsTraitInfo.statusTraitNameOptChoiceDate)) {
        step1Errors.statusTraitNameOptChoiceDate.error =
          "There should be a prefix for opt choice date trait";
      } else {
        step1Errors.statusTraitNameOptChoiceDate.error = null;
      }

      setErrors((x) => ({ ...x, step1: step1Errors }));
      return {
        valid: Object.values(step1Errors).every((val) => isEmpty(val.error)),
        stepErrors: step1Errors,
      };
    }

    if (step === 2) {
      const step2Errors = {
        optMappingsSelected: {
          error: "",
        },
      };

      const validSelection = optMappings.filter(
        (mapping) =>
          optMappingsSelected.includes(mapping.id) &&
          !intersecting.includes(mapping.id)
      );

      if (validSelection?.length === 0) {
        step2Errors.optMappingsSelected.error =
          "Please select at least one opt mapping";
      } else {
        step2Errors.optMappingsSelected.error = null;
      }

      setErrors((x) => ({ ...x, step2: step2Errors }));

      return {
        valid: Object.values(step2Errors).every((val) => isEmpty(val.error)),
        stepErrors: step2Errors,
      };
    }

    return {
      valid: true,
    };
  };

  let newOptFields = getFields(optsTraitInfo);
  newOptFields = newOptFields.map((field) => {
    if (field.label === t("common.labels.marketing_program")) {
      return {
        ...field,
        type: "custom",
        label: t("common.labels.marketing_program"),
        element: Autocomplete,
        props: {
          id: "combo-box-demo",
          options: marketingProgramOptions.map((option) => ({
            ...option,
            title: `${option.marketingProgramNumber} - ${option.description}`,
          })),
          loading: marketingProgramsLoading,
          getOptionLabel: (option) => option.title || "",
          onChange: async (event, value) => {
            setOptsTraitInfo((draft) => {
              draft.marketingProgram = value ? value.description : "";
            });
            setOptMappingInfo((draft) => {
              draft.cdpMarketingProgram = value || "";
            });
            setMarketingProgram(value);
            setErrors((x) => ({
              ...x,
              step1: {
                ...x.step1,
                marketingProgram: { error: null },
              },
            }));
          },
          value: marketingProgram,
          disabled: isFormDisabled,
          renderInput: (params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Marketing Program"
              helperText={errors.step1.marketingProgram.error}
              error={!!errors.step1.marketingProgram.error}
              onChange={(event) => {
                setMarketingProgramsLoading(true);
                setMarketingProgramOptions([]);
                memoizedMarketingProgramsFromApi(
                  event.target.value,
                  optsTraitInfo.ciamLegalEntityId
                );
              }}
            />
          ),
        },
      };
    }
    if (field.label === t("common.labels.channel")) {
      return {
        ...field,
        props: {
          label: optsTraitInfo.channel === "" ? t("common.labels.channel") : "",
          onChange: (event) => {
            setOptsTraitInfo((draft) => {
              draft.channel = event.target.value;
            });
            if (
              request &&
              request.requestDetails &&
              request.requestDetails.contactPointType.contactPointTypeName ===
                event.target.value.contact_point_type_name
            ) {
              setOptMappingsSelected(
                request.requestDetails.optMappings.map((mapping) => mapping.id)
              );
            } else {
              setOptMappingsSelected([]);
            }
            setErrors((x) => ({
              ...x,
              step1: {
                ...x.step1,
                channel: { error: null },
              },
            }));
          },
          helperText: errors.step1.channel.error,
          error: !!errors.step1.channel.error,
          select: true,
          variant: "outlined",
          inputProps: {
            "data-testid": "channel",
          },
          value: optsTraitInfo.channel.contact_point_type_name,
          SelectProps: {
            displayEmpty: true,
            renderValue: () => (
              <span>{optsTraitInfo.channel.contact_point_type_name}</span>
            ),
          },
          values: contactPointTypeOptions.map((channel) => ({
            label: channel.contact_point_type_name,
            value: channel,
          })),
          disabled: isFormDisabled,
        },
      };
    }
    if (field.label === t("common.labels.ecosystems")) {
      return {
        type: "custom",
        element: CustomAutoComplete,
        label: t("common.labels.ecosystems"),
        props: {
          options: ecosystems.map((option) => {
            return {
              ...option,
              title: option.ecoSystemName,
              personalDataAllowed: true,
            };
          }),
          id: "ecosystems",
          "data-testid": "ecosystems",
          placeholder: t("autocomplete.ecosystem_placeholder"),
          loading: ecoSystemsLoading,
          value: ecoSystemsSelected,
          inputValue: ecosystemInputValue,
          error: !!errors.step1.ecosystems.error,
          errorText: errors.step1.ecosystems.error,
          setValue: (data) => {
            setEcosystemsSelected(data);
            setErrors((x) => ({
              ...x,
              step1: {
                ...x.step1,
                ecosystems: { error: null },
              },
            }));
          },
          onInputChange: (val) => {
            setEcosystemInputValue(val);
            setEcosystemsLoading(true);
            setEcosystems([]);
            memoizedEcosystemsFromApi(val);
          },
          optionDisabled: (option) => !option.personalDataAllowed,
          disablePortal: true,
        },
      };
    }
    if (
      Object.keys(marketingProgram || {}).length !== 0 &&
      !isEmpty(optsTraitInfo.channel)
    ) {
      if (
        field.label === t("opts_status_trait.status_trait_name_opt_indicator")
      ) {
        return {
          ...field,
          type: "custom",
          element: PartiallyEditableText,
          props: {
            label:
              optsTraitInfo.statusTraitNameOptIndicator === ""
                ? t("opts_status_trait.status_trait_name_opt_indicator")
                : "",
            error:
              !!errors.step1.statusTraitNameOptIndicator.error ||
              existingTraitNameError ===
                t("opts_status_trait.errors.trait_name_already_present"),
            helperText: (
              <InlineMessage
                message={
                  errors.step1.statusTraitNameOptIndicator.error ||
                  existingTraitNameError
                }
                state={
                  errors.step1.statusTraitNameOptIndicator.error
                    ? applicationConfig.status.error
                    : getState()
                }
              />
            ),
            prefixText: optsTraitInfo.statusTraitNameOptIndicator,
            suffixText: optsTraitInfo.optIndicator,
            editablePrefix: optsTraitInfo.statusTraitNameOptIndicatorPrefix,
            onChangePrefix: (value) => {
              setOptsTraitInfo((draft) => {
                draft.statusTraitNameOptIndicatorPrefix = value;
              });
            },
            onEditMode: () => {
              setExistingTraitNameError("");
              setErrors((x) => ({
                ...x,
                step1: {
                  ...x.step1,
                  statusTraitNameOptIndicator: { error: null },
                  statusTraitNameOptChoiceDate: { error: null },
                },
              }));
            },
            onSave: async () => {
              setOptsTraitInfo((draft) => {
                draft.statusTraitNameOptIndicator =
                  draft.statusTraitNameOptIndicatorPrefix;
                draft.statusTraitNameOptChoiceDate =
                  draft.statusTraitNameOptIndicatorPrefix;
              });
              await searchForTraits(
                optsTraitInfo.statusTraitNameOptIndicatorPrefix
              );
            },
            onCancel: async () => {
              setOptsTraitInfo((draft) => {
                draft.statusTraitNameOptIndicatorPrefix =
                  draft.statusTraitNameOptIndicator;
              });
              await searchForTraits(
                optsTraitInfo.statusTraitNameOptIndicatorPrefix
              );
            },
          },
        };
      }
      if (
        field.label === t("opts_status_trait.status_trait_name_opt_choice_date")
      ) {
        return {
          ...field,
          type: "text-input",
          label: t("opts_status_trait.status_trait_name_opt_choice_date"),
          props: {
            value:
              optsTraitInfo.statusTraitNameOptChoiceDate +
              optsTraitInfo.optChoiceDate,
            variant: "outlined",
            error: !!errors.step1.statusTraitNameOptChoiceDate.error,
            helperText: errors.step1.statusTraitNameOptChoiceDate.error,
            inputProps: {
              "data-testid": "editable-text",
            },
          },
        };
      }
    }
    return null;
  });

  const optsTraitRequestTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program_name"),
      flex: 1,
      sortable: false,
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      width: 150,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  setShowOptsTraitRequestOutput(true);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];

  const getConfirmationDetails = () => {
    const info = [
      {
        label: t("common.labels.marketing_program"),
        value: `${marketingProgram.marketingProgramNumber} - ${marketingProgram.description}`,
      },
      {
        label: t("common.labels.ecosystems"),
        value: ecoSystemsSelected
          .map((ecosystem) => ecosystem.ecoSystemName)
          .join(", "),
      },
      {
        label: t("common.labels.channel"),
        value: optsTraitInfo.channel.contact_point_type_name,
      },
      {
        label: t("opts_status_trait.status_trait_name_opt_indicator"),
        value:
          optsTraitInfo.statusTraitNameOptIndicator +
          optsTraitInfo.optIndicator,
      },
      {
        label: t("opts_status_trait.status_trait_name_opt_choice_date"),
        value:
          optsTraitInfo.statusTraitNameOptChoiceDate +
          optsTraitInfo.optChoiceDate,
      },
    ];
    return info;
  };

  const renderStep = (step) => {
    if (step === 1) {
      if (
        loading &&
        marketingProgramOptions.length === 0 &&
        ecosystems.length === 0
      ) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
          </>
        );
      }

      return (
        <div
          className={classes.optsTraitContainer}
          data-testid="opts-trait-container"
        >
          <Form
            fields={newOptFields.filter((field) => field !== null)}
            fieldClassName={classes.inputContainer}
            containerClassName={clsx(classes.grid)}
          />
        </div>
      );
    }

    if (step === 2) {
      if (loading) {
        return (
          <>
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
            <Skeleton height={56} />
          </>
        );
      }

      return (
        <div>
          <div
            style={{
              height: 150 * optMappings.length + 60,
              maxHeight: "600px",
              overflow: "hidden",
            }}
            className={classes.tableWrapper}
          >
            <Table
              columns={optsMappingTableColumns}
              hidePagination
              disableSelectAllCheckbox
              rows={optMappings.map((mapping, index) => ({
                id: mapping.id,
                optId: mapping.ciamOptId,
                optText: mapping.optTextEnglish,
                subId: mapping.subscriptionOptNumber,
                serviceName: mapping.serviceName,
                channelType: mapping.contactPointTypeName,
                operations: mapping.new && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      onClick={async () => {
                        setOpenOptMappingModal(true);
                        setOptMappingIndex(index);
                        const numbers = subscriptionOptNumbers;
                        numbers[index] = 0;
                        setSubscriptionOptNumbers(numbers);
                      }}
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                      data-testid="button-1"
                    >
                      <span className={globalStyles.editIcon}>
                        <EditOutlinedIcon />
                      </span>
                    </div>
                    <div
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setOptMappingIndex(index);
                      }}
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      aria-label="delete"
                      data-testid="button-2"
                    >
                      <span className={globalStyles.deleteIcon}>
                        <DeleteOutlineOutlinedIcon />
                      </span>
                    </div>
                  </div>
                ),
              }))}
              isRowSelectable={({ row }) => {
                return !intersecting.includes(row.id);
              }}
              selectionModel={optMappingsSelected}
              checkboxSelection
              onSelectionModelChange={(e) => {
                if (JSON.stringify(optMappingsSelected) !== JSON.stringify(e)) {
                  setOptMappingsSelected(e);
                  setErrors((x) => ({
                    ...x,
                    step2: { ...x.step2, optMappingsSelected: { error: "" } },
                  }));
                }
              }}
              noRowsText={t("opts_status_trait.no_rows_text")}
              tableStyle={classes.myRequestsTable}
              loading={loading}
            />
          </div>
          <div className={globalStyles.addTraitBtn}>
            <AddCircleOutlineIcon />
            <Button
              onClick={() => {
                let number = 0;
                // Set subscription number
                if (subscriptionOptNumbers.length >= 1) {
                  number = Math.max(...subscriptionOptNumbers);
                }
                setOptMappingInfo({
                  ...optMappingInfo,
                  subscriptionOptNumber: number + 1,
                });
                setOpenOptMappingModal(true);
              }}
            >
              {t("new_opt_id.add_opt_mapping")}
            </Button>
          </div>
          <InlineMessage message={errors.step2.optMappingsSelected.error} />
        </div>
      );
    }
    if (step === 3) {
      const details = getConfirmationDetails();
      const detailsDOM = details.map((info) => {
        return (
          <div
            className={clsx(
              classes.flexContainer,
              classes.infoContainer,
              classes.justifyContent
            )}
            key={`${info.label}${info.index}`}
          >
            <Typography variant="h6">{info.label} :</Typography>
            <Typography variant="h6" style={{ fontWeight: "normal" }}>
              {info.value}
            </Typography>
          </div>
        );
      });

      return (
        <div>
          {detailsDOM}
          <div className={classes.confirmationContainer}>
            <Typography
              variant="h4"
              style={{ fontSize: "16px", fontWeight: "500" }}
            >
              {t("opts_status_trait.selected_opt_mappings")}
            </Typography>
            <div
              style={{
                height: 150 * optMappingsSelected.length + 60,
                maxHeight: "600px",
                overflow: "hidden",
              }}
              className={classes.tableWrapper}
            >
              <Table
                hidePagination
                columns={optsMappingTableColumns}
                rows={optMappings
                  .filter(
                    (mapping) =>
                      optMappingsSelected.includes(mapping.id) &&
                      !intersecting.includes(mapping.id)
                  )
                  .map((mapping) => ({
                    id: mapping.id,
                    optId: mapping.ciamOptId,
                    optText: mapping.optTextEnglish,
                    subId: mapping.subscriptionOptNumber,
                    serviceName: mapping.serviceName,
                    channelType: mapping.contactPointTypeName,
                  }))}
                tableStyle={classes.myRequestsTable}
                noRowsText={t("opts_status_trait.no_rows_text")}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        <div
          style={{
            height: 150 * optTraitResponse.length + 60,
            maxHeight: "600px",
            overflow: "hidden",
          }}
          className={classes.tableWrapper}
        >
          <Table
            hidePagination
            columns={optsTraitRequestTableColumns}
            rows={optTraitResponse}
            tableStyle={classes.myRequestsTable}
          />
        </div>
      </div>
    );
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied goToLink="/opts" goToText={t("access_denied.go_to_opts")} />
  ) : (
    <>
      <InputFlow
        steps={["Trait Details", "Opt Mappings", "Preview & Confirm"]}
        totalSteps={totalSteps}
        currentStep={currentStep}
        headerText={
          isRequestRevisionFlow
            ? createOptTraitConstants.optRevisionMessages[currentStep]
            : createOptTraitConstants.optCreationMessages[currentStep]
        }
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                {currentStep === 1 ? (
                  <div className={classes.backBtn}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        history.goBack();
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                ) : (
                  <div
                    className={clsx(classes.flexContainer, classes.step2Footer)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                        setCurrentStep(currentStep - 1);
                      }}
                    >
                      {t("back")}
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep <= totalSteps && (
              <Button
                className={classes.regularBtn}
                color="primary"
                onClick={async () => {
                  if (currentStep === totalSteps) {
                    try {
                      const validSelection = optMappings.filter(
                        (mapping) =>
                          optMappingsSelected.includes(mapping.id) &&
                          !intersecting.includes(mapping.id)
                      );

                      const payload = createBackendPayload(
                        marketingProgram,
                        optsTraitInfo,
                        ecoSystemsSelected,
                        validSelection
                      );

                      increaseRequestsCount();
                      if (!isRequestRevisionFlow) {
                        const data = await createOptTraits(payload);
                        const { items } = data;
                        setOptTraitResponse(
                          items.map((item, index) => ({
                            id: index,
                            ...item,
                            requestId: item.requestId,
                            status:
                              item.status[0].toUpperCase() +
                              item.status.slice(1),
                            marketingProgram: `${item.marketingProgram.marketingProgramNumber} - ${item.marketingProgram.description}`,
                          }))
                        );
                      } else if (currentStep === totalSteps) {
                        const data = await updateRequest(
                          request.requestId,
                          payload,
                          applicationConfig.modules.opts
                        );
                        setIsRequestRevisionUpdated(true);
                        setRequest(data);
                        history.goBack();
                        addNotification(
                          t("notifications.request_edited_success"),
                          t("status.success")
                        );
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } catch (error) {
                      handleError({
                        error,
                        handle404: false,
                        addNotification,
                      });
                    } finally {
                      decreaseRequestsCount();
                    }
                  } else {
                    const validateStep = validateFields(currentStep);
                    if (!validateStep.valid) {
                      return;
                    }
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    setCurrentStep(currentStep + 1);
                  }
                }}
              >
                {loading && (
                  <Box
                    sx={{
                      mr: 1,
                      mt: 0.5,
                    }}
                  >
                    <CircularProgress size={20} color="white" />
                  </Box>
                )}
                {createOptTraitConstants.optFooterText[currentStep]}
              </Button>
            )}
            {isInfoStep && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/opts?displayTab=opt-traits"
                  className={classes.regularBtn}
                >
                  {t("common.labels.back_to_opts")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${optTraitResponse[0].requestId}`}
                  className={classes.regularBtn}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div
          className={clsx(
            classes.sourceContainer,
            (currentStep === 2 || currentStep === 4) && classes.paddingZero
          )}
        >
          {renderStep(currentStep)}
        </div>
      </InputFlow>
      {showOptsTraitRequestOutput && (
        <OptsTraitRequestOutputModal
          isOpen={showOptsTraitRequestOutput}
          isLoading={false}
          requestId={optTraitResponse[0].requestId}
          data={optTraitResponse[0].output}
          setShowOptsTraitOutput={() => setShowOptsTraitRequestOutput(false)}
        />
      )}

      {openOptMappingsModal && (
        <OptsMappingModal
          title={`${optTraitResponse[0].requestId} - ${t(
            "common.labels.opt_mappings"
          )}`}
          isOpen={openOptMappingsModal}
          mappings={optTraitResponse[0].optMappings}
          setOpenOptMappingsModal={setOpenOptMappingsModal}
        />
      )}
      <NewOptIdMapping
        cdpMarketingProgramOptions={cdpMarketingProgramOptions}
        cdpMarketingProgramsLoading={cdpMarketingProgramsLoading}
        getcdpMarketingProgramOptions={async (mp) => {
          let legalEntity = null;
          if (optIdValue.new) {
            legalEntity = optInfo.ciamMarketingProgram.legalEntityNumber;
          } else {
            legalEntity = optMappingInfo.ciamLegalEntityId;
          }
          setCdpMarketingProgramsLoading(true);
          memoizedMarketingProgramsFromApi(mp, legalEntity);
        }}
        optIds={optIds}
        optIdsLoading={optIdsLoading}
        optMappings={optMappings}
        editableOptMapping={optMappings[optMappingIndex]}
        openModal={openOptMappingModal}
        handleClose={() => {
          setOpenOptMappingModal(false);
          setOptMappingIndex(-1);
        }}
        handleSubmit={(newData, oldData) => {
          let { subscriptionOptNumber } = newData;
          subscriptionOptNumber = parseInt(subscriptionOptNumber, 10);

          const newOpt = {
            ciamOptId: (
              <div style={{ whiteSpace: "nowrap" }}>
                {newData.optId.ciamOptId}
                <Button
                  style={{
                    backgroundColor: "#0E61DD",
                    padding: "5px 8px",
                    borderRadius: 20,
                    fontSize: "12px",
                    textAlign: "center",
                    color: "#fff",
                    fontWeight: "bold",
                    marginLeft: "8px",
                  }}
                  className={classes.new}
                >
                  NEW
                </Button>
              </div>
            ),
            og_data: newData,
            contactPointTypeCode:
              newData.contactPointCategoryCode.contact_point_type_code,
            contactPointTypeName:
              newData.contactPointCategoryCode.contact_point_type_name,
            contactPointCategoryCode:
              newData.contactPointCategoryCode.contact_point_category_code,
            description: newData.contactPointCategoryCode.description,
            optTextEnglish: newData.optId.optTextEnglish,
            serviceName: newData.serviceName,
            subscriptionOptNumber: newData.subscriptionOptNumber,
            id: `${newData.optId.ciamOptId}-${optMappings.length}`,
            new: true,
          };

          if (oldData) {
            const numbers = subscriptionOptNumbers;
            numbers[optMappingIndex] = subscriptionOptNumber;
            setSubscriptionOptNumbers(numbers);

            newOpt.id = oldData.id;
            setOptMappings((x) =>
              x.map((y) => {
                if (y.id === newOpt.id) {
                  return newOpt;
                }

                return y;
              })
            );
            return;
          }

          setSubscriptionOptNumbers([
            ...subscriptionOptNumbers,
            subscriptionOptNumber,
          ]);
          setOptMappings((x) => [...x, newOpt]);
          setOptMappingsSelected((x) => [...x, newOpt.id]);
          setErrors((x) => ({
            ...x,
            step2: { ...x.step2, optMappingsSelected: { error: "" } },
          }));
        }}
        serviceName={serviceName}
        setServiceName={setServiceName}
        optMappingInfo={optMappingInfo}
        setOptMappingInfo={setOptMappingInfo}
        isDisabled={false}
        title={t("new_opt_id.new_opt_mapping")}
        subscriptionOptNumbers={subscriptionOptNumbers}
      />
      <ConfirmationModal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        title={t("dialogs.confirm_remove")}
        message={
          currentStep === 1
            ? t("new_opt_id.dialogs.opt_id_warning")
            : t("new_opt_id.dialogs.opt_mapping_warning")
        }
        btn1Text={t("common.cancel")}
        btn2Text={t("common.ok")}
        btn2Action={async () => {
          setOptMappings(
            produce((draft) => {
              draft.splice(optMappingIndex, 1);
            })
          );
          setOptMappingIndex(-1);
          setOpenDeleteModal(false);
        }}
        type="error"
      />
      <Prompt
        message={() => {
          if (isRequestRevisionFlow) {
            return true;
          }
          return t("prompt.progress_lost");
        }}
        when={
          isInfoStep
            ? false
            : Object.values(optsTraitInfo).filter(
                (val) => val !== null && val.length > 0
              ).length > 0
        }
      />
    </>
  );
};

export default NewOptsStatusTraitContainer;
