/* eslint-disable*/
import { useState, useEffect, useCallback } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";

import { useTranslation } from "react-i18next";

import getTraits from "../../../../api/get-traits";
import CustomAutoComplete from "../../../../components/CustomAutoComplete";
import Form from "../../../../components/Form";
import InlineMessage from "../../../../components/InlineMessage";

import applicationConfig from "../../../../config/applicationConfig";
import insightModuleConfig from "../../../../config/insightModuleConfig";
import useNotifier from "../../../../hooks/useNotifier";
import useUserProfile from "../../../../hooks/useUserProfile";
import debounce from "../../../../utilities/debounce";
import { isEmptyString } from "../../../../utilities/formValidation";
import handleError from "../../../../utilities/handleError";
import useImmer from "../../../../utilities/useImmer";
import validateInsightsInfo from "../../CreateInsightContainer/helpers/validateInsightsInfo";

import {
  customIndicatorModify,
  modifyOutputTrait,
  createInputTraits,
} from "../../InsightsContainer/helpers/insightsUtils";

import useStyles from "./styles";

const NewInsight = ({
  insightsInfo,
  setInsightsInfo,
  openModal,
  handleClose,
  handleSubmit,
  isDisabled,
  title,
}) => {
  const initialFocusedValues = {
    insightsName: false,
    description: false,
    inputTraits: false,
    outputTrait: false,
    customIndicator: false,
    sqlExpression: false,
  };
  const [currentStep, setCurrentStep] = useState(1);
  const [isCancelClicked, setIsCancelClicked] = useState(false);
  const [focused, setFocused] = useImmer(initialFocusedValues);
  const [insightNameValidationState, setInsightNameValidationState] = useState(
    {}
  );
  const [traitsLoading, setTraitsLoading] = useState(false);
  const { user } = useUserProfile();

  const [insightsLoading, setInsightsLoading] = useState(false);

  const [initialInsightOptions] = useState([]);
  const { addNotification } = useNotifier();

  const [, setSearchTraitValue] = useState("");
  const [traitOptions, setTraitOptions] = useState([]);

  const [inputTraitsValue, setInputTraitsValue] = useState([]);
  const [inputTraitsInputValue, setInputTraitsInputValue] = useState(
    inputTraitsValue?.title || ""
  );
  const [outputTraitValue, setOutputTraitValue] = useState(null);
  const [outputTraitInputValue, setOutputTraitInputValue] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const { createInsightConstants } = insightModuleConfig;
  const initialErrorsState = {
    insightNameError: null,
    insightNameErrorState: "",
    insightDescriptionError: null,
    insightInputTraitsError: null,
    insightOutputTraitsError: null,
    sqlIndicatorError: null,
    sqlExpressionError: null,
  };
  const [errors, setErrors] = useState(initialErrorsState);

  const resetState = () => {
    setFocused(initialFocusedValues);
    setCurrentStep(1);
    setErrors(initialErrorsState);
    setInsightNameValidationState({});
  };

  const checkStep1Disabled = (errorMap) => {
    const requiredFields = ["insightsName", "description"];
    const errorValues = JSON.parse(JSON.stringify(errorMap));
    const checkRequiredFields = requiredFields.filter((field) =>
      isEmptyString(insightsInfo[field])
    );
    if (
      errorValues.insightNameErrorState === applicationConfig.status.success
    ) {
      delete errorValues.insightNameErrorState;
      delete errorValues.insightNameError;
    }
    return !(
      Object.values(errorValues).filter((val) => val).length === 0 &&
      checkRequiredFields.length === 0
    );
  };

  const getTraitsFromApi = useCallback(async (searchText, page, perPage) => {
    let filter = {};
    if (searchText?.length > 0) {
      filter = {
        searchText,
        searchFields: "traitId,traitName,description",
      };
    }
    try {
      setTraitsLoading(true);
      const rsp1 = await getTraits(
        `?page=${page || 1}&itemsPerPage=${perPage || 6}&${new URLSearchParams(
          filter
        ).toString()}`
      );
      setTraitOptions([...rsp1.items]);
      if (searchText.length === 0) {
        if (initialInsightOptions.length > 0) {
          const newInitialInsightOptions = [...initialInsightOptions];
          newInitialInsightOptions.splice(
            newInitialInsightOptions.length - 1,
            0,
            ...rsp1.items
          );
          setTraitOptions(newInitialInsightOptions);
        }
      }
    } catch (error) {
      // handleError({
      //   error,
      //   handle404: () => {
      //     setEventOptions([]);
      //   },
      //   addNotification,
      // });
    } finally {
      setInsightsLoading(false);
      setTraitsLoading(false);
    }
  }, []);

  // Debounce & Memoize Api Calls
  const debouncedTraitsFromApi = debounce(
    getTraitsFromApi,
    applicationConfig.waitTime
  );

  const memoizedTraitsFromApi = useCallback(async (val) => {
    debouncedTraitsFromApi(val);
  }, []);

  useEffect(() => {
    if (insightsInfo?.outputTrait && !outputTraitInputValue) {
      const outputTrait = modifyOutputTrait(insightsInfo?.outputTrait, true);
      setOutputTraitValue(outputTrait);
      setOutputTraitInputValue(outputTrait?.title);
    }
  }, [insightsInfo?.outputTrait, openModal]);

  useEffect(() => {
    if (
      insightsInfo?.inputTraits?.length > 0 &&
      inputTraitsValue?.length === 0
    ) {
      const inputTraits = createInputTraits(insightsInfo?.inputTraits, true);
      if (inputTraits?.length > 0) {
        setInputTraitsValue(inputTraits);
      }
    }
  }, [insightsInfo?.inputTraits, openModal]);

  useEffect(async () => {
    if (user.userId) {
      await getTraitsFromApi("");
    }
  }, [user]);

  const inputTraitsOptions = (inputOptions) => {
    const outputTraitsVal = outputTraitValue
      ? insightsInfo.outputTrait
      : insightsInfo.outputTrait;
    return inputOptions.filter(
      (value) => value?.traitId !== outputTraitsVal?.traitId
    );
  };

  const outputTraitsOptions = (outputOptions) => {
    const inputTraitsVal = inputTraitsValue
      ? insightsInfo?.inputTraits
      : insightsInfo?.inputTraits;
    const inputTraitsValIds =
      (inputTraitsVal?.length > 0 &&
        inputTraitsVal.map((item) => item?.traitId)) ||
      [];
    const modifyOutputOptions = outputOptions.filter(
      (item1) => !inputTraitsValIds.includes(item1?.traitId)
    );
    return modifyOutputOptions;
  };

  const newInsightFields = [
    {
      label: t("common.labels.insight_name"),
      type: applicationConfig.inputType.textInput,
      flex: 2,
      required: true,
      props: {
        placeholder: t("insight_container.placeholders.insight_name"),
        onChange: (insight) => {
          setInsightsInfo((draft) => {
            draft.insightsName = insight.target.value;
          });
          setInsightNameValidationState({
            message: "",
          });
        },
        error:
          insightNameValidationState.state === "error" ||
          errors.insightNameError !== null,
        value: insightsInfo.insightsName || insightsInfo.insightName,
        helperText: (
          <InlineMessage
            message={
              errors.insightNameError || insightNameValidationState.message
            }
            state={insightNameValidationState.state}
          />
        ),
        onBlur: () => {
          setFocused((draft) => {
            draft.insightsName = true;
          });
        },
        onFocus: () => {
          setInsightNameValidationState({});
          setFocused((draft) => {
            draft.insightsName = false;
          });
        },
        multiline: true,
        disabled: isDisabled,
      },
    },
    {
      label: t("common.labels.insight_description"),
      type: applicationConfig.inputType.textInput,
      required: true,
      props: {
        placeholder: t("insight_container.placeholders.insight_description"),
        onChange: (insight) =>
          setInsightsInfo((draft) => {
            draft.description = insight.target.value;
          }),
        error: errors.insightDescriptionError !== null,
        value: insightsInfo.description,
        helperText: <InlineMessage message={errors.insightDescriptionError} />,
        onBlur: () => {
          setFocused((draft) => {
            draft.description = true;
          });
        },
        onFocus: () => {
          setFocused((draft) => {
            draft.description = false;
          });
        },
        inputProps: {
          "data-testid": "insight-description",
        },
        rows: 2,
        multiline: true,
        disabled: isDisabled,
      },
    },

    {
      type: applicationConfig.inputType.custom,
      element: CustomAutoComplete,
      required: true,
      label: t("common.labels.input_traits"),
      props: {
        isMultiple: true,
        hideCheckbox: true,
        disableCloseOnSelect: false,
        disableFilterOptions: true,
        id: "input-traits-id",
        loading: traitsLoading,
        placeholder: traitsLoading
          ? t("common.loading")
          : t("new_trait.select_option"),

        options: traitsLoading
          ? []
          : inputTraitsOptions(traitOptions)
              .slice(0, Math.min(3, traitOptions?.length))
              .map((type) => ({
                ...type,
                title: `${type?.traitId} - ${type?.traitName}`,
              })),
        value: inputTraitsValue || [],
        inputValue: inputTraitsInputValue || insightsInfo?.inputTraits?.title,
        setValue: (value) => {
          setInputTraitsValue(value);
          setInputTraitsInputValue("");
          setInsightsInfo((draft) => {
            draft.inputTraits = value;
          });
        },
        error: errors.insightInputTraitsError !== null,
        errorText: errors.insightInputTraitsError,
        onInputChange: (val) => {
          setTraitOptions([]);
          setSearchTraitValue(val);
          setInputTraitsInputValue(val);
          memoizedTraitsFromApi(val);
        },
        isDisabled,
      },
    },

    {
      type: applicationConfig.inputType.custom,
      element: CustomAutoComplete,
      required: true,
      label: t("common.labels.output_traits"),
      props: {
        isMultiple: false,
        hideCheckbox: true,
        disableCloseOnSelect: false,
        disableFilterOptions: true,
        id: "output-trait-id",
        loading: traitsLoading,
        placeholder: traitsLoading
          ? t("common.loading")
          : t("new_trait.select_option"),
        options: traitsLoading
          ? []
          : outputTraitsOptions(traitOptions)
              .slice(0, Math.min(3, traitOptions.length))
              .map((type) => ({
                ...type,
                title: `${type.traitId} - ${type.traitName}`,
              })),
        value: outputTraitValue
          ? outputTraitValue
          : modifyOutputTrait(insightsInfo?.outputTrait, true),
        inputValue: outputTraitInputValue,
        setValue: (value) => {
          setOutputTraitValue(value);
          setOutputTraitInputValue(value?.title);
          setInsightsInfo((draft) => {
            draft.outputTrait = value;
          });
        },
        error: errors.insightOutputTraitsError !== null,
        errorText: errors.insightOutputTraitsError,
        onInputChange: (val) => {
          setOutputTraitInputValue(val);
          memoizedTraitsFromApi(val);
        },
        isDisabled,
      },
    },
    {
      label: t("common.labels.sql_custom_indecator"),
      type: applicationConfig.inputType.dropdown,
      required: true,
      props: {
        select: true,
        variant: "outlined",
        placeholder: insightsLoading ? t("common.loading") : "",
        onChange: (insight) =>
          setInsightsInfo((draft) => {
            draft.customIndicator = insight.target.value;
            draft.sqlExpression = "";
          }),
        inputProps: {
          "data-testid": "sql_custom_indecator",
        },
        values: createInsightConstants.sql_indicator.map((option) => ({
          label: option,
          value: option,
        })),
        value: customIndicatorModify(insightsInfo.customIndicator),
        disabled: isDisabled,
        helperText: focused.customIndicator && (
          <InlineMessage message={errors.sqlIndicatorError} />
        ),
      },
    },
    {
      ...(insightsInfo.customIndicator ===
      createInsightConstants.sql_indicator[0]
        ? {
            label: t("common.labels.sql_expression"),
            type: applicationConfig.inputType.textInput,
            required: true,
            flex: 2,
            props: {
              placeholder: t("insight_container.placeholders.sql_expression"),
              onChange: (insight) => {
                setInsightsInfo((draft) => {
                  draft.sqlExpression = insight.target.value;
                });
              },
              value: insightsInfo.sqlExpression,
              inputProps: {
                "data-testid": "sql-expression",
              },
              rows: 2,
              multiline: true,
              disabled: isDisabled,
              error: errors.sqlExpressionError !== null,
              helperText: <InlineMessage message={errors.sqlExpressionError} />,
            },
          }
        : {}),
    },
  ];
  useEffect(() => {
    const errors1 = validateInsightsInfo(insightsInfo, focused, isDisabled);
    setErrors(errors1);
  }, [insightsInfo, focused]);

  return (
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        classes={{
          paper: classes.MuiPaper,
        }}
      >
        <div className={clsx(classes.flexContainer, classes.alignStart)}>
          <Typography variant="h5">
            {!isDisabled
              ? title
              : `${t("insight_container.new_insight")} - ${
                  insightsInfo.insightsName || insightsInfo.insightName
                }`}
          </Typography>
          <div
            className={classes.marginLeftAuto}
            onClick={() => {
              handleClose();
              resetState();
            }}
            onKeyDown={() => null}
            role="button"
            tabIndex={0}
            data-testid="close-btn"
          >
            <Close />
          </div>
        </div>
        {currentStep === 1 && (
          <DialogContent>
            <Form
              fields={newInsightFields}
              fieldClassName={clsx(
                classes.fieldContainer,
                classes.flexContainer
              )}
            />
          </DialogContent>
        )}
        <DialogActions
          classes={{
            root: classes.justifySpaceBetween,
          }}
        >
          {!(currentStep === 1 && isDisabled) && (
            <div className={classes.btnContainer}>
              <Button
                onClick={() => {
                  if (currentStep === 1) {
                    if (!isDisabled) {
                      setIsCancelClicked(true);
                    } else {
                      handleClose();
                    }
                  } else {
                    setCurrentStep(currentStep - 1);
                  }
                }}
                color="primary"
                variant={
                  currentStep === 2 && isDisabled ? "contained" : "outlined"
                }
              >
                {currentStep === 1 ? t("common.cancel1") : t("common.previous")}
              </Button>
            </div>
          )}
          {!(currentStep === 2 && isDisabled) && !isDisabled && (
            <div className={classes.btnContainer}>
              <Button
                onClick={async () => {
                  setInsightNameValidationState({});
                  const allFocusedValues = {};
                  Object.keys(initialFocusedValues).forEach((key) => {
                    allFocusedValues[key] = true;
                  });

                  setFocused(allFocusedValues);
                  const errors1 = validateInsightsInfo(
                    insightsInfo,
                    allFocusedValues,
                    isDisabled
                  );

                  if (Object.values(errors1).some((x) => x)) {
                    setErrors(errors1);
                  }
                  if (checkStep1Disabled(errors1)) {
                    return;
                  }

                  handleSubmit(insightsInfo);
                  resetState();
                }}
                color="primary"
                variant="contained"
              >
                {currentStep === 1 &&
                  insightNameValidationState.state === "info" && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.5,
                      }}
                    >
                      <CircularProgress size={20} color="white" />
                    </Box>
                  )}
                {!isDisabled && t("common.submit")}
              </Button>
            </div>
          )}
        </DialogActions>
      </Dialog>
      <Dialog
        open={isCancelClicked}
        onClose={() => setIsCancelClicked(false)}
        classes={{
          paper: classes.removeModalContainer,
        }}
      >
        <DialogContent>
          <Typography variant="h4">{t("common.confirm_cancel")}</Typography>
          <Typography variant="h6">
            {t("new_trait.unsaved_inputs_warning")}
          </Typography>
        </DialogContent>
        <DialogActions>
          <div className={classes.btnContainer}>
            <Button
              onClick={() => {
                setIsCancelClicked(false);
              }}
              color="primary"
              variant="outlined"
            >
              {t("common.no")}
            </Button>
            <Button
              onClick={() => {
                handleClose();
                resetState();
                setIsCancelClicked(false);
              }}
              color="secondary"
              variant="contained"
            >
              {t("common.yes")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

NewInsight.defaultProps = {
  insightsInfo: {},
};

NewInsight.propTypes = {
  insightsInfo: PropTypes.shape({
    insightsName: PropTypes.string,
    description: PropTypes.string,
    inputTraits: PropTypes.string,
    outputTrait: PropTypes.string,
    customIndicator: PropTypes.string,
    sqlExpression: PropTypes.string,
    isNewEvent: PropTypes.bool.isRequired,
    insightsId: PropTypes.string,
    insightName: PropTypes.string,
    isSQLTrait: PropTypes.bool.isRequired,
  }),
  setInsightsInfo: PropTypes.func.isRequired,
  openModal: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default NewInsight;
