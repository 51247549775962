import { useMemo } from "react";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import RequestOutputModal from "../../../../components/RequestOutputModal";
import StatusBadge from "../../../../components/StatusBadge";
import Table from "../../../../components/Table";

const InsightRequestOutputModal = ({
  setShowOutput,
  isOpen,
  data,
  requestId,
  isLoading,
}) => {
  const { t } = useTranslation();

  const columns = useMemo(() => {
    const updatedKeys = [
      {
        field: "description",
        headerName: t("common.labels.insight_description"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      },
      {
        field: "marketingProgram",
        headerName: t("common.labels.marketing_program"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      },
      {
        field: "status",
        headerName: t("common.labels.status"),
        flex: 1,
        sortable: false,
        renderCell: (colData) => {
          const { value } = colData;
          return <StatusBadge status={value} />;
        },
      },
      {
        field: "message",
        headerName: t("common.labels.message"),
        flex: 1,
        sortable: false,
      },
    ];
    if (data?.insightsName) {
      updatedKeys.unshift({
        field: "insightsName",
        headerName: t("common.labels.insight_name"),
        flex: 1,
        sortable: false,
        disableToggle: true,
      });
    }
    return updatedKeys;
  }, [data]);

  function getMessage(mpItem) {
    if (mpItem?.new) {
      return t("insight_container.insights_marketing_program_created");
    }
    if (mpItem?.updated) {
      return t("insight_container.insights_marketing_program_created");
    }
    if (mpItem?.deleted) {
      return t("insight_container.insights_marketing_program_created");
    }
    return "";
  }

  const rows = useMemo(() => {
    return (
      data?.marketingPrograms?.map((item, index) => {
        const row = {
          id: `${item.insightName}-${index}`,
          insightsName: data?.insightsName,
          description: data?.description,
          marketingProgram: item?.marketingProgram,
          status: data.status,
          message: getMessage(item),
        };

        return row;
      }) || []
    );
  }, [data]);

  return (
    <RequestOutputModal
      open={isOpen}
      handleClose={() => setShowOutput(false)}
      title={`${t("common.labels.request_id")} ${requestId} ${t(
        "request_output.output"
      )}`}
      subTitle={t("request_output.insight_output_modal_title")}
      isLoading={isLoading}
    >
      <Table columns={columns} rows={rows} hidePagination />
    </RequestOutputModal>
  );
};

InsightRequestOutputModal.defaultProps = {
  isLoading: false,
  requestId: "",
};

InsightRequestOutputModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.objectOf({
    items: PropTypes.shape({
      dataType: PropTypes.string,
      description: PropTypes.string,
      insightName: PropTypes.string,
    }),
  }).isRequired,
  requestId: PropTypes.string,
  setShowOutput: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

export default InsightRequestOutputModal;
