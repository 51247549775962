import { forwardRef } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import _ from "lodash";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import useGlobalStyles from "../../assets/styles/global";
import i18n from "../../i18n/init";
import isEmpty from "../../utilities/isEmpty";
import AccordionRowDetails, { KeyValueSeparator } from "../AccordionRowDetails";
import CustomModal from "../CustomModal";

import useStyles from "./styles";

export const AccordionModalDetails = forwardRef(
  (
    {
      rows,
      truncate,
      type,
      keys,
      rowKeys,
      loading,
      emptyDataMessage,
      onDelete,
      onEdit,
      showEdit,
      showDelete,
      noScroll,
    },
    ref
  ) => {
    const classes = useStyles();
    const globalClasses = useGlobalStyles();
    const { t } = useTranslation();

    const removeHtmlTagsAndTruncate = (html, maxLength) => {
      const doc = new DOMParser().parseFromString(html, "text/html");
      const plainText = doc.body.textContent || "";
      if (plainText.length > maxLength) {
        return `${plainText.substring(0, maxLength)}...`;
      }
      return plainText;
    };
    const accordionClass = noScroll ? classes.noScrollSystems : classes.systems;

    return (
      <div ref={ref} className={accordionClass}>
        {rows.map((row, i) => {
          const status = (() => {
            if (row.deleted && row.isUpdated) {
              return t("common.existing");
            }
            if (row.deleted) {
              return t("common.deleted");
            }
            if (row.isUpdated) {
              return t("common.updated");
            }
            if (row.updated) {
              return t("common.updated");
            }
            if (row.new) {
              return t("common.new");
            }
            return null;
          })();
          const subTitleConfig = _.find(keys, {
            id: rowKeys.subTitle || rowKeys.subTitle2,
          });
          return (
            <div
              key={`${i} ${row[rowKeys.title]} ${row[rowKeys.subTitle]}`}
              className={clsx(classes.flexContainer, classes.fullWidth)}
            >
              <Accordion className={classes.accordion}>
                <AccordionSummary
                  data-testid="accordion-summary"
                  className={classes.accordionHeader}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography className={classes.systemType}>
                    {row[rowKeys.title]}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={clsx(
                      `${classes.systemName} ${
                        subTitleConfig?.textCapitalize && classes.textCapitalize
                      }`
                    )}
                  >
                    {removeHtmlTagsAndTruncate(
                      row[rowKeys.subTitle] || row[rowKeys.subTitle2],
                      50
                    )}
                  </Typography>
                  {status && (
                    <Typography
                      className={clsx(
                        classes.statusLabel,
                        classes[row.deleted ? "deleted" : "new"]
                      )}
                    >
                      {status}
                    </Typography>
                  )}
                </AccordionSummary>
                <AccordionDetails className={classes.accordionContent}>
                  {type === "traitNames" ? (
                    <table className={globalClasses.table}>
                      <tbody>
                        {keys
                          .filter(
                            (key) => !(key.hideIfEmpty && isEmpty(row[key.id]))
                          )
                          .map((key, ind) => (
                            <KeyValueSeparator
                              keyVal={key.name}
                              value={row[key.id]}
                              ind={ind.toString()}
                              separator={null}
                              copyValue={key.copyValue}
                            />
                          ))}
                        {type === "traitNames" &&
                          row.ecosystemTraitNames.map((trait, j) => (
                            <KeyValueSeparator
                              keyVal={`Ecosystem ${trait.ecosystemName}`}
                              value={trait.ecosystemTraitName}
                              ind={j.toString()}
                              separator={null}
                            />
                          ))}
                      </tbody>
                    </table>
                  ) : (
                    <AccordionRowDetails
                      truncate={truncate}
                      keys={keys.filter(
                        (key) => !(key.hideIfEmpty && isEmpty(row[key.id]))
                      )}
                      row={row}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
              <div className={clsx(classes.flexContainer, classes.ml12)}>
                {showEdit(row) && (
                  <div
                    onClick={() => onEdit(row, i)}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                    data-testid="button-1"
                  >
                    <span className={globalClasses.editIcon}>
                      <EditOutlinedIcon />
                    </span>
                  </div>
                )}
                {showDelete(row) && (
                  <div
                    onClick={() => onDelete(row, i)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={() => {}}
                    aria-label="delete"
                    data-testid="button-2"
                  >
                    <span className={globalClasses.deleteIcon}>
                      <DeleteOutlineOutlinedIcon />
                    </span>
                  </div>
                )}
              </div>
            </div>
          );
        })}
        {rows.length === 0 && !loading && (
          <Alert severity="info">
            <Typography variant="body2">{emptyDataMessage}</Typography>
          </Alert>
        )}
      </div>
    );
  }
);

AccordionModalDetails.defaultProps = {
  rows: [],
  rowKeys: {
    subTitle2: "",
  },
  type: "",
  emptyDataMessage: i18n.t("ecosystems.no_mapping"),
  onDelete: () => {},
  onEdit: () => {},
  showEdit: () => false,
  showDelete: () => false,
  noScroll: false,
  truncate: true,
};

AccordionModalDetails.propTypes = {
  truncate: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ),
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowKeys: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    subTitle2: PropTypes.string,
  }),
  type: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  emptyDataMessage: PropTypes.string,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  showEdit: PropTypes.func,
  showDelete: PropTypes.func,
  noScroll: PropTypes.bool,
};

const AccordionModal = ({
  title,
  subtitle,
  keys,
  loading,
  open,
  onClose,
  rows,
  rowKeys,
  type,
  btn,
  btn1,
  onBtnClick,
  onBtn1Click,
}) => {
  const classes = useStyles();

  return (
    <CustomModal
      open={open}
      onClose={() => {
        onClose();
      }}
      title={title}
      subtitle={subtitle}
      showCloseIcon
      loading={loading}
    >
      <AccordionModalDetails
        rows={rows}
        keys={keys}
        type={type}
        rowKeys={rowKeys}
        loading={loading}
      />
      <div className={clsx(classes.footer, btn && classes.footer1)}>
        {btn && (
          <Button
            data-testid={`btn-${btn}`}
            variant="contained"
            color="primary"
            onClick={() => {
              onBtnClick();
              onClose();
            }}
          >
            {btn}
          </Button>
        )}
        {btn1 && (
          <Button
            data-testid={`btn-${btn}`}
            variant="contained"
            color="primary"
            onClick={() => {
              onBtn1Click();
              onClose();
            }}
          >
            {btn1}
          </Button>
        )}
      </div>
    </CustomModal>
  );
};

AccordionModal.defaultProps = {
  title: null,
  rows: [],
  rowKeys: {
    subTitle2: "",
  },
  type: "",
  btn: "",
  btn1: "",
  onBtnClick: () => {},
  onBtn1Click: () => {},
};

AccordionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.string,
    })
  ),
  keys: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    })
  ).isRequired,
  rowKeys: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    subTitle2: PropTypes.string,
  }),
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string,
  btn: PropTypes.string,
  btn1: PropTypes.string,
  onBtnClick: PropTypes.func,
  onBtn1Click: PropTypes.func,
};

export default AccordionModal;
