import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  customTooltip: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  separator: {
    margin: "0px 20px",
  },
  featureName: {
    color: theme.palette.text.primary,
    fontWeight: 500,
    whiteSpace: "nowrap",
  },
  flexContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap",
  },
  textOverflowEllipsis: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  textCapitalize: {
    textTransform: "capitalize",
  },
}));

export default useStyles;
