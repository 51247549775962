import { Route, Switch, Redirect } from "react-router-dom";

import applicationConfig from "../../config/applicationConfig";

import CreateInsightContainer from "./CreateInsightContainer";
import InsightsContainer from "./InsightsContainer";

const InsightsModule = () => {
  const basePath = applicationConfig.basePaths.insights;
  return (
    <Switch>
      <Route exact path={`${basePath}`} component={InsightsContainer} />

      <Route
        exact
        path={`${basePath}/new`}
        component={CreateInsightContainer}
      />
      <Route
        exact
        path={applicationConfig.pathnames.insight_manage}
        component={CreateInsightContainer}
      />
      <Route
        exact
        path={applicationConfig.pathnames.insight_requests_revision}
        component={CreateInsightContainer}
      />
      <Route
        exact
        path={`${basePath}/*`}
        render={() => <Redirect to={`${basePath}`} />}
      />
    </Switch>
  );
};

export default InsightsModule;
