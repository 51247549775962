import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import ReviewModal from "../../../../../components/ReviewModal";

import applicationConfig from "../../../../../config/applicationConfig";

const ReviewRequestModal = ({
  data,
  module,
  isApprovalPage,
  open,
  isRevised,
  status,
  handleOutput,
  handleClose,
  handleAction,
  handleEditRequest,
  commentRef,
  isLoading,
  isBatchRequest,
}) => {
  const { t } = useTranslation();
  const { requestStatus } = applicationConfig;

  const requestDetailsFields = [
    {
      label: `${t("common.labels.request_id")}`,
      selector: "requestId",
    },
    {
      label: `${t("common.labels.requestDate")}`,
      selector: "requestDate",
    },
    {
      label: `${t("common.labels.requester")}`,
      selector: "requester",
    },
  ];

  if (isApprovalPage) {
    requestDetailsFields.push(
      ...[
        {
          label: `${t("common.labels.region")}`,
          selector: "region",
        },
      ]
    );
  }

  // Push status always
  requestDetailsFields.push(
    ...[
      {
        label: t("common.labels.status"),
        selector: "status",
      },
    ]
  );

  const insightDetails = [
    {
      label: t("common.labels.insight_id"),
      selector: "insightId",
    },
  ];

  const traitDetails = [
    {
      label: `${t("common.labels.trait_name")}`,
      selector: "name",
    },
    {
      label: `${t("common.labels.ecosystems")}`,
      selector: "ecosystems",
    },
    {
      label: `${t("common.labels.marketing_programs")}`,
      selector: "marketingPrograms",
    },
    {
      label: `${t("common.labels.survivorship_rules")}`,
      selector: "survivorshipRules",
    },
    {
      label: `${t("common.labels.trait_response_types")}`,
      selector: "traitResponseTypes",
    },
  ];

  const sourceDetails = [
    {
      label: `${t("common.labels.sources")}`,
      selector: "dataSources",
    },
    {
      label: `${t("common.labels.marketing_program")}`,
      selector: "dataSourceMarketingPrograms",
    },
  ];

  const segmentSourceDetails = [
    {
      label: `${t("common.labels.sources")}`,
      selector: "dataSources",
    },
    {
      label: `${t("common.labels.segment_source")}`,
      selector: "name",
    },
    {
      label: `${t("common.labels.marketing_program")}`,
      selector: "dataSourceMarketingPrograms",
    },
  ];

  const traitsUseCaseDetails = [
    {
      label: "Use Case ID",
      selector: "useCaseId",
    },
    {
      label: "Use Case Name",
      selector: "useCaseName",
    },
    {
      label: "Use Case Description",
      selector: "useCaseDescription",
    },
    {
      label: "Scope",
      selector: "scope",
    },
    {
      label: "Warning",
      selector: "warning",
    },
  ];

  const eventsUseCaseDetails = [
    {
      label: "Use Case ID",
      selector: "useCaseId",
    },
    {
      label: "Use Case Name",
      selector: "useCaseName",
    },
    {
      label: "Use Case Description",
      selector: "useCaseDescription",
    },
    {
      label: "Event Name",
      selector: "eventName",
    },
    {
      label: "Scope",
      selector: "scope",
    },
    {
      label: "Warning",
      selector: "warning",
    },
  ];

  const originalTraitsUseCaseDetails = [
    {
      label: "Use Case Name",
      selector: "originalUseCaseName",
    },
    {
      label: "Use Case Description",
      selector: "originalUseCaseDescription",
    },
    {
      label: "Scope",
      selector: "originalScope",
    },
  ];

  const originalEventsUseCaseDetails = [
    ...originalTraitsUseCaseDetails,
    {
      label: "Event Name",
      selector: "originalEventName",
    },
  ];

  const traitsResponsesDetails = [
    {
      label: "Trait Name",
      selector: "name",
    },
    {
      label: "Trait Responses",
      selector: "traitResponses",
    },
    {
      label: "Warning",
      selector: "warning",
    },
  ];

  const eventResponsesDetails = [
    {
      label: "Event Name",
      selector: "eventName",
    },
    {
      label: "Property Name",
      selector: "propertyName",
    },
    {
      label: "Event Responses",
      selector: "eventPropertyResponses",
    },
    {
      label: "Warning",
      selector: "warning",
    },
  ];

  const consentDetails = [
    {
      label: "Consent Id",
      selector: "consentId",
    },
    {
      label: "Marketing Program",
      selector: "marketingProgram",
    },
    {
      label: "Consent Template Type",
      selector: "consentTemplateType",
    },
    {
      label: "Consent Texts",
      selector: "consentTexts",
    },
  ];

  const originalTraitsResponsesDetails = [
    {
      label: "Trait Responses",
      selector: "originalTraitResponses",
    },
  ];

  const originalEventResponsesDetails = [
    {
      label: "Event Responses",
      selector: "originalEventPropertyResponses",
    },
  ];

  const eventMappingDetails = [
    {
      label: `${t("common.labels.event_name")}:`,
      selector: "eventName",
    },
    {
      label: `${t("events_container.table_columns.event_properties")}:`,
      selector: "eventProperties",
    },
    {
      label: `${t("common.labels.ecosystems")}:`,
      selector: "ecosystems",
    },
    {
      label: `${t("common.labels.marketing_programs")}:`,
      selector: "marketingPrograms",
    },
  ];

  const eventPropertyDetails = [
    {
      label: `${t("common.labels.event_name")}:`,
      selector: "eventName",
    },
    {
      label: `${t("events_container.table_columns.event_properties")}:`,
      selector: "eventProperties",
    },
  ];

  const originalEventPropertyDetails = [
    {
      label: `${t("common.labels.event_name")}:`,
      selector: "originaleventName",
    },
    {
      label: `${t("events_container.table_columns.event_properties")}:`,
      selector: "originalEventProperties",
    },
  ];
  const originalEventMappingDetails = [
    {
      label: `${t("common.labels.event_name")}:`,
      selector: "originalEventName",
    },
    {
      label: `${t("events_container.table_columns.event_properties")}:`,
      selector: "originalEventProperties",
    },
    {
      label: `${t("common.labels.ecosystems")}:`,
      selector: "originalEcosystems",
    },
    {
      label: `${t("common.labels.marketing_programs")}:`,
      selector: "originalMarketingPrograms",
    },
  ];

  const originalConsentDetails = [
    {
      label: "Consent Id",
      selector: "originalConsentId",
    },
    {
      label: "Marketing Program",
      selector: "originalMarketingProgram",
    },
    {
      label: "Consent Template Type",
      selector: "originalConsentTemplateType",
    },
  ];

  const newOptDetails = [
    {
      label: `${t("common.labels.opt_id")}:`,
      selector: "optId",
    },
    {
      label: `${t("opts_requests.opt_text_english")}:`,
      selector: "optTextEnglish",
    },
    {
      label: `${t("opts_requests.opt_text_local")}:`,
      selector: "optTextLocalLanguage",
    },
    {
      label: `${t("common.labels.opt_mappings")}:`,
      selector: "optMappings",
    },
  ];

  const optMappingDetails = [
    {
      label: `${t("common.labels.opt_id")}:`,
      selector: "optId",
    },
    {
      label: `${t("common.labels.service_name")}:`,
      selector: "name",
    },
  ];

  const optTraitDetails = [
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "optTraitMarketingProgram",
    },
    {
      label: `${t("common.labels.ecosystems")}:`,
      selector: "ecosystems",
    },
    {
      label: `${t("common.labels.channel")}:`,
      selector: "channel",
    },
    {
      label: `${t("common.labels.trait_names")}:`,
      selector: "optTraitNames",
    },
    {
      label: `${t("common.labels.opt_mappings")}:`,
      selector: "optMappings",
    },
  ];

  const marketingProgramDetails = [
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "marketingProgramDescription",
    },
    {
      label: `${t("common.labels.marketing_program_ecosystems")}:`,
      selector: "mpEcosystems",
    },
  ];

  // Add comment and reviewer if request is revised or not in pending status
  if (status !== requestStatus.PENDING.toLowerCase() || isRevised) {
    requestDetailsFields.push(
      ...[
        {
          label: t("common.labels.comment"),
          selector: "comment",
        },
        {
          label: t("common.labels.reviewer"),
          selector: "reviewer",
        },
      ]
    );
  }

  const disconnectDataSourceDetails = [
    {
      label: `${t("common.labels.name")}`,
      selector: "name",
    },
    {
      label: `${t("disconnect_sources.source_name")}:`,
      selector: "dataSource",
    },
    {
      label: `${t("common.labels.marketing_programs")}:`,
      selector: "marketingPrograms",
    },
  ];
  const originalDisconnectDataSourceDetails = [
    {
      label: `${t("disconnect_sources.source_name")}:`,
      selector: "originalDataSource",
    },
    {
      label: `${t("common.labels.marketing_programs")}:`,
      selector: "OrignalMarketingPrograms",
    },
    {
      label: `${t("common.labels.name")}:`,
      selector: "name",
    },
  ];

  const originalTraitDetails = [
    {
      label: `${t("common.labels.trait_name")}:`,
      selector: "originalName",
    },
    {
      label: `${t("common.labels.ecosystems")}:`,
      selector: "originalEcosystems",
    },
    {
      label: `${t("common.labels.marketing_programs")}:`,
      selector: "originalMarketingPrograms",
    },
    {
      label: `${t("common.labels.survivorship_rules")}:`,
      selector: "originalSurvivorshipRules",
    },
    {
      label: `${t("common.labels.trait_response_types")}:`,
      selector: "originalTraitResponseTypes",
    },
  ];

  const originalSourceDetails = [
    {
      label: `${t("common.labels.sources")}`,
      selector: "originalDataSources",
    },
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "originalDataSourceMarketingPrograms",
    },
  ];

  const originalSegmentSourceDetails = [
    {
      label: `${t("common.labels.sources")}`,
      selector: "originalDataSources",
    },
    {
      label: `${t("common.labels.segment_source")}`,
      selector: "originalName",
    },
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "originalDataSourceMarketingPrograms",
    },
  ];

  const originalMarketingProgramDetails = [
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "originalMarketingProgramDescription",
    },
    {
      label: `${t("common.labels.marketing_program_ecosystems")}:`,
      selector: "originalMpEcosystems",
    },
  ];

  const originalNewOptDetails = [
    {
      label: `${t("opts_requests.opt_text_english")}:`,
      selector: "originalOptTextEnglish",
    },
    {
      label: `${t("opts_requests.opt_text_local")}:`,
      selector: "originalOptTextLocalLanguage",
    },
    {
      label: `${t("common.labels.opt_mappings")}:`,
      selector: "originalOptMappings",
    },
  ];

  const originalOptMappingsDetails = [
    {
      label: `${t("common.labels.opt_id")}:`,
      selector: "originalOptId",
    },
    {
      label: `${t("common.labels.service_name")}:`,
      selector: "originalName",
    },
  ];

  const originalOptTraitDetails = [
    {
      label: `${t("common.labels.marketing_program")}:`,
      selector: "originalOptTraitMarketingProgram",
    },
    {
      label: `${t("common.labels.ecosystems")}:`,
      selector: "originalEcosystems",
    },
    {
      label: `${t("common.labels.channel")}:`,
      selector: "originalChannel",
    },
    {
      label: `${t("common.labels.trait_names")}:`,
      selector: "originalOptTraitNames",
    },
    {
      label: `${t("common.labels.opt_mappings")}:`,
      selector: "originalOptMappings",
    },
  ];

  const consentTemplateDetails = [
    {
      label: "Consent Template Type",
      selector: "consentTemplateType",
    },
    {
      label: "Language",
      selector: "language",
    },
    {
      label: "Country",
      selector: "country",
    },
    {
      label: "Versions",
      selector: "versions",
    },
  ];

  const originalConsentTemplateDetails = [
    {
      label: "Consent Template Type",
      selector: "originalConsentTemplateType",
    },
    {
      label: "Language",
      selector: "originalLanguage",
    },
    {
      label: "Country",
      selector: "originalCountry",
    },
    {
      label: "Versions",
      selector: "originalVersions",
    },
  ];

  const originalInsightDetails = [
    {
      label: t("common.labels.insight_id"),
      selector: "insightId",
    },
    {
      label: `${t("common.labels.insight_name")}`,
      selector: "originalInsightName",
    },
  ];

  let extraDetails = [];

  if (module === applicationConfig.modules.traits) {
    requestDetailsFields.push(...traitDetails);
    extraDetails = originalTraitDetails;
  } else if (module === applicationConfig.modules.insights) {
    requestDetailsFields.push(...insightDetails);
    extraDetails = originalInsightDetails;
  } else if (module === applicationConfig.modules.segment_source) {
    requestDetailsFields.push(...segmentSourceDetails);
    extraDetails = originalSegmentSourceDetails;
  } else if (module === applicationConfig.modules.source) {
    requestDetailsFields.push(...sourceDetails);
    extraDetails = originalSourceDetails;
  } else if (module === applicationConfig.modules.new_opt) {
    requestDetailsFields.push(...newOptDetails);
    extraDetails = originalNewOptDetails;
  } else if (module === applicationConfig.modules.opt_mapping) {
    requestDetailsFields.push(...optMappingDetails);
    extraDetails = originalOptMappingsDetails;
  } else if (module === applicationConfig.modules.opt_traits) {
    requestDetailsFields.push(...optTraitDetails);
    extraDetails = originalOptTraitDetails;
  } else if (
    module === applicationConfig.modules.marketing_program ||
    module === applicationConfig.modules.marketing_program_ecosystem
  ) {
    requestDetailsFields.push(...marketingProgramDetails);
    extraDetails = originalMarketingProgramDetails;
  } else if (module === applicationConfig.modules.traits_use_case) {
    requestDetailsFields.push(...traitsUseCaseDetails);
    extraDetails = originalTraitsUseCaseDetails;
  } else if (module === applicationConfig.modules.events_use_case) {
    requestDetailsFields.push(...eventsUseCaseDetails);
    extraDetails = originalEventsUseCaseDetails;
  } else if (module === applicationConfig.modules.event_mapping) {
    requestDetailsFields.push(...eventMappingDetails);
    extraDetails = originalEventMappingDetails;
  } else if (module === applicationConfig.modules.event_properties) {
    requestDetailsFields.push(...eventPropertyDetails);
    extraDetails = originalEventPropertyDetails;
  } else if (module === applicationConfig.modules.traits_responses) {
    requestDetailsFields.push(...traitsResponsesDetails);
    extraDetails = originalTraitsResponsesDetails;
  } else if (module === applicationConfig.modules.events_responses) {
    requestDetailsFields.push(...eventResponsesDetails);
    extraDetails = originalEventResponsesDetails;
  } else if (module === applicationConfig.modules.consent_template) {
    requestDetailsFields.push(...consentTemplateDetails);
    extraDetails = originalConsentTemplateDetails;
  } else if (module === applicationConfig.modules.consents) {
    requestDetailsFields.push(...consentDetails);
    extraDetails = originalConsentDetails;
  } else if (module === applicationConfig.modules.data_source_disconnect) {
    requestDetailsFields.push(...disconnectDataSourceDetails);
    extraDetails = originalDisconnectDataSourceDetails;
  }

  if (
    data.message &&
    data.message.length > 0 &&
    (data.statusText === requestStatus.RETURNED.toLowerCase() ||
      data.statusText === requestStatus.PENDING.toLowerCase())
  ) {
    requestDetailsFields.push({
      label: t("common.labels.error_message"),
      selector: "errorMessage",
    });
  }
  return (
    <ReviewModal
      open={open}
      isLoading={isLoading}
      handleOutput={handleOutput}
      handleClose={handleClose}
      handleAction={handleAction}
      handleEditRequest={handleEditRequest}
      data={data}
      status={status}
      isRevised={isRevised}
      commentRef={commentRef}
      extraDetails={extraDetails}
      requestDetailsFields={requestDetailsFields}
      isBatchRequest={isBatchRequest}
    />
  );
};

ReviewRequestModal.defaultProps = {
  open: false,
  isRevised: false,
  isApprovalPage: false,
  status: "",
  handleAction: () => {},
  commentRef: null,
  isBatchRequest: false,
};

ReviewRequestModal.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    message: PropTypes.string,
    statusText: PropTypes.string,
  }).isRequired,
  module: PropTypes.string.isRequired,
  isApprovalPage: PropTypes.bool,
  isRevised: PropTypes.bool,
  status: PropTypes.string,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleEditRequest: PropTypes.func.isRequired,
  handleAction: PropTypes.func,
  handleOutput: PropTypes.func.isRequired,
  commentRef: PropTypes.shape({
    current: PropTypes.string.isRequired,
  }),
  isBatchRequest: PropTypes.bool,
};

export default ReviewRequestModal;
