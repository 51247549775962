import { createElement, useContext } from "react";

import { Box, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { ReactComponent as feedbackIcon } from "../../assets/images/RYE.svg";

import { lightTheme } from "../../assets/styles/themes";

import applicationConfig from "../../config/applicationConfig";

import { ColorModeContext } from "../../providers/ColorModeProvider";

import useStyles from "./styles";

function AppFooter() {
  const classes = useStyles();
  const colorMode = useContext(ColorModeContext);
  const { t } = useTranslation();
  const { sidebarMenuConfig } = applicationConfig;
  const giveFeedbackItem = sidebarMenuConfig.find(
    (config) => config.text === t("sidebar_menu_config.feedback")
  );
  return (
    <Box>
      <div className={classes.footerContainer}>
        <div className={classes.rateUs}>
          {createElement(feedbackIcon, {
            className: classes.feedbackIcon,
            fill:
              colorMode.mode === "light"
                ? lightTheme.palette.static.darkerGrey
                : lightTheme.palette.static.white,
          })}
        </div>
        <div className={classes.footerCopyright}>
          <Button
            color="primary"
            variant="text"
            href={giveFeedbackItem.children?.[0]?.path}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.feedbackLink}
          >
            {giveFeedbackItem.children?.[0]?.text}
          </Button>
          <Typography className={classes.internalUse}>
            <strong>{t("common.footer_texts.internal_use")}</strong>
          </Typography>
          <Typography>{t("common.footer_texts.copyright")}</Typography>
        </div>
      </div>
    </Box>
  );
}

export default AppFooter;
