import { createElement, useContext, useEffect, useState } from "react";

import {
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { ReactComponent as DarkLogo } from "../../assets/images/CRS_LOGO.svg";
import listCheckmark from "../../assets/images/list_checkmark.svg";

import { lightTheme } from "../../assets/styles/themes";
import Layout from "../../components/Layout";

import applicationConfig from "../../config/applicationConfig";
import useUserProfile from "../../hooks/useUserProfile";

import { ColorModeContext } from "../../providers/ColorModeProvider";

import checkUserAuthorization from "../../utilities/checkUserAuthorization";

import useStyles from "./styles";

const HomePage = () => {
  const classes = useStyles();
  const { user } = useUserProfile();

  const { t } = useTranslation();
  const colorMode = useContext(ColorModeContext);
  const [loginModalVisible, setLoginModalVisible] = useState(false);

  const { sidebarMenuConfig } = applicationConfig;

  const moduleTypographyConfig = [
    {
      key: t("common.labels.traits"),
      title: t("home_page.navigation.traits.title"),
      description: t("home_page.navigation.traits.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.dullGrey,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullWhite,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("sidebar_menu_config.sources"),
      title: t("home_page.navigation.sources.title"),
      description: t("home_page.navigation.sources.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.darkLavender,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullTeal,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("sidebar_menu_config.my_tasks"),
      title: t("home_page.navigation.my_tasks.title"),
      description: t("home_page.navigation.my_tasks.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.mediumLavender,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.lightTeal,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.opts"),
      title: t("home_page.navigation.opts.title"),
      description: t("home_page.navigation.opts.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.mediumLavender,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.lightTeal,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.mps"),
      title: t("common.labels.mps"),
      description: t("home_page.navigation.mps.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.darkPink,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.lighterTeal,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.events"),
      title: t("home_page.navigation.events.title"),
      description: t("home_page.navigation.events.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.dullGrey,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullWhite,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.usecase"),
      title: t("home_page.navigation.usecase.title"),
      description: t("home_page.navigation.usecase.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.lightRed,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullRed,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.consents"),
      title: t("home_page.navigation.consents.title"),
      description: t("home_page.navigation.consents.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.lightRed,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullRed,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.dummy"),
      title: t("home_page.navigation.dummy.title"),
      description: t("home_page.navigation.dummy.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.lightRed,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullRed,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
    {
      key: t("common.labels.utilities"),
      title: t("home_page.navigation.utilities.title"),
      description: t("home_page.navigation.utilities.description"),
      theme: {
        light: {
          backgroundColor: lightTheme.palette.static.lightRed,
          color: lightTheme.palette.static.lightGrey,
        },
        dark: {
          backgroundColor: lightTheme.palette.static.dullRed,
          color: lightTheme.palette.static.darkGrey,
        },
      },
    },
  ];

  useEffect(() => {
    setLoginModalVisible(!user || !user.userId);
  }, [user]);

  return (
    <>
      <div
        className={clsx(
          classes.themeWrapper,
          loginModalVisible ? classes.blurrBackground : ""
        )}
      >
        <Layout showFooter>
          <div className={clsx(classes.flexContainer, classes.heroWrapper)}>
            <div className={classes.leftContainer}>
              <DarkLogo />
              <ul
                className={classes.infoList}
                style={{
                  listStyle: `url(${listCheckmark})`,
                }}
              >
                <li>Digital Marketing Self-Service</li>
                <li>Data Centricity</li>
                <li>Metadata Management</li>
              </ul>
              {user && user.totalVisitorCount ? (
                <span className={classes.visitorCount}>
                  Visitor Count - {user.totalVisitorCount}
                </span>
              ) : (
                <></>
              )}
            </div>
            <div className={classes.rightContainer}>
              <Typography variant="h2">{t("home_page.title")}</Typography>
              <Typography variant="h5">
                {t("home_page.description1")}
              </Typography>
              <Typography variant="h6">
                {t("home_page.description2")}
              </Typography>
              <div>
                <a href="/guide" target="_blank" rel="noreferrer">
                  <Button variant="outlined" color="primary">
                    {t("home_page.user_guide")}
                  </Button>
                </a>
                <a
                  href="/ops"
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginLeft: 10, marginRight: 10 }}
                >
                  <Button variant="outlined" color="primary">
                    {t("home_page.contact_us")}
                  </Button>
                </a>
              </div>
            </div>
          </div>
          <div className={classes.infoContainer}>
            {sidebarMenuConfig
              .filter(
                (menuItem) =>
                  menuItem.referenceType ===
                  (localStorage.getItem("reference-type") || "Consumer")
              )
              .map((menuItem, index) => {
                if (
                  checkUserAuthorization(user.access, menuItem.accessRights) &&
                  !menuItem.hidden
                ) {
                  const module = moduleTypographyConfig.find(
                    (title) => title.key === menuItem.text
                  );

                  if (!module) {
                    return undefined;
                  }

                  return (
                    <Card className={classes.card} key={index}>
                      <CardMedia
                        component={() => (
                          <div
                            style={{
                              backgroundColor:
                                module.theme[colorMode.mode].backgroundColor,
                              color: module.theme[colorMode.mode].color,
                            }}
                            className={classes.cardMedia}
                          >
                            {createElement(menuItem.icon, {
                              fill: module.theme[colorMode.mode].color,
                              width: 50,
                              height: 50,
                            })}
                            <Typography variant="h5">{module.title}</Typography>
                            <Typography variant="subtitle1">
                              {module.description}
                            </Typography>
                          </div>
                        )}
                      />
                      <CardContent className={classes.cardContent}>
                        {menuItem.children.map((item) => {
                          if (
                            checkUserAuthorization(
                              user.access,
                              item.accessRights
                            ) &&
                            !item.hidden
                          ) {
                            return (
                              <Button
                                key={item.path}
                                variant="outlined"
                                endIcon={createElement(item.icon, {
                                  fill:
                                    colorMode.mode === "light"
                                      ? lightTheme.palette.static.darkerGrey
                                      : lightTheme.palette.static.white,
                                  width: 30,
                                  height: 30,
                                })}
                                component={Link}
                                to={item.path}
                              >
                                {item.text}
                              </Button>
                            );
                          }
                          return undefined;
                        })}
                      </CardContent>
                    </Card>
                  );
                }
                return undefined;
              })}
          </div>
        </Layout>
      </div>
    </>
  );
};

export default HomePage;
