import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Fragment,
} from "react";

import {
  Box,
  Button,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useHistory, Link } from "react-router-dom";

import createConsentMappings from "../../../api/create-consent-mappings";
import getConsentMappings from "../../../api/get-consent-mappings";
import getConsentTemplates from "../../../api/get-consent-templates";
import getReferenceData from "../../../api/get-reference-data";
import updateRequest from "../../../api/update-request";

import { ReactComponent as ContentCopyOutlined } from "../../../assets/images/copy.svg";
import useGlobalStyles from "../../../assets/styles/global";

import AccessDenied from "../../../components/AccessDenied";
import ConfirmationModal from "../../../components/ConfirmationModal";
import InputFlow from "../../../components/InputFlow";
import StatusBadge from "../../../components/StatusBadge";
import Table from "../../../components/Table";

import applicationConfig from "../../../config/applicationConfig";
import consentsModuleConfig from "../../../config/consentsModuleConfig";
import optsModuleConfig from "../../../config/optsModuleConfig";
import pageAccessConfig from "../../../config/pageAccessConfig";

import useLoadingSpinner from "../../../hooks/useLoadingSpinner";
import useNotifier from "../../../hooks/useNotifier";
import useRequest from "../../../hooks/useRequest";

import useUserProfile from "../../../hooks/useUserProfile";
import checkUserAuthorization from "../../../utilities/checkUserAuthorization";
import handleError from "../../../utilities/handleError";

import parseSearchFilter from "../../../utilities/parseSearchFilter";
import ConfirmationDetails from "../NewConsentTemplateContainer/components/ConfirmationDetails";

import ConsentIdRequestOutputModal from "./components/ConsentIdRequestOutputModal";

import ConsentID from "./ConsentID";
import ConsentTexts from "./ConsentTexts";
import TemplateTexts from "./ConsentTexts/TemplateTexts";

import createBackendPayload from "./helpers/createBackendPayload";
import extractPlaceholders from "./helpers/extractPlaceholders";
import validateConsentMappingForm from "./helpers/validateConsentMappingForm";
import validateConsentTexts from "./helpers/validateConsentTexts";
import useStyles from "./styles";

const totalSteps = 3;

const NewConsentContainer = () => {
  const { t } = useTranslation();
  const { loading, increaseRequestsCount, decreaseRequestsCount } =
    useLoadingSpinner();
  const globalStyles = useGlobalStyles();
  const history = useHistory();
  const {
    location: { state },
  } = history;
  const { user } = useUserProfile();
  const { request, setRequest } = useRequest();
  const { addNotification } = useNotifier();
  const classes = useStyles();
  const { createConsentIDConstants } = consentsModuleConfig;
  const { requestStatus } = optsModuleConfig;
  const isUserAuthorized = checkUserAuthorization(
    user.access,
    pageAccessConfig.manageConsents
  );

  const isRequestRevisionFlow = useMemo(
    () => Object.keys(request).length !== 0,
    [request]
  );
  const [isRequestRevisionUpdated, setIsRequestRevisionUpdated] =
    useState(false);
  useEffect(() => {
    return () => {
      if (isRequestRevisionFlow && !isRequestRevisionUpdated) {
        setRequest({});
      }
    };
  }, []);

  const [currentStep, setCurrentStep] = useState(1);
  const [initialStep, setInitialStep] = useState(1);

  const [consentUsecaseNameValue, setConsentUsecaseNameValue] = useState({});

  const [consentTemplateTypeValue, setConsentTemplateTypeValue] = useState({});
  const [marketingProgramValue, setMarketingProgramValue] = useState();
  const [countryValue, setCountryValue] = useState({});
  const [languagesValue, setLanguagesValue] = useState([]);
  const [consentTemplates, setConsentTemplates] = useState();
  const [updatedConsentTexts, setUpdatedConsentTexts] = useState({});
  const [templateVariablesValues, setTemplateVariablesValues] = useState({});
  const [consentMappingAlertInfo, setConsentMappingAlertInfo] = useState();
  const [urlInfo, setUrlInfo] = useState();
  const [showConsentIdValidation, setShowConsentIdValidation] = useState(false);
  const [showConsentTextValidation, setShowConsentTextValidation] =
    useState(false);
  const [consentMappingInfo, setConsentMappingInfo] = useState(null);
  const [mappingResponse, setMappingResponse] = useState({});
  const [output, setOutput] = useState({});
  const [showOutput, setShowOutput] = useState(false);

  const [openCreateConsentWarningModal, setOpenCreateConsentWarningModal] =
    useState(false);
  const [consentUseCases, setConsentUseCases] = useState([]);
  const [openAccordion, setOpenAccordion] = useState(false);

  const { apigeeHost, deploymentEnv } = applicationConfig;

  useEffect(() => {
    const hasValues = Object.values(templateVariablesValues).some((v) => !!v);
    if (hasValues !== openAccordion) {
      setOpenAccordion(hasValues);
    }
  }, [templateVariablesValues]);

  useEffect(() => {
    if (state) {
      const consentTemplateSplit =
        state?.consentDetails?.consentTemplateType?.split(" - ");
      const countrySplit = state.consentDetails.country.split(" - ");
      setConsentMappingInfo({
        consentIds: [
          {
            channelMappings: state.consentMappings,
            consentDetails: state.consentDetails,
            consentId: state.consentDetails.consentId,
            consentIdSuffix: state.consentDetails.consentId.substring(
              state.consentDetails.consentId.indexOf("_") + 1
            ),
            consentMappings: state.consentMappings,
            consentTemplateType: consentTemplateSplit?.[1],
            consentTemplateTypeId: consentTemplateSplit?.[0],
            consentVersions: state.consentVersions,
            country: countrySplit[1],
            countryCode: countrySplit[0],
            optTraits: state.optTraits,
          },
        ],
      });

      setConsentTemplateTypeValue({
        consentTemplateTypeId: consentTemplateSplit?.[0],
        description: consentTemplateSplit?.[1],
        title: state?.consentDetails?.consentTemplateType,
      });

      const marketingProgramSplit =
        state.consentDetails.marketingProgram.split(" - ");
      const legalEntitySplit = state.consentDetails.legalEntity.split(" - ");

      setMarketingProgramValue({
        description: marketingProgramSplit[1],
        legalEntityName: legalEntitySplit[1],
        legalEntityNumber: legalEntitySplit[0],
        marketingProgramName: state.consentDetails.marketingProgram,
        marketingProgramNumber: marketingProgramSplit[0],
        title: state.consentDetails.marketingProgram,
      });

      setUrlInfo(
        `Your Updated Consent Details will be available in this URL(https://${apigeeHost[deploymentEnv]}/brandbuilding/consumer/V2/datadictionary/consents?consentId=${state?.consentDetails?.consentId}) once the request is approved.`
      );
      setInitialStep(2);
      setCurrentStep(2);
    }
  }, [state]);

  const [templateVariableValuesLoaded, setTemplateVariablesValuesLoaded] =
    useState(false);

  useEffect(() => {
    if (
      consentMappingInfo &&
      consentMappingInfo?.consentIds?.length > 0 &&
      Object.keys(templateVariablesValues).length === 0 &&
      consentTemplates &&
      !templateVariableValuesLoaded
    ) {
      let newTemplateVariablesValues = {};
      consentMappingInfo?.consentIds?.forEach((item) => {
        item?.consentVersions?.forEach((consentVersion) => {
          const consentTemplateTypeId =
            item?.consentDetails?.consentTemplateType?.split(" - ")?.[0];
          const countryCode = consentVersion?.country.split(" - ")?.[0];
          const languageCode = consentVersion?.language?.split(" - ")?.[0];
          const consentTemplateKey = `${consentTemplateTypeId}-${countryCode}-${languageCode}`;
          const consentTemplate = consentTemplates.find(
            (template) => template.consentTemplateKey === consentTemplateKey
          );
          if (consentTemplate) {
            const placeholders = extractPlaceholders(
              consentTemplate.consentText,
              consentVersion.consentText
            );
            const noticePlacehoders = extractPlaceholders(
              consentTemplate.noticeText,
              consentVersion.noticeText
            );
            newTemplateVariablesValues = {
              ...newTemplateVariablesValues,
              ...placeholders,
              ...noticePlacehoders,
            };
          }
        });
      });
      setTemplateVariablesValues(newTemplateVariablesValues);
      setTemplateVariablesValuesLoaded(true);
    }
  }, [consentMappingInfo, templateVariablesValues, consentTemplates]);

  useEffect(() => {
    if (consentMappingInfo?.consentIds?.[0]?.consentDetails) {
      const countryInfo =
        consentMappingInfo?.consentIds?.[0]?.consentDetails.country?.split(
          " - "
        );
      setCountryValue({
        country: countryInfo[1],
        countryCode: countryInfo[0],
        title: `${countryInfo[0]} - ${countryInfo[1]}`,
      });

      const languageData = [];

      consentMappingInfo?.consentIds?.forEach((item) => {
        // Take the Latest Version Out
        const latestVersion = Math.max(
          ...item.consentVersions.map(
            (version) => version.templateVersionNumber
          )
        );

        languageData.push(
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...item?.consentVersions
            .filter(
              (version) => version.templateVersionNumber === latestVersion
            )
            .map((version) => ({
              ...version,
              languageCode: version.language?.split(" - ")[0],
              country: version.country?.split(" - ")[1],
              title: version.language,
            }))
        );
      });

      setLanguagesValue(languageData);
    } else if (consentMappingInfo?.consentIds?.[0]?.countryCode) {
      setCountryValue({
        country: consentMappingInfo?.consentIds[0]?.country,
        countryCode: consentMappingInfo?.consentIds[0]?.countryCode,
        title: `${consentMappingInfo?.consentIds[0]?.countryCode} - ${consentMappingInfo?.consentIds[0]?.country}`,
      });
    }
  }, [consentMappingInfo]);

  useEffect(() => {
    if (consentMappingInfo?.consentIds?.length > 0) {
      (async () => {
        try {
          increaseRequestsCount();
          const data = await getConsentTemplates(
            null,
            parseSearchFilter(
              [
                {
                  name: "consentTemplateTypeId",
                  value: consentMappingInfo?.consentIds
                    .map((item) => item.consentTemplateTypeId)
                    .join(","),
                },
              ],
              {
                consentTemplateTypeId: "searchText",
                country: "country",
              },
              1,
              50
            )
          );
          setConsentTemplates(data?.items || []);
        } catch (error) {
          handleError({
            error,
            handle404: false,
            addNotification,
          });
        } finally {
          decreaseRequestsCount();
        }
      })();
    }
  }, [consentTemplateTypeValue, consentMappingInfo]);

  const initializeConsentMapping = useCallback(
    async (marketingProgram, ctUseCase) => {
      try {
        increaseRequestsCount();
        const data = await getConsentMappings(
          `?useCaseId=${ctUseCase.consentUseCaseId}&marketingProgram=${marketingProgram?.marketingProgramNumber}`
        );
        setConsentMappingInfo(data);
        return data;
      } catch (error) {
        console.error(error);
        return null;
      } finally {
        decreaseRequestsCount();
      }
    },
    []
  );

  useEffect(() => {
    (async () => {
      const errors = validateConsentMappingForm(
        marketingProgramValue,
        consentUsecaseNameValue
      );
      if (Object.keys(errors).length === 0) {
        setConsentMappingInfo(null);
        setLanguagesValue([]);
        const data = await initializeConsentMapping(
          marketingProgramValue,
          consentUsecaseNameValue
        );
        const consentId = data?.consentIds
          .map((item) => item.consentId)
          .join(", ");
        if (data?.consentIds[0]?.consentDetails) {
          setConsentMappingAlertInfo(
            `Consent ID ${consentId} already exists. Please update the brand in next steps`
          );
        } else {
          setConsentMappingAlertInfo(
            `Consent ID ${consentId} will be created. Please update the brand in next steps`
          );
        }
      }
    })();
  }, [
    initializeConsentMapping,
    marketingProgramValue,
    consentUsecaseNameValue,
  ]);
  const confirmationDetails = useMemo(() => {
    const details = [
      {
        label: "Consent ID",
        value: consentMappingInfo?.consentIds
          ?.map((item) => item.consentId)
          .join(", "),
      },
      {
        label: "Consent Template Type",
        value: consentMappingInfo?.consentIds
          ?.map((item) => item.consentTemplateType)
          .join(", "),
      },
      {
        label: "Marketing Program",
        value:
          consentMappingInfo?.consentDetails?.marketingProgram ||
          `${marketingProgramValue?.marketingProgramNumber} - ${marketingProgramValue?.description}`,
      },
    ];
    if (consentMappingInfo) {
      details.push({
        label: "Country",
        value: `${consentMappingInfo?.consentIds?.[0].countryCode} - ${consentMappingInfo?.consentIds?.[0].country}`,
      });
    }
    return details;
  }, [consentMappingInfo, consentTemplateTypeValue]);

  const consentTemplatesTableColumns = [
    {
      field: "requestId",
      headerName: t("common.labels.request_id"),
      flex: 1,
      sortable: false,
      disableToggle: true,
    },
    {
      field: "consentId",
      headerName: t("common.labels.consent_id"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.consent?.consentIds
              .map((rec) => rec.consentId)
              .join(",")}
          </span>
        );
      },
    },
    {
      field: "marketingProgram",
      headerName: t("common.labels.marketing_program"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        const { marketingProgramNumber, description } =
          params.row.marketingProgram;
        return <span>{`${marketingProgramNumber} - ${description}`}</span>;
      },
    },
    {
      field: "consentTemplateType",
      headerName: t("common.labels.consent_template_type"),
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            {params?.row?.consent?.consentIds
              .map((rec) => rec.consentTemplateType)
              .join(",")}
          </span>
        );
      },
    },
    {
      field: "status",
      headerName: t("status.status"),
      flex: 1,
      renderCell: (params) => {
        return (
          <div className={classes.statusWrapper}>
            <StatusBadge
              status={params.value}
              showTooltip
              onTooltipClick={() => {
                if (params.value === requestStatus.APPROVED) {
                  setShowOutput(true);
                }
              }}
            />
          </div>
        );
      },
      sortable: false,
    },
  ];
  useEffect(() => {
    if (request.requestDetails) {
      const { marketingProgram, consentTemplateType } = request.requestDetails;
      setMarketingProgramValue({
        ...marketingProgram,
        title: `${marketingProgram?.marketingProgramNumber} - ${marketingProgram?.description}`,
      });
      setConsentTemplateTypeValue({
        ...consentTemplateType,
        title: `${consentTemplateType?.consentTemplateTypeId} - ${consentTemplateType?.description}`,
      });
    }
  }, [isRequestRevisionFlow]);

  useEffect(() => {
    (async () => {
      const { consentUseCases: consentUseCasesList } = await getReferenceData(
        applicationConfig.referenceDataQueries.consentUseCases
      );
      setConsentUseCases(consentUseCasesList);
    })();
  }, []);
  const handleCopy = (copyValueString) => {
    const instructions = copyValueString.replace(/<\/?[^>]+(>|$)/g, "");
    navigator.clipboard.writeText(`${instructions || "null"}`);
  };

  return !isUserAuthorized && !user.loading && !loading ? (
    <AccessDenied
      goToLink="/consents"
      goToText={t("access_denied.go_to_consents")}
    />
  ) : (
    <>
      <InputFlow
        totalSteps={totalSteps}
        currentStep={currentStep}
        loading={loading}
        steps={[
          t("create_consent.headings.step_1"),
          t("create_consent.headings.step_2"),
          t("create_consent.headings.step_3"),
        ]}
        headerText={t("create_consent.headerText")}
        footer={
          <div className={clsx(classes.footer, classes.flexContainer)}>
            {currentStep <= totalSteps && (
              <>
                <Button
                  variant="outlined"
                  color="primary"
                  classes={{
                    root: globalStyles.btn,
                  }}
                  onClick={() => {
                    if (currentStep === initialStep) {
                      history.goBack();
                    } else {
                      setCurrentStep(currentStep - 1);
                    }
                  }}
                >
                  {t("common.back")}
                </Button>
                <Button
                  onClick={async () => {
                    if (loading) return;
                    if (currentStep === 1) {
                      const errs = validateConsentMappingForm(
                        marketingProgramValue,
                        consentUsecaseNameValue
                      );

                      if (Object.keys(errs).length !== 0) {
                        setShowConsentIdValidation(true);
                        return;
                      }

                      if (consentUsecaseNameValue?.instructions == null) {
                        setCurrentStep(currentStep + 1);
                      }
                      setOpenCreateConsentWarningModal(true);
                    } else if (currentStep === 2) {
                      const errs = validateConsentTexts({
                        countryValue,
                        updatedConsentTexts,
                        languagesValue,
                        templateVariablesValues,
                      });
                      if (Object.keys(errs).length !== 0) {
                        setShowConsentTextValidation(true);
                        return;
                      }
                      setCurrentStep(currentStep + 1);
                    } else if (currentStep === 3) {
                      try {
                        const payload = createBackendPayload(
                          marketingProgramValue,
                          // optMappings,
                          updatedConsentTexts,
                          consentTemplates,
                          consentMappingInfo,
                          templateVariablesValues
                        );
                        increaseRequestsCount();
                        if (!isRequestRevisionFlow) {
                          const data = await createConsentMappings(payload);

                          const { items } = data;

                          setMappingResponse(
                            items.map((item, index) => ({
                              id: index,
                              ...item,
                              status:
                                item.status[0].toUpperCase() +
                                item.status.slice(1),
                            }))
                          );
                          setOutput(items?.[0].output);
                        } else {
                          const data = await updateRequest(
                            request.requestId,
                            payload,
                            applicationConfig.modules.consents
                          );
                          setIsRequestRevisionUpdated(true);
                          setRequest(data);
                          history.goBack();
                          addNotification(
                            t("notifications.request_edited_success"),
                            t("status.success")
                          );
                          return;
                        }
                        setCurrentStep(currentStep + 1);
                      } catch (error) {
                        handleError({
                          error,
                          handle404: false,
                          addNotification,
                        });
                      } finally {
                        decreaseRequestsCount();
                      }
                    }
                  }}
                  classes={{
                    root: globalStyles.btn,
                  }}
                >
                  {loading && (
                    <Box
                      sx={{
                        mr: 1,
                        mt: 0.7,
                      }}
                    >
                      <CircularProgress
                        color="palette.static.white"
                        size={20}
                      />
                    </Box>
                  )}
                  {createConsentIDConstants.footerText[currentStep]}
                </Button>
              </>
            )}
            {currentStep > totalSteps && (
              <div className={globalStyles.footerContainer}>
                <Button
                  variant="outlined"
                  color="primary"
                  component={Link}
                  to="/consents"
                >
                  {t("create_consent_template.back_to_consents")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  component={Link}
                  to={`/tasks/requests?requestId=${mappingResponse[0].requestId}`}
                >
                  {t("common.labels.view_request_status")}
                </Button>
              </div>
            )}
          </div>
        }
      >
        <div className={clsx(classes.newConsentContainer)}>
          {currentStep === 1 && (
            <>
              <ConsentID
                consentUsecaseNameValue={consentUsecaseNameValue}
                setConsentUsecaseNameValue={setConsentUsecaseNameValue}
                marketingProgramSelected={marketingProgramValue}
                setMarketingProgramSelected={setMarketingProgramValue}
                showValidation={showConsentIdValidation}
                consentUseCases={consentUseCases}
              />
              {consentMappingAlertInfo && !loading && (
                <Alert severity="info" style={{ marginTop: 20 }}>
                  <Typography variant="body2">
                    {consentMappingAlertInfo}
                  </Typography>
                </Alert>
              )}
              {urlInfo && !loading && (
                <Alert severity="info" style={{ marginTop: 20 }}>
                  <Typography variant="body2">{urlInfo}</Typography>
                </Alert>
              )}
            </>
          )}
          {currentStep === 2 && (
            <>
              <ConfirmationDetails details={confirmationDetails} />
              <ConsentTexts
                isExisting={consentMappingInfo !== null}
                consentTemplateTypeValue={consentTemplateTypeValue}
                countryValue={countryValue}
                languagesValue={languagesValue}
                setCountryValue={setCountryValue}
                consentTemplates={consentTemplates}
                setLanguagesValue={setLanguagesValue}
                updatedConsentTexts={updatedConsentTexts}
                setUpdatedConsentTexts={setUpdatedConsentTexts}
                showValidation={showConsentTextValidation}
                templateVariablesValues={templateVariablesValues}
                setTemplateVariablesValues={setTemplateVariablesValues}
                mpCountryCode={
                  marketingProgramValue?.marketingProgramName?.split("-")?.[1]
                }
                consentMappingInfo={consentMappingInfo}
                consentIdSuffix={consentMappingInfo?.consentIds?.[0].consentId}
                openAccordion={openAccordion}
              />
            </>
          )}
          {currentStep === 3 && (
            <>
              <ConfirmationDetails details={confirmationDetails} />
              <div className={classes.confirmationContainer}>
                <Typography
                  style={{
                    marginBottom: "16px",
                  }}
                  variant="h6"
                >
                  Consent Texts
                </Typography>

                <TemplateTexts
                  updatedConsentTexts={updatedConsentTexts}
                  isDisabled
                  templateVariablesValues={templateVariablesValues}
                  openAccordion
                />
              </div>
            </>
          )}
          {currentStep > 3 && (
            <div
              style={{
                height: 300 * mappingResponse.length + 60,
                maxHeight: "calc(100vh - 100px)",
                overflow: "hidden",
              }}
            >
              <Table
                columns={consentTemplatesTableColumns}
                rows={mappingResponse}
              />
            </div>
          )}
        </div>
        {showOutput && (
          <ConsentIdRequestOutputModal
            isOpen={showOutput}
            isLoading={false}
            requestId={mappingResponse[0]?.requestId}
            marketingProgramDescription={
              mappingResponse && mappingResponse[0]?.marketingProgram
                ? `${mappingResponse[0]?.marketingProgram?.marketingProgramNumber} - ${mappingResponse[0]?.marketingProgram?.description}`
                : ""
            }
            data={output}
            setShowOutput={() => setShowOutput(false)}
          />
        )}
      </InputFlow>
      {consentUsecaseNameValue?.instructions !== null && (
        <>
          <ConfirmationModal
            open={openCreateConsentWarningModal}
            message={
              <div className={classes.consentDialog}>
                <span className={classes.title}>
                  {t("create_consent.dialogs.confirm_usecase_classification")}
                </span>
                <IconButton
                  onClick={() => {
                    handleCopy(consentUsecaseNameValue?.instructions);
                  }}
                >
                  <ContentCopyOutlined
                    style={{
                      fill: "#3682F2",
                      cursor: "pointer",
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: consentUsecaseNameValue?.instructions,
                  }}
                />
                <Typography style={{ marginTop: "10px", fontWeight: "bold" }}>
                  {consentUsecaseNameValue?.instructions
                    ? t("create_consent.dialogs.confirmation")
                    : "null"}
                </Typography>
              </div>
            }
            btn1Text={t("create_consent.dialogs.cancel")}
            btn2Text={t("create_consent.dialogs.yes_confirm")}
            btn1Action={() => {
              setOpenCreateConsentWarningModal(false);
            }}
            btn2Action={() => {
              setOpenCreateConsentWarningModal(false);
              setCurrentStep(currentStep + 1);
            }}
            type="success"
          />
        </>
      )}
    </>
  );
};

export default NewConsentContainer;
