import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  footerContainer: {
    padding: "8px 24px 8px 100px",
  },
  footerCopyright: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "& p": {
      fontSize: "0.7rem",
    },
  },
  internalUse: {
    marginBottom: "4px",
  },
  rateUs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "4px",
  },
  feedbackIcon: {
    width: "150px",
  },
  feedbackLink: {
    fontSize: "1rem",
    textTransform: "capitalize",
  },
}));

export default useStyles;
