import api from "../utilities/api";

const getInsightFrequency = async (insightId) => {
  const apiUrl = `/insights/${insightId}/frequency`;
  const {
    data: { data },
  } = await api.get(apiUrl);

  return data;
};

export default getInsightFrequency;
